import { useParams } from "react-router"

type PropertyIdHookProps = {
  throwIfMissing: boolean
}

/**
 * Wrapper around useParams for :propertyId
 * Throws if propertyId isn't found by default
 */
function usePropertyId(props?: { throwIfMissing: true }): string
function usePropertyId(props?: { throwIfMissing: false }): string | undefined
function usePropertyId(props?: PropertyIdHookProps): string | undefined
function usePropertyId(props: PropertyIdHookProps = { throwIfMissing: true }) {
  const { propertyId } = useParams<{ propertyId: string }>()
  if (props.throwIfMissing && !propertyId) {
    throw new Error(
      "usePropertyId must be used within a route that contains a propertyId"
    )
  }
  return propertyId
}

export default usePropertyId
