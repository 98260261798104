export const mortgagesHomeBlocks = {
  mortgageIntroPageGreetingMDX: {
    type: "mdx",
    description: `The greeting on the Mortgage Intro page - default is either "Welcome," or "Welcome {nickname}," depending on whether the user has a nickname`,
    default: null, // Default is either "Welcome," or "Welcome {nickname}," depending on whether the user has a nickname
  },
  mortgageIntroPageTitle: {
    type: "text",
    description: `The title on the Mortgage Intro page - default is "We track your mortgage so you don't have to", except "mortgage" may become "mortgages" if there is more than one`,
    default: null, // Default is "We track your mortgage so you don't have to", except "mortgage" may become "mortgages" if there is more than one
  },
  mortgageIntroPagePreamble: {
    type: "text",
    description: "The preamble on the Mortgage Intro page",
    default: "What we track",
  },
  mortgageIntroCtaLabel: {
    type: "text",
    description: "The label on the CTA button on the Mortgage Intro page",
    default: "Take a look",
  },
  mortgageHealthPageInfoCards: {
    type: "text",
    description: "The title above the info cards on the Mortgage Health page",
    default: "What we check",
  },
  mortgageHealthPagePropertyIntroCountMdx: {
    type: "mdx",
    description: `The text that appears above the property health cards on the Mortgage Health page - {props.propertiesCount} will include the word "property" or "properties" depending on whether there is more than one`,
    default: "We're checking {props.propertiesCount} for you",
  },
  mortgageHomePageAlertChange: {
    type: "text",
    description:
      "The text that goes next to the warning icon inside the badge of the alert card on /property/:propertyId when there are Lifecycle alerts. The fallback text is currently 'Alert available' or 'Alerts available' depending on the number of alerts",
    default: null, // The fallback text is currently 'Alert available' or 'Alerts available' depending on the number of alerts
  },
  mortgageHomeChangeContent: {
    type: "markdown",
    description:
      "The instructions that appear at the bottom of the alert card on /property/:propertyId when there are Lifecycle alerts",
    default: "Check below what can impact your mortgage",
  },
  lifecycleZeroMortgages: {
    title: {
      type: "text",
      description: "Title of Lifecycle Mortgages home page when user has no mortgages",
      default: "You have no mortgages with us that need to be actioned right now",
    },
    content: {
      type: "markdown",
      description:
        "Body content of Lifecycle Mortgages home page when user has no mortgages",
      default:
        "Get in touch with your advisor if you'd like to enquire about a new mortgage.",
    },
  },
} as const
