import { configureStore, isPlain } from "@reduxjs/toolkit"
import type { TypedStartListening } from "@reduxjs/toolkit"
import { CurriedGetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"

import { detailActivityApi } from "../components/business/UserActivities/services/detailActivityApi"
import reducer from "./reducers"
import { blogPostsApi } from "./services/blogPosts"
import { businessFileManagerApi } from "./services/business/fileManager"
import { businessProductSelectionApi } from "./services/business/productSelection"
import { reportingAdvisorsApi } from "./services/business/reportingAdvisors"
import { consumerFactorApi } from "./services/consumerFactor/consumerFactorApi"
import { consumerSideApi } from "./services/consumerSide"
import { conversations } from "./services/conversations"
import { employeeFactorApi } from "./services/employeeFactor/employeeFactorApi"
import { eventApi } from "./services/eventApi"
import { learnApi } from "./services/learnApi"
import { lenderApi } from "./services/lender"
import { lifecycleProspectsApi } from "./services/lifecycleProspects/lifecycleProspectsApi"
import { mortgagesApi } from "./services/mortgages"
import { notInterestedApi } from "./services/notInterested"
import { onboardingFactorApi } from "./services/onboardingFactor/onboardingFactorApi"
import { productSelectionApi } from "./services/productSelection"
import { singleSignOnApi } from "./services/singleSignOn"
import { learnSessionListener } from "./slices/learnSessionMiddleware"
import { onboardingListener } from "./slices/onboardingMiddleware"
import { persistantSessionListener } from "./slices/persistantSessionMiddleware"
import { uiListener } from "./slices/uiMiddleware"

const SerializableStateInvariantMiddlewareOptions = {
  isSerializable: (value: unknown) => isPlain(value) || value instanceof Date,
}

export const middlewareArray = [
  persistantSessionListener.middleware,
  onboardingListener.middleware,
  uiListener.middleware,
  employeeFactorApi.middleware,
  lifecycleProspectsApi.middleware,
  onboardingFactorApi.middleware,
  consumerFactorApi.middleware,
  detailActivityApi.middleware,
  lenderApi.middleware,
  blogPostsApi.middleware,
  mortgagesApi.middleware,
  productSelectionApi.middleware,
  businessProductSelectionApi.middleware,
  businessFileManagerApi.middleware,
  consumerSideApi.middleware,
  singleSignOnApi.middleware,
  notInterestedApi.middleware,
  eventApi.middleware,
  reportingAdvisorsApi.middleware,
  learnApi.middleware,
  learnSessionListener.middleware,
  conversations.middleware,
]

export const middleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: SerializableStateInvariantMiddlewareOptions,
  }).concat(middlewareArray)

export const store = configureStore({
  reducer,
  middleware,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStartListening = TypedStartListening<RootState, AppDispatch>

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch: () => AppDispatch = useDispatch
