import { LabelPaths } from "../../../hooks/blocks/util.types"

export const quickSelectBlocks = {
  quickSelectCaseStarted: {
    type: "text",
    description: "Copy displayed on the quick select component for case started",
    default: "Case started",
  },
  quickSelectMeetingBooked: {
    type: "text",
    description: "Copy displayed on the quick select component for meeting booked",
    default: "Meeting booked",
  },
  quickSelectNotInterested: {
    type: "text",
    description: "Copy displayed on the quick select component for not interested",
    default: "Not interested",
  },
  quickSelectRescheduleMeeting: {
    type: "text",
    description: "Copy displayed on the quick select component for reschedule meeting",
    default: "Reschedule meeting",
  },
} as const

export type QuickSelectLabelPaths = LabelPaths<typeof quickSelectBlocks>
