import { LabelPaths } from "../../../../hooks/blocks/util.types"

export const productSelectionStartPageBlocks = {
  lender: {
    selection: {
      start: {
        title: {
          type: "text",
          description: "Heading element for the /product-selection/start page",
          default: "Start your review",
        },
        introContent: {
          type: "text",
          description: "Copy displayed on the /product-selection/start page",
          default: null, // default is null because fallback is used in the component as we use firmName in the copy
        },
        executionButton: {
          labelContent: {
            type: "text",
            description:
              "Label for the button that opens the execution modal on the /product-selection/start page",
            default: "What does execution-only mean?",
          },
        },
        warningContent: {
          type: "text",
          description:
            "Copy displayed on the /product-selection/start page if the consumer has an existing product switch in progress.",
          default:
            "This online process is on an execution-only and has no associated fees.",
        },
        proceedContent: {
          type: "text",
          description:
            "Copy displayed on the /product-selection/start page if the consumer has an existing product switch in progress.",
          default: "Click 'Start review' to see rates available to you.",
        },
        startButton: {
          labelContent: {
            type: "text",
            description:
              "Label for the button that starts the product selection journey on the /product-selection/start page",
            default: "Start review",
          },
        },
        executionModal: {
          title: {
            type: "text",
            description:
              "Title for the modal that opens when the consumer clicks the 'What does execution-only mean?' button link on the /product-selection/start page",
            default: "What does execution-only mean?",
          },
          content: {
            type: "mdx",
            description: `Copy displayed in the modal that opens when the consumer clicks the 'What does execution-only mean?' button on the /product-selection/start page. A component can be used for the contact link e.g. <ExternalLink url="https://example.org">Contact us</ExternalLink>`,
            default:
              "You’re proceeding execution-only, if you’re choosing a mortgage without advice.\n\n" +
              "We’re not recommending a product or assessing whether your selection is right for you.\n\n" +
              'Prefer to get help? <ExternalLink url="">Contact us</ExternalLink> or speak to your mortgage broker.',
          },
        },
      },
    },
  },
} as const

export type ProductSelectionStartPaths = LabelPaths<
  typeof productSelectionStartPageBlocks
>
