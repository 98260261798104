import { isEmpty } from "lodash-es"
import { generatePath } from "react-router"

interface Params {
  [param: string]: string
}

const createPath = (pathspec: string): ((params?: Params) => string) => {
  return (params: Params = {}): string => {
    const paramValues = Object.values(params)
    if (!isEmpty(params) && !paramValues.some((val) => !val)) {
      return generatePath(pathspec, params)
    }
    return pathspec
  }
}

export default {
  HOME: "/",
  BUSINESS: {
    LISTS: {
      CALL_LIST: "/call-list",
      ORPHANS: "/orphans",
      ALL_LEADS: "/all-leads",
      MY_LEADS: "/my-leads",
      URGENT: "/urgent",
      MEETINGS_BOOKED: "/meetings-booked",
      SEARCH: "/search",
    },
    PORTAL_LIST: "/portal-list",
    ONBOARDING: {
      MAIN: "/onboarding",
      BRANDING: "/edit-branding",
      IMAGE: "/edit-image",
      POLICIES: "/edit-policies",
    },
    ACTIONED: {
      LIST: "/have-you-actioned",
    },
    SETTINGS: {
      TEAM: "/team",
      SUPPRESSION_LIST: "/suppression-list",
      INVALID_EMAILS: "/invalid-emails",
      GENERAL: "/general-settings",
      NOT_INTERESTED_LIST: "/not-interested-list",
    },
    REPORTS_OLD: "/management-reports",
    REPORTS: {
      SUMMARY: "/summary",
      PROJECTIONS: "/projections",
      USER_PERFORMANCE: "/user-performance",
    },
    FILES: {
      DOWNLOADS: "/downloads",
      UPLOADS: "/uploads",
      DETAIL: createPath("/file/:id"),
    },
    LOAD_APPROVAL: {
      ALL: "/approval",
      INTERNAL: "/approval-internal",
      DETAIL: createPath("/approval/:id"),
    },
    CONSUMER_BASE: "/consumers",
    CONSUMER: createPath("/consumers/:id"),
    EMPLOYEE: createPath("/employee/:id"),
    NOT_INTERESTED: "/not-interested",
    SEARCH: (q?: string): string => `/search${q ? `?query=${q}` : ""}`,
    LIFECYCLE: "/lifecycle",
  },
  CONSUMER: {
    UPFRONT_PREFERENCES: "/upfront-preferences",
    UNSUBSCRIBE: "/unsubscribe",
    BLOG_POST: createPath("/blog-posts/:slug"),
    BLOG_POST_SLUG: "/blog-posts/:slug",
    SNOOZE_EMAIL: "/snooze-email",
    DIRECT_CALL_REQUEST: "/request-call",
    EMAIL_TRACKING_OPT_OUT: "/email-tracking-opt-out",
    EMAIL_SET_REMINDER: "/email-set-reminder",
    LOGIN: "/login",
    SEARCH: "/search",
    CHECKLIST: "/checklist",
    QUESTIONS: { MAIN: "/questions", THANKYOU: "/questions/thankyou" },

    NOT_INTERESTED: "/not-interested",

    EXPIRY_DATE: "/expiry-date/:propertyId/:mortgageId",
    EXPIRY_DATE_PROPERTY_INDEX: "/expiry-date/:propertyId",
    EXPIRY_DATE_INDEX: "/expiry-date",
    INTEREST_RATES: "/interest-rates/:propertyId/:mortgageId",
    INTEREST_RATES_INDEX: "/interest-rates",
    PRODUCT_RATES: "/product-rates/:propertyId/:mortgageId",
    PRODUCT_RATES_PROPERTY_INDEX: "/product-rates/:propertyId",
    PRODUCT_RATES_INDEX: "/product-rates",
    PROPERTY_VALUE: "/property-value/:propertyId",
    PROPERTY_VALUE_INDEX: "/property-value",
    PROPERTY_INDEX: "/property",
    PROPERTY: "/property/:propertyId",

    PORTAL: {
      ALERTS_DISABLED: "/portal/alerts-disabled",
      LOGIN: "/portal/login",
      NO_ID: "/portal/no-id",
      LEARN: {
        MAIN: "/portal/learn/:propertyId?",
        ARTICLE: "/portal/learn/article/:slug/:propertyId?",
      },
      LEARN_MORE: {
        INDEX: "/learn/:propertyId",
        INDEX_BASE: "/learn/:propertyId?",
        TOPIC: "/learn/:propertyId/topic/:topicId",
        SUBTOPIC: "/learn/:propertyId/topic/:topicId/:subtopicId/:conceptId?",
      },
      SET_REMINDER: "/portal/set-reminder",

      CURRENT_SITUATION_BASE:
        "/portal/your-mortgage-payments/:propertyId?/:mortgageId?",
      CURRENT_SITUATION: "/portal/your-mortgage-payments/:propertyId/:mortgageId",

      SWITCH_NOW_BASE: "/portal/switch-now",
      SWITCH_NOW: "/portal/switch-now/:propertyId",
      LINK: {
        WITHOUT_ADVICE: "/portal/link/without-advice/:propertyId",
        EXTERNAL_ADVICE: "/portal/link/external-advice/:propertyId",
        INTERNAL_ADVICE: "/portal/link/internal-advice/:propertyId",
        WITH_SWITCH_SUPPORT: "/portal/link/with-switch-support/:propertyId",
      },
      OVERVIEW: {
        MAIN: createPath("/portal/overview/:page"),
        STEP_ONE: "/portal/overview/switch",
        STEP_TWO: "/portal/overview/search",
        STEP_THREE: "/portal/overview/no-fees",
        YOUR_OPTIONS_BASE: "/portal/overview/your-options",
        YOUR_OPTIONS: "/portal/overview/your-options/:propertyId",
      },
      INTERIM: {
        INTERNAL: "/portal/internal",
        EXTERNAL: "/portal/external",
      },
      REMIND_WHEN_PRODUCTS_AVAILABLE: "/remind-when-products-available",
      WITH_SWITCH_SUPPORT_BASE: "/portal/with-switch-support",
      WITH_SWITCH_SUPPORT: "/portal/with-switch-support/:propertyId",
      GET_HELP_BASE: "/portal/get-help",
      GET_HELP: "/portal/get-help/:propertyId",
      NEED_SUPPORT: "/portal/support/:propertyId",
      DASHBOARD: "/portal/dashboard/:propertyId",
      POST_SVR_LEARN: {
        FROM_DASHBOARD: "/portal/post-svr-learn/from-dashboard/:propertyId",
        AFTER_SWITCH: "/portal/post-svr-learn/after-switch/:propertyId",
        AFTER_REQUEST_CALL: "/portal/post-svr-learn/after-request-call/:propertyId",
        AFTER_KEEP_CURRENT_RATE:
          "/portal/post-svr-learn/after-keep-current-rate/:propertyId",
        SHOULD_SWITCH: "/portal/post-svr-learn/should-switch/:propertyId",
        PICKING_RIGHT_MORTGAGE:
          "/portal/post-svr-learn/picking-right-mortgage/:propertyId",
        UNDERSTANDING_BOE: "/portal/post-svr-learn/understanding-boe/:propertyId",
        END_OF_INTRO: "/portal/post-svr-learn/end-of-intro/:propertyId",
        STEPS_TO_NEW_DEAL: "/portal/post-svr-learn/steps-to-new-deal/:propertyId",
      },
      SPEAK_TO_ADVISER: "/portal/speak-to-adviser/:propertyId",
      KEEP_CURRENT_RATE: {
        REASONS: "/portal/keep-current-rate/reasons/:propertyId",
        COMMUNICATION: "/portal/keep-current-rate/communication/:propertyId",
        THANKS: "/portal/keep-current-rate/thanks/:propertyId",
      },
      RESPONSIVE_SUB_JOURNEY: {
        PORTFOLIO: "/portal/portfolio/",
        PROPERTIES: "/portal/portfolio/properties/",
        PROPERTY: "/portal/portfolio/property/:propertyId",
      },
    },
  },
  CHANGE_PASSWORD: "/change-password",
  RESET_PASSWORD: "/reset-password",
  MULTI_FACTOR_VERIFICATION: "/multi-factor-authentication",
  COOKIES: "/cookies",
  BLOCKS: "/blocks",
  DEMO: {
    IMPORTANT_TERMS: "/demo/important-terms/:propertyId",
    HELP_ARTICLES: "/demo/help-articles/:propertyId",
    HELP_ARTICLES_FROM_TERMS: "/demo/help-articles-from-terms/:propertyId",
    HELP_ARTICLES_FROM_BREAKDOWN: "/demo/help-articles-from-breakdown/:propertyId",
    DETAILS_FINISH: "/demo/details-finish/:propertyId",
    LEARN_MORE: "/demo/learn-more/:propertyId",
    CALL_THANKS: "/demo/call-thanks/:propertyId",
    NAV: "/demo/nav/:propertyId",
    OVERVIEW: "/demo/overview/:propertyId",
    PAYMENT_BREAKDOWN: "/demo/payment-breakdown/:propertyId",
    BREAKDOWN_FINISH: "/demo/breakdown-finish/:propertyId",
  },
}

export const productSelectionRoutes = {
  START: "/product-selection/start/:propertyId",
  LIST: "/product-selection/list/:propertyId",
  SWITCH_DATE: "/product-selection/switch-date/:propertyId",
  SUMMARY: "/product-selection/summary/:propertyId",
  THANKS: "/product-selection/thanks/:propertyId",
} as const
