import { LabelPaths } from "../../../hooks/blocks/util.types"

export const thanksModalBlocks = {
  thanksModalTitle: {
    type: "text",
    description: "Heading element for the thanks modal",
    default: "Thanks! We’ll be in touch within two working days, if not sooner",
  },
} as const

export type ThanksModalLabelPaths = LabelPaths<typeof thanksModalBlocks>
