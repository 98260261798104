import { LabelPaths } from "../../../hooks/blocks/util.types"

export const askForHelpBlocks = {
  askForHelp: {
    title: {
      type: "text",
      description:
        "Heading in the Ask for Help content box, displays at the bottom of the /blog-posts/:slug page if 'ask-for-help' feature flag is active",
      default: "Need a hand?",
    },
    content: {
      type: "markdown",
      description:
        "Description in the Ask for Help content box, displays at the bottom of the /blog-posts/:slug page if 'ask-for-help' feature flag is active",
      default: "If you need us to explain anything in more detail, we're here to help.",
    },
  },
  askForHelpCta: {
    type: "text",
    description: "Ask for help button text",
    default: "Ask for help",
  },
} as const

export type AskForHelpPaths = LabelPaths<typeof askForHelpBlocks>
