import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit"

import localStorageKeys from "../../constants/localStorageKeys"
import { setLocalStorageItem } from "../../lib/localStorageUtil"
import type { AppStartListening } from "../store"
import {
  setActiveUserPermissionModal,
  setIntroArticleHasBeenShown,
  showCompletedModal,
  visitedLoadApproval,
} from "./onboarding"

export const onboardingListener = createListenerMiddleware()
// It's impossible for .getState() typing to be known ahead of time so we cast here.
// It's the recommended approach. https://redux-toolkit.js.org/api/createListenerMiddleware#typescript-usage
const startListening = onboardingListener.startListening as AppStartListening

startListening({
  matcher: isAnyOf(
    setIntroArticleHasBeenShown,
    showCompletedModal,
    setActiveUserPermissionModal,
    visitedLoadApproval
  ),
  effect: (_, listenerApi) => {
    const {
      introArticleHasBeenShown: introDateTime,
      completedModalHasBeenShown: completedDateTime,
      visitedLoadApproval,
      userPermissionsWelcomeModalBeenShown,
    } = listenerApi.getState().onboarding

    if (introDateTime) {
      setLocalStorageItem(
        localStorageKeys.onboardingWelcomeModalBeenShown,
        introDateTime
      )
    }
    if (completedDateTime) {
      setLocalStorageItem(
        localStorageKeys.onboardingWelcomeModalBeenShown,
        completedDateTime
      )
    }
    if (visitedLoadApproval) {
      setLocalStorageItem(localStorageKeys.isVisitedLA, visitedLoadApproval)
    }
    if (userPermissionsWelcomeModalBeenShown) {
      setLocalStorageItem(
        localStorageKeys.userPermissionsWelcomeModalBeenShown,
        userPermissionsWelcomeModalBeenShown
      )
    }
  },
})
