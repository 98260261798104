import ActionType from "../../constants/actionTypes"
import { RouteState } from "../../types/retain"

export const initialState = {
  redirectTo: undefined,
}

type RedirectAction = RouteState & {
  type: ActionType.ROUTE_REDIRECT_UPDATE
}

export default function (
  state: RouteState = initialState,
  action: RedirectAction
): RouteState {
  const { redirectTo } = action

  switch (action.type) {
    case ActionType.ROUTE_REDIRECT_UPDATE:
      return {
        redirectTo,
      }

    default:
      return state
  }
}
