import { cloneDeep } from "lodash-es"

import { Lead, RawLead } from "../../types/retain/Consumer.types"
import { convertMortgagesIntoPropertiesList } from "../convertMortgagesIntoPropertiesList"
import { consolidateMortgages } from "./consolidateMortgages"
import { parseRawMortgages } from "./parseMortgages"

export function parseLead(consumer: RawLead): Lead {
  if (!consumer) throw Error("Missing consumer (lead)")

  const c = cloneDeep<Lead>({ ...consumer, mortgages: [], properties: [] })

  if (consumer.mortgages) {
    c.numMortgages = consumer.mortgages.length
    const parsedMortgages = parseRawMortgages(consumer.mortgages)
    const mortgages = consolidateMortgages(parsedMortgages)
    c.mortgages = mortgages
    c.properties = convertMortgagesIntoPropertiesList(mortgages)
  }

  if (consumer.meetingBookedFrom) {
    c.meetingBookedFrom = new Date(consumer.meetingBookedFrom)
  }

  if (consumer.meetingBookedUntil) {
    c.meetingBookedUntil = new Date(consumer.meetingBookedUntil)
  }

  return c
}
