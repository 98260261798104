import { LabelPaths } from "../hooks/blocks/util.types"

export const styleBlocks = {
  defaultButtonStyle: {
    type: "text",
    default: "outline",
    description:
      "The default style for buttons. Valid options are outline, ghost, solid, icon, button-as-link",
  },
  firstButtonStyle: {
    type: "text",
    default: "solid",
    description:
      "The default style for initial lender buttons. Valid options are outline, ghost, solid, icon, button-as-link",
  },
  defaultButtonFill: {
    type: "text",
    default: "primary",
    description:
      "The default fill for buttons. Valid options are warning, primary, action, solid-action, contrast, secondary-contrast, danger, transparent, confirm, cancel",
  },
  firstButtonFill: {
    type: "text",
    default: "action",
    description:
      "The default fill for initial lender buttons. Valid options are warning, primary, action, solid-action, contrast, secondary-contrast, danger, transparent, confirm, cancel",
  },
} as const

export type StylePaths = LabelPaths<typeof styleBlocks>
