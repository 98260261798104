import { LabelPaths } from "../../../hooks/blocks/util.types"

export const policiesBlocks = {
  additionalSidebarDisclaimer: {
    type: "markdown",
    description: "Additional disclaimer content for the sidebar",
    default: null,
  },
} as const

export type PoliciesLabelPaths = LabelPaths<typeof policiesBlocks>
