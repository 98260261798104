import ActionType from "../../../constants/actionTypes"
import {
  PermissionGroup,
  PermissionGroupsStore,
} from "../../../types/retain/Permission.types"

export const initialState = {
  data: [],
  error: false,
}

type SuccessAction = {
  type: ActionType.PERMISSION_GROUPS_SUCCESS
  permissionGroups: PermissionGroup[]
}

type NoPayloadAction = {
  type: ActionType.PERMISSION_GROUPS_REQUEST | ActionType.PERMISSION_GROUPS_FAILURE
}

export type Action = NoPayloadAction | SuccessAction

export default function (
  state: PermissionGroupsStore = initialState,
  action: Action
): PermissionGroupsStore {
  switch (action.type) {
    case ActionType.PERMISSION_GROUPS_REQUEST:
      return {
        ...state,
        error: false,
      }
    case ActionType.PERMISSION_GROUPS_SUCCESS:
      return {
        data: action.permissionGroups,
        error: false,
      }
    case ActionType.PERMISSION_GROUPS_FAILURE:
      return {
        data: [],
        error: true,
      }
    default:
      return state
  }
}
