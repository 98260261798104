import { LabelPaths } from "../../../hooks/blocks/util.types"

const introDefault = `
Mortgage products typically start on a lower rate. At the end of your initial term, you move to our Follow-on Rate. This is usually higher than the rate your mortgage product started on.

You may be able to get a better rate by moving to a new product. You can switch directly with us or remortgage with another lender.

Switch today or learn more about the process of moving to a new product.
`

export const portalNavBlocks = {
  lender: {
    homepage: {
      headerTitle: {
        type: "text",
        description: "Title on the homepage for lenders",
        default: "Get started",
      },
      introContent: {
        type: "markdown",
        default: introDefault,
        description: "Intro paragraph on the homepage for lenders",
      },
      footerImage: {
        type: "image",
        description: "Footer image on the homepage for lenders",
        default: null,
      },
      learnButton: {
        iconImage: {
          type: "image",
          description: "Icon image for the learn button",
          default: null,
        },
        labelTitle: {
          type: "text",
          description: "Label for the learn button",
          default: "Explore process",
        },
      },
      mortgagesButton: {
        iconImage: {
          type: "image",
          description: "Icon image for the mortgages button",
          default: null,
        },
        labelTitle: {
          type: "text",
          description: "Label for the mortgages button",
          default: "View mortgage",
        },
      },
    },
  },
} as const

export type PortalNavLabelPaths = LabelPaths<typeof portalNavBlocks>
