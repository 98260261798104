import { createContext, useMemo } from "react"

type IfComponentsContextValue = [boolean, boolean]

export const IfComponentsContext = createContext<IfComponentsContextValue>([
  false,
  false,
])

export function IfComponentsProvider({
  hasExpired,
  hasMultiple,
  children,
}: {
  hasExpired: boolean
  hasMultiple: boolean
  children: React.ReactNode
}) {
  const ifState = useMemo<IfComponentsContextValue>(
    () => [hasExpired, hasMultiple],
    [hasExpired, hasMultiple]
  )
  return (
    <IfComponentsContext.Provider value={ifState}>
      {children}
    </IfComponentsContext.Provider>
  )
}
