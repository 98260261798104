import cx from "classnames"
import { useRef, useState, useEffect } from "react"
import { CSSTransition } from "react-transition-group"
import { CSSTransitionClassNames } from "react-transition-group/CSSTransition"

import s from "./AnimateHeight.module.scss"

interface AnimateHeightClassNames extends CSSTransitionClassNames {
  // How it should look expanded
  base?: string | undefined
  // How the content container should be styled
  content?: string | undefined
  // How the content looks collapsed
  collapsed?: string | undefined
  // Override the default animation
  transition?: string | undefined
}

type Props = {
  classNames?: AnimateHeightClassNames
  in?: boolean
  timeout?: number
  header?: string
  subHeader?: string
  children?: React.ReactNode
}

// Note: if you wish to change the duration of the collapse you can pass
// classNames.transition, but you must also pass the same value to timeout as a
// fallback. E.g.:
//
// style.module.css:
//
//   @value transitionDuration 900ms;
//
//   .transition {
//     transition: height transitionDuration;
//   }
//
//
// component.jsx:
//
//   <AnimateHeight
//     timeout={parseInt(s.transitionDuration, 10)}
//     classNames={{ transition: s.transition }}
//   >

const AnimateHeight = (props: Props) => {
  const {
    classNames: externalClassName = s,
    in: inProp = false,
    timeout = parseInt(s.animationLength, 10),
    children,
  } = props

  const [height, setHeight] = useState<number | string>(0)
  const [classNames, setClassNames] =
    useState<AnimateHeightClassNames>(externalClassName)
  const contentEl = useRef<HTMLDivElement>(null)
  const transitionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const cn = { ...s, ...externalClassName }
    setClassNames({
      enter: cn.collapsed,
      exitDone: cn.collapsed,
      exitActive: cx(cn.collapsed, cn.transition),
      enterActive: cn.transition,
      ...cn,
    })
  }, [externalClassName])

  const transition = (state: keyof AnimateHeightClassNames) => {
    switch (state) {
      case "enterActive":
      case "exit": {
        const el = contentEl.current
        const height = el?.scrollHeight
        if (height) {
          setHeight(height)
        }
        break
      }
      case "enterDone":
      case "exitDone":
      case "enter":
      case "exitActive":
      default:
        setHeight("")
    }
  }

  return (
    <CSSTransition
      in={inProp}
      appear
      timeout={timeout}
      classNames={classNames}
      onEnter={() => transition("enter")}
      onEntering={() => transition("enterActive")}
      onEntered={() => transition("enterDone")}
      onExit={() => transition("exit")}
      onExiting={() => transition("exitActive")}
      onExited={() => transition("exitDone")}
      nodeRef={transitionRef}
    >
      <div
        className={cx(classNames.base)}
        style={{ height: height }}
        ref={transitionRef}
      >
        <div className={classNames.content} ref={contentEl}>
          {children}
        </div>
      </div>
    </CSSTransition>
  )
}

export default AnimateHeight
