import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { BrandingLogoVariety } from "../../components/business/Onboarding/BrandingForm/BrandingForm.types"

export interface ImageEditorState {
  originalImage: string | null
  currentImage: string | null
  imageFileName: string | null
  fixedWidth: number | null
  fixedHeight: number | null
  fixedWidthIsActive: boolean
  fixedHeightIsActive: boolean
  brandingLogoVariety: BrandingLogoVariety | null
  isEditingOnBrandingPage: boolean
  restrictRatioIsActive: boolean
}

const initialState: ImageEditorState = {
  originalImage: null,
  currentImage: null,
  imageFileName: null,
  fixedWidth: null,
  fixedHeight: null,
  fixedWidthIsActive: false,
  fixedHeightIsActive: false,
  brandingLogoVariety: null,
  isEditingOnBrandingPage: false,
  restrictRatioIsActive: false,
}

export const imageEditorSlice = createSlice({
  name: "imageEditor",
  initialState,
  reducers: {
    selectBrandingImageForEdit: (
      state,
      action: PayloadAction<{
        originalImage: string
        imageFileName: string
        fixedWidth: number
        fixedHeight: number
        brandingLogoVariety: BrandingLogoVariety
      }>
    ) => {
      state.originalImage = action.payload.originalImage
      state.currentImage = action.payload.originalImage
      state.imageFileName = action.payload.imageFileName
      state.fixedWidth = action.payload.fixedWidth
      state.fixedHeight = action.payload.fixedHeight
      state.fixedHeightIsActive = true
      state.fixedWidthIsActive = true
      state.brandingLogoVariety = action.payload.brandingLogoVariety
      state.isEditingOnBrandingPage = true
    },
    finishEditingBrandingImage: (state) => {
      state.originalImage = null
      state.currentImage = null
      state.imageFileName = null
      state.fixedWidth = null
      state.fixedHeight = null
      state.fixedHeightIsActive = false
      state.fixedWidthIsActive = false
      state.brandingLogoVariety = null
      state.isEditingOnBrandingPage = false
    },
    setCurrentImage: (state, action: PayloadAction<string>) => {
      state.currentImage = action.payload
    },
    toggleFixedWidthActive: (state) => {
      state.fixedWidthIsActive = !state.fixedWidthIsActive
    },
    toggleFixedHeightActive: (state) => {
      state.fixedHeightIsActive = !state.fixedHeightIsActive
    },
    setFixedWidth: (state, action: PayloadAction<number | null>) => {
      state.fixedWidth = action.payload
    },
    setFixedHeight: (state, action: PayloadAction<number | null>) => {
      state.fixedHeight = action.payload
    },
    toggleRestrictRatio: (state) => {
      state.restrictRatioIsActive = !state.restrictRatioIsActive
    },
  },
})

export const {
  selectBrandingImageForEdit,
  toggleFixedWidthActive,
  toggleFixedHeightActive,
  setCurrentImage,
  setFixedWidth,
  setFixedHeight,
  finishEditingBrandingImage,
  toggleRestrictRatio,
} = imageEditorSlice.actions

export default imageEditorSlice.reducer
