export const interestRatesPageBlocks = {
  interestRatesModuleTitle: {
    type: "text",
    description:
      "Label that displays inside the Interest Rate Info Card on the Lifecycle Home page, and the top of modal that it opens",
    default: "BoE Interest Rate",
  },
  interestRatesModuleAlertCard: {
    type: "text",
    description:
      "The label inside the clickable Interest Rate alerts cards at /property/:propertyId",
    default: "BoE interest rate",
  },
  interestRatesModulePageHeading: {
    type: "text",
    description:
      "Heading element for the Interest Rates page at /interest-rates/:propertyId/:mortgageId",
    default: "BoE interest rate",
  },
  interestRatesDisclaimerBalanceMissingMdx: {
    type: "mdx",
    description:
      "Paragraph beneath the 'Historical base rate changes' chart on the Interest Rates page when the givenCurrentBalance on the mortgage is null or undefined",
    default: `<p>*Estimated payments are calculated based on your current remaining term and an estimated remaining mortgage amount as of today. The estimated remaining mortgage amount assumes you have made no extra payments on your mortgage.</p><p>Any changes to your payments can be confirmed with your lender.</p>`,
  },
  interestRatesDisclaimerBalancePresentMdx: {
    type: "mdx",
    description:
      "Paragraph beneath the 'Historical base rate changes' chart on the Interest Rates page when the givenCurrentBalance on the mortgage is a numerical value",
    default: `<p>*Estimated payments are calculated based on your current remaining term and an estimated remaining mortgage amount of {props.currentLoanAmount}. The estimated remaining mortgage amount assumes you have made no extra payments on your mortgage.</p><p>Any changes to your payments can be confirmed with your lender.</p>`,
  },
  interestRatesModalContentPage1: {
    title: {
      DEPRECATED_id: "interestRatesModalContentPage1",

      type: "text",
      description: "The title of the first page of the Interest Rates info modal",
      default: "BoE interest rate explained",
    },
    content: {
      DEPRECATED_id: "interestRatesModalContentPage1",

      type: "markdown",
      description: "The content of the first page of the Interest Rates info modal",
      default:
        "The Bank Rate (or Base Rate) is set by the Bank of England (BoE). This is the interest rate at which other banks borrow from the BoE. Depending on the type of your mortgage, changes to the Base Rate may change your monthly payment.",
    },
  },
  interestRatesModalContentPage2: {
    title: {
      DEPRECATED_id: "interestRatesModalContentPage2",

      type: "text",
      description: "The title of the second page of the Interest Rates info modal",
      default: "How does this impact my mortgage?",
    },
    content: {
      DEPRECATED_id: "interestRatesModalContentPage2",

      type: "markdown",
      description: "The content of the second page of the Interest Rates info modal",
      default:
        "**Tracker mortgages** track a Base Rate. They are typically the BoE's Base Rate plus a set percentage. Tracker rates change when the Base Rate changes.\n" +
        "\n" +
        "**Fixed-rate mortgages** keep the same interest rate from the start of the deal to the end of the initial term (or fixed rate term).\n" +
        "\n" +
        "**Variable rate mortgages** do not have a fixed interest rate. Typically these follow a lender's standard variable rate (SVR). Changes to the BoE Base Rate often impact SVRs.",
    },
  },
  interestRatesModalContentPage3: {
    title: {
      DEPRECATED_id: "interestRatesModalContentPage3",

      type: "text",
      description: "The title of the third page of the Interest Rates info modal",
      default: "Why do we track it?",
    },
    content: {
      DEPRECATED_id: "interestRatesModalContentPage3",

      type: "markdown",
      description: "The content of the third page of the Interest Rates info modal",
      default:
        "BoE interest rate (Base Rate) changes are often made in response to inflation and how the economy is performing.\n" +
        "\n" +
        "Mortgages that track the Base Rate will immediately be impacted by any change.\n" +
        "\n" +
        "But it's important to note that lenders can change their Standard Variable Rate (or SVR) at any time. Though these changes are often linked to the Base Rate.",
    },
  },
} as const
