import { ButtonTrackingId } from "../../../constants/buttonTrackingIds"
import Info from "../../../images/Info.svg?react"
import MenuCross from "../../../images/MenuCross.svg?react"
import Button, { ButtonFill, ButtonStyle } from "../Button"
import ContentBox from "../ContentBox"
import s from "./InfoBox.module.scss"
export interface InfoBoxProps {
  title: JSX.Element
  body: JSX.Element
  hide: () => void
  onCloseTrackingId?: ButtonTrackingId
}

const InfoBox = ({ title, body, hide, onCloseTrackingId }: InfoBoxProps) => {
  return (
    <ContentBox topMargin noFullWidth className={s.infoBox}>
      <div className={s.topBar}>
        <Info className={s.infoIcon} />
        <Button
          fill={ButtonFill.NONE}
          style={ButtonStyle.NONE}
          onClick={hide}
          trackingId={onCloseTrackingId}
          label={"Close"}
        >
          <MenuCross className={s.menuCrossIcon} />
        </Button>
      </div>
      {title}
      {body}
    </ContentBox>
  )
}
export default InfoBox
