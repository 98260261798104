export enum currentSituationContent {
  SEE_RATES = "see_rates", // A1
  MORE_OPTIONS = "more_options", // A2
  BOOK_APPOINTMENT = "book_appointment", // B1
  SET_REMINDER = "set_reminder", // C1
  BOOK_APPOINTMENT_CTA = "book_appointment_CTA", // C2
  STOP_ALERTS_CTA = "stop_alerts_CTA", // B2/C3
  CONTACT_US = "contact_us", // B3/C4
}

export interface CurrentSituationStore {
  currentSituationBeenExpanded: boolean
}
