import * as Sentry from "@sentry/react"
import { get } from "lodash-es"
import { useCallback, useContext, createContext } from "react"
import invariant from "tiny-invariant"

import { isDevelopmentEnv } from "../../lib/isDevelopmentEnv"
import { isProductionEnv } from "../../lib/isProductionEnv"
import { consumerSideApi } from "../../redux/services/consumerSide"
import { GenericContent } from "../../types/retain/Content.types"
import { blocks } from "./list"
import { Block } from "./util.types"

const isTestEnv = import.meta.env.VITEST === "true"

function getUniqueId(
  ids: Map<string, string>,
  originalId: string,
  uniqueId?: string
): string {
  const id = uniqueId || originalId
  if (ids.has(id)) {
    return getUniqueId(ids, originalId, `${id}_`)
  } else {
    ids.set(id, originalId)
    return id
  }
}
export const BlocksContext = createContext([
  new Map<string, HTMLElement>(),
  new Map<string, string>(),
] as const)

export const hasDefault = (content: string | null | undefined) => {
  invariant(content, "Block is expected to have default")
  return content
}

type BlockType = "text" | "image" | "mdx" | "markdown"

function getContent(
  path: string,
  data: Partial<Record<string, GenericContent>> | undefined,
  fallback?: string | null,
  type?: BlockType
) {
  // Casting the gets as Blocks is reasonably safe as LabelPaths ensures they should exist and be typed
  const block = get(blocks, path) as Block
  const field = type !== "image" ? "content" : "image"
  const defaultOrFallback =
    fallback === undefined && isTestEnv ? block?.default : fallback
  if (type) {
    const expectedType = data?.[path]?.type || block?.type
    if (type !== expectedType) {
      const errorMessage = `Block ${path} was ${type}, not ${expectedType}`
      Sentry.captureMessage(errorMessage, "error")
      if (isDevelopmentEnv()) {
        throw new Error(errorMessage)
      }
      return null
    }
  }
  return data?.[path]?.[field] || defaultOrFallback || null
}

export function useContent_Unstable<Paths extends string = string>({
  type,
}: { type?: "text" | "image" | "mdx" | "markdown" } = {}) {
  const { data } = consumerSideApi.endpoints.getContent.useQueryState(undefined)
  const b = useCallback(
    (
      path: Paths,
      { fallback, check }: { fallback?: string | null; check?: boolean } = {}
    ) => {
      if (check) {
        // To enable gradual adoption, check is flagged and off by default.
        // Expect check to default to true after bMaybe is fully adopted.
        return hasDefault(getContent(path, data, fallback, type))
      }
      return getContent(path, data, fallback, type) as string
    },
    [data, type]
  )

  const bMaybe = useCallback(
    (
      path: Paths,
      {
        fallback,
        overrideType,
      }: { fallback?: string | null; overrideType?: BlockType } = {}
    ) => {
      return getContent(path, data, fallback, overrideType || type)
    },
    [data, type]
  )

  const [blockRefs, blockIds] = useContext(BlocksContext)
  const bRef = useCallback(
    (path: Paths) => {
      const isProduction = isProductionEnv()
      const uniqueId = isProduction ? path : getUniqueId(blockIds, path)
      return isProduction
        ? undefined
        : (node: HTMLElement | null) => {
            if (node) {
              blockRefs.set(uniqueId, node)
            } else {
              blockRefs.delete(uniqueId)
              blockIds.delete(uniqueId)
            }
          }
    },
    [blockRefs, blockIds]
  )

  return { b, bMaybe, bRef }
}
