import { LabelPaths } from "../../../hooks/blocks/util.types"

export const lenderOptionsPageBlocks = {
  supportCta: {
    type: "text",
    description: "Content for Options support CTA",
    default: "Get support",
  },
  phoneNumber: {
    type: "text",
    description: "Content for Options phone number",
    default: null,
  },
  phoneUsIf: {
    type: "markdown",
    description: "Content for Options phone us if",
    default: `
### Phone us first if...

You are in financial difficulty or recently had a change in circumstances that is impacting your ability to pay.

If you want to make an overpayment (you will need to do so before switching).

If you want to make other changes to your mortgage (i.e. change term, repayment method, etc).
      `,
  },
  callTimes: {
    type: "markdown",
    description: "Content for Options call times",
    default: `
Monday to Friday 8am - 8pm

Saturday 9am - 1pm
      `,
  },
  lender: {
    options: {
      intro: {
        title: {
          type: "text",
          description: "Title for Options intro page",
          default: "Your options",
        },
        withoutAdvice: {
          content: {
            type: "markdown",
            description: "Content for Options intro page without advice",
            default:
              "Select the first option if you’re happy to select a mortgage without advice or help.",
          },
        },
        externalAdvice: {
          content: {
            type: "markdown",
            description: "Content for Options intro page with external advice",
            default:
              "If you would prefer to speak to an independent broker, select the ‘Find advisor’ option.",
          },
        },
        internalAdvice: {
          content: {
            type: "markdown",
            description: "Content for Options intro page with internal advice",
            default:
              "Choose ‘Get help’ option if you need to talk about your mortgage deal.",
          },
        },
        withSwitchSupport: {
          content: {
            type: "markdown",
            description: "Content for Options intro page with switch support",
            default: null,
          },
        },
      },
      zeroMortgages: {
        image: {
          type: "image",
          description: "Image on Options no mortgages page",
          default: null,
        },
        headerContent: {
          type: "markdown",
          description: "Heading element for Options no mortgages page",
          default: "You have no mortgages with us that need to be actioned right now",
        },
        bodyContent: {
          type: "markdown",
          description: "Body element for Options no mortgages page",
          default:
            "Get in touch with your advisor if you'd like to enquire about a new mortgage.",
        },
      },
    },
  },
  options: {
    pausedBox: {
      title: {
        type: "text",
        description: "Title for Options paused box",
        default: null,
      },
      content: {
        type: "markdown",
        description: "Content for Options paused box",
        default: `Alerts are now paused, you will still be alerted as soon as new rates are available.
In the meantime, you can explore the process to better understand the switching procedure.`,
      },
    },
    actionedContentBox: {
      title: {
        type: "text",
        description: "Title for Options actioned box",
        default: null,
      },
      content: {
        type: "markdown",
        description: "Content for Options actioned box",
        default: `Thanks for actioning your mortgage, we will process your application shortly.
In the meantime, you can explore the process to better understand the switching procedure.`,
      },
    },
    fullWidthImage: {
      type: "image",
      description: "Options header page image",
      default: null,
    },
    icon: {
      type: "image",
      description: "Options header page icon",
      default: null,
    },
  },
} as const

export type LenderOptionsPageLabelPaths = LabelPaths<typeof lenderOptionsPageBlocks>
