import { ReactNode, useContext } from "react"

import { Button } from "../../../components/common"
import { ButtonFill, ButtonStyle } from "../../../components/common/Button"
import { InfoBoxContext } from "../../../components/consumer/LenderWithoutAdvicePage/InfoBoxProvider"
import buttonTrackingIds from "../../../constants/buttonTrackingIds"
import s from "./OpenInfoBoxButton.module.scss"

type ButtonProps = {
  children?: ReactNode
}

const OpenInfoBoxButton = ({ children }: ButtonProps) => {
  const [state, setState] = useContext(InfoBoxContext)

  return (
    <Button
      className={s.toggleInfoBox}
      style={ButtonStyle.NONE}
      fill={ButtonFill.NONE}
      onClick={() => setState(!state)}
      trackingId={
        state
          ? buttonTrackingIds.ACCOUNT_NUMBER__INFO_BOX__CLOSE
          : buttonTrackingIds.ACCOUNT_NUMBER__INFO_BOX__OPEN
      }
    >
      {children}
    </Button>
  )
}

export default OpenInfoBoxButton
