import { LabelPaths } from "../../../../hooks/blocks/util.types"

export const productSelectionSummaryPageBlocks = {
  lender: {
    selection: {
      summary: {
        title: {
          type: "text",
          description: "Heading element for the /product-selection/thanks page",
          default: "Selection summary",
        },
        removeProductButton: {
          labelContent: {
            type: "text",
            description: "Label for the remove product button",
            default: "Remove product",
          },
        },
        confirmation: {
          descriptionContent: {
            type: "markdown",
            description: "Confirmation description for the summary page",
            default: `Click ‘Confirm’ if you’re happy to move forward without advice.

This means you will not benefit from the same level of protection as a customer who has received advice on the suitability of the product switch (which may include the right to refer to the Financial Ombudsman Service).`,
          },
        },
        variableRatesModal: {
          body: {
            type: "text",
            description:
              "Message for the variable rates modal shown for discount products",
            default:
              "You have selected a discount mortgage product, this rate can vary in line with our variable mortgage rate, do you wish to continue?",
          },
          continueButton: {
            type: "text",
            description: "Label for the variable rates modal continue button",
            default: "I understand",
          },
          backButton: {
            type: "text",
            description: "Label for the variable rates modal back button",
            default: "Go back",
          },
        },
        confirmationButton: {
          labelContent: {
            type: "text",
            description: "Label for the confirmation button",
            default: "Confirm",
          },
        },
        contactContent: {
          type: "text",
          description: "Contact content for the summary page",
          default: "How would you like to receive your Product Switch offer?",
        },
        beforeDealSwitchChoice: {
          type: "mdx",
          description: `The card showing the Chosen Dynamic Switch Date on the summary page, if the choice is before the end of the deal. Props example: "You've chosen to switch {props.dayCount} {props.dayWord} early" could be "You've chosen to switch 5 days early" or "You've chosen to switch 1 day early"`,
          default: "You've chosen to switch {props.dayCount} {props.dayWord} early",
        },
        switchStartContent: {
          type: "text",
          description: "Switch start content for the summary page",
          default: "When would you like your switch to happen?",
        },
        fees: {
          label: {
            type: "text",
            description: "Label for the fees preference radio group",
            default: "How would you like to pay your fees?",
          },
        },
        disclaimer: {
          type: "mdx",
          description:
            "Disclaimer text (optional). Can use {props.givenCurrentBalance}. If givenCurrentBalance does not exist, then this block will not render.",
          default: null,
        },
      },
    },
  },
} as const

export type ProductSelectionSummaryLabelPaths = LabelPaths<
  typeof productSelectionSummaryPageBlocks
>
