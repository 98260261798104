import { LabelPaths } from "../../../hooks/blocks/util.types"

export const changeDataPopInBlocks = {
  consumerActionChangeDataMessage: {
    type: "text",
    description: "Label for explanation field",
    default: "Tell us what needs fixing and we will be back to you ASAP!",
  },
} as const

export type ChangeDataPopInLabelPaths = LabelPaths<typeof changeDataPopInBlocks>
