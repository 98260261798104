import axios from "axios"
import { useCallback } from "react"
import { useLocation } from "react-router"

import endpoints from "../constants/endpoints"
import reportAxiosError from "../lib/reportAxiosError"
import { useAppSelector } from "../redux/store"
import useIdent from "./useIdent"

type Params = {
  path: string
  buttonClicked?: string
}

type TrackClick = (buttonTrackingId: string, extraContext?: string) => void

export interface TrackClickEventProps {
  trackClick: TrackClick
}

// For use in functional components
const useTrackClick = (): TrackClickEventProps => {
  const { pathname: path } = useLocation()
  const { userId, token } = useIdent()
  const isAuth = useAppSelector((state) => state.auth.isAuth)
  const trackClick: TrackClick = useCallback(
    (buttonTrackingId: string, extraContext?: string) => {
      let buttonClicked: string = buttonTrackingId
      if (extraContext) {
        buttonClicked += ` [${extraContext}]`
      }

      const queryParams = {
        userId: userId || undefined,
        token: token || undefined,
      }

      const params: Params = { path, buttonClicked }

      axios
        .post(
          endpoints.pageView,
          !isAuth && queryParams.userId && queryParams.token
            ? { ...params, ...queryParams }
            : params
        )
        .catch((err: unknown) => {
          reportAxiosError(err)
        })
    },
    [isAuth, path, userId, token]
  )

  return {
    trackClick,
  }
}

export default useTrackClick
