import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export interface ProductSelectionState {
  selected: Record<string, string[]>
  accepted: string | null
  feesToggleState: boolean
  chosenSwitchDate: string | null
}

const initialState: ProductSelectionState = {
  selected: {},
  accepted: null,
  feesToggleState: false,
  chosenSwitchDate: null,
}

const productSelectionSlice = createSlice({
  name: "productSelection",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<{ mortgageId: string; productId: string }>) => {
      const { mortgageId, productId } = action.payload
      state.selected[mortgageId] = [
        ...new Set([...(state.selected[mortgageId] || []), productId]),
      ]
    },
    remove: (
      state,
      action: PayloadAction<{ mortgageId: string; productId: string }>
    ) => {
      const { mortgageId, productId } = action.payload
      const products = state.selected[mortgageId]
      if (!products) {
        return
      }
      const index = products.indexOf(productId)
      if (index < 0) {
        return
      }
      products.splice(index, 1)
      if (products.length === 0) {
        delete state.selected[mortgageId]
      }
    },
    clear: (state) => {
      state.selected = {}
    },
    acceptanceStored: (state, action: PayloadAction<string>) => {
      state.accepted = action.payload
    },
    toggleFees: (state) => {
      state.feesToggleState = !state.feesToggleState
    },
    chooseSwitchDate: (state, action: PayloadAction<string | null>) => {
      state.chosenSwitchDate = action.payload
    },
  },
})

export const { add, remove, clear, acceptanceStored, toggleFees, chooseSwitchDate } =
  productSelectionSlice.actions

export default productSelectionSlice.reducer
