import { AxiosError } from "axios"
import { isObject } from "lodash-es"

export default function buildTagsFrom403(error: AxiosError) {
  if (error.response?.status === 403) {
    try {
      const reason = isObject(error.response.data)
        ? JSON.stringify(error.response.data)
        : String(error.response.data)
      return {
        // \n is not valid in Sentry tags and we see it in the server response
        "403.reason": reason.replace("\n", " ") || "",
      }
    } catch {
      return null
    }
  }
  return null
}
