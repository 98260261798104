export enum lifecycleModule {
  INTEREST_RATES = "interest-rates",
  PRODUCT_RATES = "product-rates",
  EXPIRY_DATE = "expiry-date",
  PROPERTY_VALUE = "property-value",
  LOAN_TO_VALUE = "loan-to-value",
}

export type LifecycleAlertModule = Exclude<
  lifecycleModule,
  lifecycleModule.LOAN_TO_VALUE
>

export type LifecycleProspectStatus = Exclude<
  lifecycleModule,
  lifecycleModule.LOAN_TO_VALUE | lifecycleModule.EXPIRY_DATE
>
