import cx from "classnames"

import s from "./ActionBox.module.scss"

type ActionBoxProps = React.HTMLAttributes<HTMLDivElement>

const ActionBox = ({ children, className }: ActionBoxProps) => {
  return <div className={cx(s.actionBox, className)}>{children}</div>
}

export default ActionBox
