import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface ProductRatesState {
  initialTerm?: string
}

const initialState: ProductRatesState = {
  initialTerm: undefined,
}

const productRatesSlice = createSlice({
  name: "productRates",
  initialState,
  reducers: {
    updateInitialTerm(state, action: PayloadAction<string>) {
      state.initialTerm = action.payload
    },
  },
})

export const { updateInitialTerm } = productRatesSlice.actions
export default productRatesSlice.reducer
