import { createApi } from "@reduxjs/toolkit/dist/query/react"

import { UNASSIGNED } from "../../../components/common/MixedChart/constants"
import { Advisor } from "../../../types/retain/Advisor.types"
import axiosBaseQuery from "../axiosBaseQuery"

export const reportingAdvisorsApi = createApi({
  reducerPath: "reportingAdvisorsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  endpoints: (builder) => ({
    getReportsAdvisors: builder.query<Advisor[], void>({
      query: () => ({
        url: `/management-reports/advisors/`,
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
      transformResponse: (response: Advisor[]) => {
        return response.sort((a, b) => {
          // this sort for unassigned last
          if (a.id === UNASSIGNED) {
            return 1
          }
          if (b.id === UNASSIGNED) {
            return -1
          }
          return a.name.localeCompare(b.name)
        })
      },
    }),
  }),
})

export const { useGetReportsAdvisorsQuery } = reportingAdvisorsApi
