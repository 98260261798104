import ActionType from "../../../constants/actionTypes"
import { BusinessConsumerState } from "../../../types/retain/Consumer.types"

export const initialState = {
  markAsPromptVisible: false,
}

type PromptVisibilityAction = {
  type: ActionType
  visible: boolean
}

export default function (
  state: BusinessConsumerState = initialState,
  action: PromptVisibilityAction
): BusinessConsumerState {
  switch (action.type) {
    case ActionType.CONSUMER_MARK_AS_PROMPT_TOGGLE:
      return {
        markAsPromptVisible: action.visible,
      }
    default:
      return state
  }
}
