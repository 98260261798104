import * as Sentry from "@sentry/react"
import queryString, { ParsedQuery } from "query-string"
import { RouteComponentProps } from "react-router"

import logSentryBreadcrumb from "./logSentryBreadcrumb"

const urlLinkParams = {
  REQUEST_CALL: "request_call",
  IDENT_TOKEN: "token",
  AUTH_TOKEN: "auth_token",
  USER_ID: "user_id",
  MORTGAGE: "mortgage_id",
  EMAIL: "email",
  IS_PASSWORD_CREATE: "is_create",
  RESET_TOKEN_PARAM: "reset_token",
} as const

type urlLinkParams = typeof urlLinkParams[keyof typeof urlLinkParams]

export const removeURLSearchParams = (
  history: RouteComponentProps["history"],
  currentQuery: ParsedQuery,
  queriesToRemove: string[]
) => {
  const newQuery = queriesToRemove.reduce(
    (acc, key) => ({
      ...acc,
      [key]: null,
    }),
    currentQuery
  )
  Sentry.setContext("URL Search Params (Remaining)", {
    IDs: Object.entries(newQuery)
      .filter(([_, value]) => !!value)
      .map(([key]) => key),
  })
  history.replace({
    search: `?${queryString.stringify(newQuery, {
      skipNull: true,
      skipEmptyString: true,
    })}`,
  })
}

export function handleURLSearchParams(
  history: RouteComponentProps["history"],
  locationSearch: string
) {
  const {
    IDENT_TOKEN,
    AUTH_TOKEN,
    USER_ID,
    EMAIL,
    MORTGAGE,
    IS_PASSWORD_CREATE,
    RESET_TOKEN_PARAM,
  } = urlLinkParams

  const query = queryString.parse(locationSearch)
  const urlLinkParamIds: urlLinkParams[] = Object.values(urlLinkParams)

  const paramIds = urlLinkParamIds.filter((id) => !!query[id])

  Sentry.setContext("URL Search Params (Initial)", {
    IDs: paramIds,
  })

  removeURLSearchParams(history, query, urlLinkParamIds)

  return {
    authToken: paramsAsString(query[AUTH_TOKEN]),
    identToken: paramsAsString(query[IDENT_TOKEN]),
    userId: paramsAsString(query[USER_ID]),
    mortgageId: paramsAsString(query[MORTGAGE]),
    email: paramsAsString(query[EMAIL]),
    isCreatingPassword: query[IS_PASSWORD_CREATE] === "true",
    resetToken: paramsAsString(query[RESET_TOKEN_PARAM]),
  }
}

export function paramsAsString(
  key: string | (string | null)[] | null
): string | undefined {
  if (!key) {
    return undefined
  }
  if (Array.isArray(key)) {
    logSentryBreadcrumb({
      message: `URL Param was not expected to be multiple values`,
      data: { values: key.join(", ") },
      level: "error",
    })
    return undefined
  }
  return key
}
