export const alreadyActionedFormBlocks = {
  alreadyActionedFormSubmit: {
    type: "text",
    description: "Already actioned form submit button text",
    default: "Disable all alerts",
  },
  alreadyActionedFormSubmitLoading: {
    type: "text",
    description:
      "Already actioned form submit button text - while submission is in progress and button is in loading state",
    default: "Disabling alerts...",
  },
  stopAlerts: {
    title: {
      type: "text",
      description: "Already actioned form title",
      default: "Stop alerts",
    },
    image: {
      type: "image",
      description: "Already actioned form image",
      default: null,
    },
  },
  stopAlertsMultipleMortgages: {
    type: "markdown",
    description: "Already actioned form content for multiple mortgages",
    default:
      "To stop email notifications, select the right properties and let us know how you're dealing with your mortgage.",
  },
  stopAlertsSingleMortgage: {
    type: "markdown",
    description: "Already actioned form content for single mortgage",
    default:
      "To stop email notifications, select the right property and let us know how you're dealing with your mortgage.",
  },
}
