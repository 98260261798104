import { RunnerOptions } from "@mdx-js/mdx/lib/util/resolve-evaluate-options"
import * as Sentry from "@sentry/react"
import { MDXModule } from "mdx/types"
import { useEffect, useState } from "react"
// https://reactjs.org/docs/concurrent-mode-adoption.html#feature-comparison
import { unstable_batchedUpdates } from "react-dom"
import { Link } from "react-router-dom"
import * as runtime from "react/jsx-runtime"

import retry from "../../lib/retry"
import Accordion from "./defaults/Accordion"
import AccountNumberInfoBox from "./defaults/AccountNumberInfoBox"
import ExternalLink from "./defaults/ExternalLink"
import LinkButton from "./defaults/LinkButton"
import OpenInfoBoxButton from "./defaults/OpenInfoBoxButton"
import RemainingRatesTable from "./defaults/RemainingRatesTable"
import WrappedInfoBox from "./defaults/WrappedInfoBox"
import { DynamicComponents } from "./dynamics/DynamicComponent.types"

/**
 * MDX Rendering Hook (with default/dynamic components + string props)
 * @param source MDX string to be parsed
 * @param componentNames Dynamic components to be loaded for the MDX to use (optional)
 * @param passedProps String values to be rendered within the MDX via {props.passedPropName}
 * @returns
 */
export function useMdx(
  source: string,
  componentNames: DynamicComponents[] = [],
  passedProps: Record<string, string | boolean | number | null> = {}
) {
  const [output, setOutput] = useState<MDXModule>()
  const [componentPaths] = useState(componentNames)
  const [components, setComponents] = useState({})
  const [renderError, setRenderError] = useState(false)

  useEffect(() => {
    const runnerOptions = { ...runtime } as RunnerOptions
    const otherOptions = {
      outputFormat: "function-body",
    }
    let ignore = false
    const evaluateSourceAndImportComponents = async () => {
      try {
        const mdx = await retry(() => import("@mdx-js/mdx"))
        const defaultComponents = [
          LinkButton,
          WrappedInfoBox,
          Accordion,
          Link,
          OpenInfoBoxButton,
          AccountNumberInfoBox,
          ExternalLink,
          RemainingRatesTable,
        ]
        const mdxDefaultNames = [
          "LinkButton",
          "WrappedInfoBox",
          "Accordion",
          "Link",
          "OpenInfoBoxButton",
          "AccountNumberInfoBox",
          "ExternalLink",
          "RemainingRatesTable",
        ]
        const importedComponents = await Promise.all(
          componentPaths.map((component) => {
            return retry(() => import(`./dynamics/${component}.tsx`))
          })
        )
        const defaultMap = defaultComponents.reduce(
          (mapped, comp: Function, currentIndex) => {
            return { ...mapped, [mdxDefaultNames[currentIndex]]: comp }
          },
          {}
        )
        const importMap = importedComponents.reduce(
          (mapped, comp: { default: Function }, currentIndex) => {
            return { ...mapped, [componentPaths[currentIndex]]: comp.default }
          },
          {}
        )
        const result = await mdx.evaluate(source, {
          ...runnerOptions,
          ...otherOptions,
        })
        if (!ignore) {
          unstable_batchedUpdates(() => {
            setComponents({ ...defaultMap, ...importMap })
            setOutput(result)
          })
        }
      } catch (error) {
        if (error instanceof Error) {
          Sentry.captureException(error)
        }
        setRenderError(true)
      }
    }
    evaluateSourceAndImportComponents()
    return () => {
      ignore = true
    }
  }, [source, componentPaths])

  if (renderError) {
    return (
      <div>
        Apologies, the text we wanted to display here could not be loaded. Please try
        refreshing.
      </div>
    )
  }

  return output?.default({ components, ...passedProps }) ?? undefined
}
