import localStorageKeys from "../constants/localStorageKeys"
import { PermissionGroup } from "../types/retain/Permission.types"
import { removeLocalStorageItems } from "./localStorageUtil"
import logSentryBreadcrumb from "./logSentryBreadcrumb"

export const removeLocalStorageIdentificationItems = () => {
  logSentryBreadcrumb({
    message: `All localStorage Ident items removed.`,
    level: "info",
  })
  removeLocalStorageItems([
    localStorageKeys.currentUserId,
    localStorageKeys.identToken,
    localStorageKeys.mortgage,
    localStorageKeys.email,
    localStorageKeys.isCreatingPassword,
    localStorageKeys.resetToken,
  ])
}

export const getPermissionGroup = (
  groups: PermissionGroup[],
  id: string | null
): PermissionGroup => {
  const group = groups.find((group) => group.id === id)
  if (!group) {
    return {
      id: "#",
      name: "Not yet assigned",
      description: "Not yet assigned",
    }
  }
  return group
}
