export enum BlogPostFormat {
  HTML = "html",
  MARKDOWN = "markdown",
  MDX = "mdx",
}

export interface BlogPost {
  id: string
  slug: string
  title: string
  body: string
  featuredImage: string
  featuredThumbnail: string
  readingTime: number
  callToActionText: string
  callToActionUrl: string
  tagNames: Array<string>
  format: BlogPostFormat
  type: ArticleType
}

export enum ArticleType {
  LENDER = "lender",
  BROKER = "broker",
}

export enum userTypesForBlogRequest {
  LOGGED_IN = "logged-in",
  PRE_CREATE = "pre-create-account",
  PRE_LOGIN = "pre-login",
}

export type BlogPostAction = "view" | "cta" | "feedback"

export type BlogPostActionExtraContent = {
  ctaContent?: string
  feedback?: BlogPostFeedbackScore
}

export type BlogPostFeedbackScore = -1 | 0 | 1

export interface AllFeedback {
  [slug: string]: BlogPostFeedbackScore
}
