import useGetMortgagesQueryAuth from "../redux/services/mortgages/useGetMortgagesQueryAuth"
import usePropertyId from "./usePropertyId"

type PropertyHookProps = {
  throwIfMissing: boolean
  overrideId?: string | null
}

/**
 * @returns The property that matches the URL :propertyId
 */
function useProperty(props: PropertyHookProps = { throwIfMissing: true }) {
  const { data, isSuccess } = useGetMortgagesQueryAuth()
  const properties = data?.properties || []
  const paramId = usePropertyId(props)
  const propertyId = props.overrideId || paramId
  return isSuccess ? properties.find((property) => property.id === propertyId) : null
}

export default useProperty
