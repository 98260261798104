import { ReactNode, useMemo } from "react"

import { isProductionEnv } from "../../lib/isProductionEnv"
import { BlocksContext } from "./useContent_Unstable"

export function BlocksOverlayProvider({ children }: { children: ReactNode }) {
  const refsAndIds = useMemo(
    () => [new Map<string, HTMLElement>(), new Map<string, string>()] as const,
    []
  )
  if (isProductionEnv()) {
    return <>{children}</>
  }
  return <BlocksContext.Provider value={refsAndIds}>{children}</BlocksContext.Provider>
}
