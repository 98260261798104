import { createApi } from "@reduxjs/toolkit/query/react"

import { UserActivity } from "../../../components/business/UserSettings/UserSettings.types"
import { Advisor, EmployeeUser } from "../../../types/retain/Advisor.types"
import axiosBaseQuery from "../axiosBaseQuery"

type EmployeesQueryOptions = {
  includeInactive?: boolean
}

type SingleEmployeeOptions = {
  id: string
}

export const SINGLE_TAG = "Single"
export const LIST_TAG = "List"

export const employeeFactorApi = createApi({
  reducerPath: "employeeFactorApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  tagTypes: [SINGLE_TAG, LIST_TAG],
  endpoints: (builder) => ({
    getAdvisors: builder.query<Advisor[], { onlyAssignable?: boolean }>({
      query: ({ onlyAssignable }) => ({
        url: `/advisors`,
        params: {
          active: 1,
          onlyAssignable,
        },
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
      providesTags: [LIST_TAG],
    }),
    getEmployees: builder.query<EmployeeUser[], EmployeesQueryOptions>({
      query: (options) => ({
        url: `/employee`,
        params: {
          include_inactive: options?.includeInactive ? "True" : null,
        },
      }),
      providesTags: [LIST_TAG],
    }),
    getEmployeeDetail: builder.query<EmployeeUser, SingleEmployeeOptions>({
      query: ({ id }) => ({
        url: `/employee/${id}`,
      }),
      providesTags: [SINGLE_TAG],
    }),
    getEmployeeActivity: builder.query<UserActivity, SingleEmployeeOptions>({
      query: ({ id }) => ({
        url: `/employee/${id}/activity_summary`,
      }),
      providesTags: [SINGLE_TAG],
    }),
    deleteEmployee: builder.mutation<EmployeeUser, SingleEmployeeOptions>({
      query: ({ id }) => ({
        method: "DELETE",
        url: `/employee/${id}`,
      }),
      invalidatesTags: [LIST_TAG],
    }),
  }),
})

export const {
  useGetAdvisorsQuery,
  useGetEmployeesQuery,
  useGetEmployeeDetailQuery,
  useGetEmployeeActivityQuery,
  useDeleteEmployeeMutation,
} = employeeFactorApi
