import { runtimeEnv as env } from "./runtimeEnv"

function checkIsDevLikeEnvironment() {
  return (
    env.ENV_ENVIRONMENT === "development" ||
    env.ENV_ENVIRONMENT === "staging" ||
    env.ENV_ENVIRONMENT === "review"
  )
}

export default checkIsDevLikeEnvironment
