import { FocusEventHandler, KeyboardEvent, MouseEvent, ReactNode } from "react"

export const ButtonFill = {
  ACTION: "action",
  SOLID_ACTION: "solid-action",
  CONTRAST: "contrast",
  SECONDARY_CONTRAST: "secondary-contrast",
  WARNING: "warning",
  DANGER: "danger",
  PRIMARY: "primary",
  NONE: "transparent",
  CONFIRM: "confirm",
  CANCEL: "cancel",
} as const

export type ButtonFill = (typeof ButtonFill)[keyof typeof ButtonFill]

export const ButtonStyle = {
  NONE: "",
  OUTLINE: "outline",
  GHOST: "ghost",
  SOLID: "solid",
  ICON: "icon",
  BUTTON_AS_LINK: "button-as-link",
} as const

export type ButtonStyle = (typeof ButtonStyle)[keyof typeof ButtonStyle]

export const ButtonType = {
  BUTTON: "button",
  SUBMIT: "submit",
} as const

export type ButtonType = (typeof ButtonType)[keyof typeof ButtonType]

export const ButtonSize = {
  DEFAULT: "sizeDefault",
  LARGE: "sizeLarge",
} as const

export type ButtonSize = (typeof ButtonSize)[keyof typeof ButtonSize]

export interface ButtonProps {
  children: ReactNode
  className?: string
  disabled?: boolean
  fill?: ButtonFill
  fullWidth?: boolean
  icon?: ReactNode
  id?: string
  label?: string
  loading?: boolean
  onClick?: (e?: MouseEvent | KeyboardEvent) => void
  onEnter?: () => void
  onFocus?: FocusEventHandler
  onBlur?: FocusEventHandler
  onMouseEnter?: (e?: MouseEvent | KeyboardEvent) => void
  onMouseLeave?: (e?: MouseEvent | KeyboardEvent) => void
  reverseIconPosition?: boolean
  size?: ButtonSize
  style?: ButtonStyle
  trackingId?: string
  trackingLabel?: string
  type?: ButtonType
  topMargin?: boolean
}
