import { createApi } from "@reduxjs/toolkit/query/react"

import axiosBaseQuery from "../axiosBaseQuery"

enum onboardingTagTypes {
  FIRM_SETTINGS = "firm-settings",
}

export type Crms = {
  id: string
  name: string
  guideBeaconId: string | null
}

export const onboardingFactorApi = createApi({
  reducerPath: "onboardingFactorApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  tagTypes: Object.values(onboardingTagTypes) as string[],
  endpoints: (builder) => ({
    getFirmSettings: builder.query({
      query: ({ id }) => ({ url: `/firm-settings/${id}/` }),
      providesTags: [onboardingTagTypes.FIRM_SETTINGS],
    }),
    patchFirmSettings: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/firm-settings/${id}/`,
        method: "PATCH",
        requestData: formData,
      }),
      invalidatesTags: [onboardingTagTypes.FIRM_SETTINGS],
    }),
    uploadColorLogo: builder.mutation({
      query: ({ id, file }) => {
        return {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
          url: `/firm-settings/${id}/full_color_logo/`,
          method: "POST",
          requestData: file,
        }
      },
    }),
    uploadLightLogo: builder.mutation({
      query: ({ id, file }) => {
        return {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
          url: `/firm-settings/${id}/white_logo/`,
          method: "POST",
          requestData: file,
        }
      },
    }),
    uploadSquareLogo: builder.mutation({
      query: ({ id, file }) => {
        return {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
          url: `/firm-settings/${id}/square_logo/`,
          method: "POST",
          requestData: file,
        }
      },
    }),
    getCrms: builder.query<Crms[], void>({
      query: () => ({ url: `/crms` }),
    }),
  }),
})

export const {
  useGetCrmsQuery,
  useGetFirmSettingsQuery,
  usePatchFirmSettingsMutation,
  useUploadColorLogoMutation,
  useUploadLightLogoMutation,
  useUploadSquareLogoMutation,
} = onboardingFactorApi
