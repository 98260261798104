import { Dispatch } from "redux"

import ActionType from "../../constants/actionTypes"
import { RouteState } from "../../types/retain"

export const updateRedirect = (redirectTo: RouteState["redirectTo"]) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: ActionType.ROUTE_REDIRECT_UPDATE,
      redirectTo,
    })
  }
}
