import axios, { AxiosResponse, isAxiosError } from "axios"

import endpoints from "../../constants/endpoints"

export const ORIGIN_NULL_MSG = "Value 'null' in 'Origin' header"

/**
 * Retrys the /me endpoint exactly once if a 403 Origin:null error is encountered
 * and fails normally with other responses.
 */
export const retryUserOnce = (params?: { token: string; user_id: string }) =>
  // Allowing any here to mimic the axios return as originally typed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new Promise<AxiosResponse<any, any>>((resolve, reject) => {
    axios
      .get(endpoints.user, {
        params,
      })
      .then(resolve)
      .catch((error: unknown) => {
        if (
          isAxiosError(error) &&
          error.response?.status === 403 &&
          error.response?.data === ORIGIN_NULL_MSG
        ) {
          axios
            .get(endpoints.user, {
              params,
            })
            .then(resolve)
            .catch(reject)
        } else {
          reject(error)
        }
      })
  })
