import dayjs from "dayjs"
import { ReactElement, useMemo } from "react"
import Countdown, { zeroPad } from "react-countdown"

import BasicModal from "../BasicModal"
import DismissExpiryKeypressListener from "./DismissExpiryKeypressListener"
import { formatForAuthExpiry } from "./formatForAuthExpiry"
import useAuthExpiry from "./useAuthExpiry"

function AuthExpiry() {
  const { authExpiry, shown, dismiss } = useAuthExpiry()

  const expiryUnixTimestamp = useMemo(() => {
    if (!authExpiry) {
      return null
    }
    return dayjs(authExpiry, formatForAuthExpiry).valueOf()
  }, [authExpiry])

  if (!expiryUnixTimestamp) {
    return null
  }

  return (
    <BasicModal
      title="Your session is about to expire"
      buttons={[
        {
          children: "Keep me logged in",
          onClick: dismiss,
        },
      ]}
      hide={dismiss}
      shown={shown}
    >
      <>
        {shown && <DismissExpiryKeypressListener dismiss={dismiss} />}
        <p>
          For your security, we'll log you out in{" "}
          <Countdown
            date={expiryUnixTimestamp}
            renderer={({ minutes, seconds }): ReactElement => (
              <>
                {minutes > 0
                  ? `${zeroPad(minutes)}:${zeroPad(seconds)} minutes. `
                  : `${zeroPad(seconds)} seconds. `}
              </>
            )}
          />
          Do you wish to stay logged in?
        </p>
      </>
    </BasicModal>
  )
}

export default AuthExpiry
