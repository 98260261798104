import { useContext } from "react"

import InfoBox from "../../../components/common/InfoBox"
import s from "../../../components/common/InfoBox/InfoBox.module.scss"
import { InfoBoxContext } from "../../../components/consumer/LenderWithoutAdvicePage/InfoBoxProvider"
import buttonTrackingIds from "../../../constants/buttonTrackingIds"
import { ContentId } from "../../../types/retain/Content.types"
import { useContent_Unstable } from "../../blocks/useContent_Unstable"
import { InfoBoxesLabelPaths } from "./infoBoxesBlocks"

const titleId = ContentId.ACCOUNT_NUMBER_INFO_BOX_TITLE
const bodyId = ContentId.ACCOUNT_NUMBER_INFO_BOX_BODY
const AccountNumberInfoBox = () => {
  const [state, setState] = useContext(InfoBoxContext)
  const { b, bRef } = useContent_Unstable<InfoBoxesLabelPaths>()

  if (state) {
    return (
      <InfoBox
        title={
          <h4 ref={bRef(titleId)} className={s.title}>
            {b(titleId)}
          </h4>
        }
        body={<p ref={bRef(bodyId)}>{b(bodyId)}</p>}
        hide={() => setState(false)}
        onCloseTrackingId={buttonTrackingIds.ACCOUNT_NUMBER__INFO_BOX__CLOSE}
      />
    )
  }

  return null
}

export default AccountNumberInfoBox
