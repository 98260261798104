import { useContext, useEffect, useRef, useState } from "react"

import { BlocksContext } from "../../../hooks/blocks/useContent_Unstable"
import { isProductionEnv } from "../../../lib/isProductionEnv"
import { useAppSelector } from "../../../redux/store"
import { Highlight } from "./Highlight"

function Overlay() {
  const [blockRefs, blockIds] = useContext(BlocksContext)
  const [rects, setRects] = useState<[string, DOMRect][]>([])
  const highlight = useAppSelector((state) => state.ui.DEV_TOOL_contentIsHighlighted)
  const intervalId = useRef<number | undefined>(undefined)
  useEffect(() => {
    if (!highlight) {
      window.clearInterval(intervalId.current)
      return
    }
    intervalId.current = window.setInterval(() => {
      setRects(
        Array.from(blockRefs).map(
          ([id, element]) => [id, element.getBoundingClientRect()] as [string, DOMRect]
        )
      )
    }, 20)
    return () => {
      window.clearInterval(intervalId.current)
    }
  }, [highlight, blockRefs])

  if (!highlight) {
    return null
  }
  return (
    <div
      style={{
        position: "fixed",
        pointerEvents: "none",
        zIndex: 10000,
        width: "100%",
        height: "100%",
      }}
    >
      {rects.map(([path, rect]) => (
        <Highlight
          key={path}
          path={blockIds.get(path) || "path not found"}
          left={rect.left}
          top={rect.top}
          width={rect.width}
          height={rect.height}
        />
      ))}
    </div>
  )
}

function BlocksOverlay() {
  return isProductionEnv() ? null : <Overlay />
}

export { BlocksOverlay }
