import cx from "classnames"

import Cross from "../../../images/Cross.svg?react"
import ButtonLink from "../ButtonLink"
import s from "./ErrorMessage.module.scss"

interface Props {
  id?: string
  center?: boolean
  margin?: boolean
  msg: string | string[]
  includeReloadMsg?: boolean
  children?: JSX.Element
}

function ErrorMessage({ id, center, margin, msg, includeReloadMsg, children }: Props) {
  const msgParts: string[] = typeof msg === "string" ? [msg] : msg

  return (
    <div
      className={cx(s.errorMessage, { [s.center]: center, [s.margin]: margin })}
      id={id ? `${id}-container` : undefined}
    >
      <div className={s.close_icon}>
        <Cross />
      </div>

      <div>
        <div className={s.text_container}>
          <div className={s.text_wrap} id={id}>
            {msgParts.map((part) => (
              <span key={part}>{part}</span>
            ))}
          </div>
        </div>

        {!!includeReloadMsg && (
          <div>
            Please try&nbsp;
            <ButtonLink
              className={s.reloadBtnLink}
              onClick={() => window.location.reload()}
            >
              reloading
            </ButtonLink>
            &nbsp;the page to see if this fixes the issue
          </div>
        )}

        {children}
      </div>
    </div>
  )
}

export default ErrorMessage
