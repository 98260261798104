import { Placement, Side } from "@floating-ui/react-dom-interactions"

/* This will get the opposite side of a Floating-UI placement.
 * Be careful to use context.placement if you are using this
 * to position something like an arrow - context.placement updates
 * depending on the tooltip position on the screen,
 * whereas the prop 'placement' passed to FloatingTooltip.tsx does not */
function getOppositeSide(placement: Placement): Side {
  switch (placement) {
    case "bottom-start":
    case "bottom":
    case "bottom-end":
      return "top"
    case "top-start":
    case "top":
    case "top-end":
      return "bottom"
    case "left-start":
    case "left":
    case "left-end":
      return "right"
    case "right-start":
    case "right":
    case "right-end":
    default:
      return "left"
  }
}

export default getOppositeSide
