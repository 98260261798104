import { useAppSelector } from "../../store"

import { useGetMortgagesQuery } from "."

function useGetMortgagesQueryAuth() {
  const isAuth = useAppSelector((state) => state.auth.isAuth)
  return useGetMortgagesQuery(undefined, { skip: !isAuth })
}

export default useGetMortgagesQueryAuth
