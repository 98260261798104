import cx from "classnames"
import { forwardRef, LegacyRef, MouseEvent, ReactNode } from "react"

import { ButtonTrackingId } from "../../../constants/buttonTrackingIds"
import useTrackClick from "../../../hooks/trackClick"
import s from "./ButtonLink.module.scss"

type ButtonLinkProps = {
  center?: boolean
  className?: string
  label?: string
  onClick: (e?: MouseEvent) => void // `e` is optional because we might call this upon Enter key press if onEnter is not provided
  onEnter?: () => void
  trackingId?: ButtonTrackingId
  children: ReactNode
}

const ButtonLink = forwardRef(
  (
    {
      center,
      className,
      label,
      onClick,
      onEnter,
      children,
      trackingId,
    }: ButtonLinkProps,
    ref: LegacyRef<HTMLDivElement> | undefined
  ) => {
    const { trackClick } = useTrackClick()
    return (
      <div
        ref={ref}
        className={cx(s.button, { [s.center]: center, [className || ""]: className })}
        role="button"
        aria-label={label}
        tabIndex={0}
        onClick={(e) => {
          if (trackingId) {
            trackClick(trackingId)
          }
          onClick(e)
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEnter ? onEnter() : onClick()
          }
        }}
      >
        {children}
      </div>
    )
  }
)

ButtonLink.displayName = "ButtonLink"

export default ButtonLink
