import { LabelPaths } from "../../../../hooks/blocks/util.types"

export const ltvDescriptionFallback = `Loan to value (LTV) ratio is the % of the property value you own or have a deposit for, compared to the mortgage amount. This is the maximum LTV permitted for this product.

We will use the latest valuation we have on file in order to calculate your Loan to value.`
export const productSelectionInfoModalsBlocks = {
  lender: {
    selection: {
      modal: {
        aprc: {
          title: {
            type: "text",
            description: "Title content of aprc modal",
            default: "APRC",
          },
          descriptionContent: {
            type: "markdown",
            description: "Description content of aprc modal",
            default:
              "APRC (or Annual Percentage Rate of Charge) is the percentage rate which your loan will cost you each year over the full mortgage term including all charges such as administration fees, valuation fees and booking fees at the start of the mortgage. The APRC should always be quoted to you when you make a mortgage application.",
          },
        },
        fees: {
          title: {
            type: "text",
            description: "Title content of fees modal",
            default: "Fees",
          },
          descriptionContent: {
            type: "markdown",
            description: "Description content of fees modal",
            default:
              "These are the conveyancing costs for refinancing your mortgage. Fees are estimated based on current market averages.",
          },
        },
        feesPreference: {
          title: {
            type: "text",
            description: "Title content of preference for paying fees modal",
            default: "Paying fees",
          },
          description: {
            type: "markdown",
            description: "Description content of fees preference modal",
            default:
              "You can choose to pay any fees up front or add them onto your mortgage which will increase your monthly payment. You will incur interest on the fee amount if added to your mortgage for the duration of your full term.",
          },
        },
        followOnPayment: {
          title: {
            type: "text",
            description: "Title content of follow on payment modal",
            default: "Follow-on payment",
          },
          descriptionContent: {
            type: "markdown",
            description: "Description content of follow on payment modal",
            default: "[content]",
          },
        },
        loanToValue: {
          title: {
            type: "text",
            description: "Title content of loan to value modal",
            default: "Loan-to-Value",
          },
          descriptionContentMdx: {
            type: "mdx",
            description:
              "Description content of loan to value modal. Available props are {props.givenCurrentBalance} and {props.givenCurrentLoanToValue}. If the data for these are not available then the value for `lender.selection.modal.loanToValue.fallbackDescriptionContent` will be used.",
            default: ltvDescriptionFallback,
          },
          fallbackDescriptionContent: {
            type: "markdown",
            description:
              "Fallback for the description content of loan to value modal. If the data for {props.givenCurrentBalance} or {props.givenCurrentLoanToValue} is missing, this will be displayed instead.",
            default: ltvDescriptionFallback,
          },
        },
      },
    },
  },
} as const

export type ProductSelectionInfoModalsPaths = LabelPaths<
  typeof productSelectionInfoModalsBlocks
>
