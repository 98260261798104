export const loadApprovalBlocks = {
  discardTitle: {
    type: "text",
    default: "Are you sure you don't want to import these changes ?",
    description: "Title for Load Approval discard record confirm modal",
  },
  discardCancelButton: {
    type: "text",
    default: "Cancel",
    description: "Cancel button label for Load Approval discard record confirm modal",
  },
  discardConfirmButton: {
    type: "text",
    default: "Confirm",
    description: "Confirm button label for Load Approval discard record confirm modal",
  },
  discardPreamble: {
    type: "text",
    default: "These changes will no longer be imported:",
    description: "Preamble for Load Approval discard record confirm modal",
  },
} as const
