import { LabelPaths } from "../../../hooks/blocks/util.types"

export const onboardingBlocks = {
  onboardingLoginArticleId: {
    type: "text",
    description: "The Beacon article UUID to be launched when onboarding initially",
    default: "6304b7de5c9cf50902eb54e2",
  },
  menuOverview: {
    type: "text",
    description: "The menu overview text",
    default: "Overview",
  },
  onboardingUploadCrmGuidLink: {
    type: "text",
    description: "The CRM Guide label",
    default: "this guide",
  },
  onboardingPoliciesTooltipLegalName: {
    type: "markdown",
    description: "Tooltip for the Legal Name field",
    default: "Registered legal name (not the trading name).",
  },
  onboardingPoliciesTooltipCompanyNumber: {
    type: "markdown",
    description: "Tooltip for the Company Number field",
    default: "Usually the number given by Companies House.",
  },
  onboardingPoliciesTooltipCompanyAddress: {
    type: "markdown",
    description: "Tooltip for the Company Address field",
    default: "Office address for local correspondence.",
  },
  onboardingPoliciesTooltipCompanyPostcode: {
    type: "markdown",
    description: "Tooltip for the Company Postcode field",
    default: "Postcode for local correspondence.",
  },
  onboardingPoliciesTooltipCompanyType: {
    type: "markdown",
    description: "Tooltip for the Company Type field",
    default: "Type of company (Limited, Sole Trader etc).",
  },
  onboardingPoliciesTooltipRegisteredCountries: {
    type: "markdown",
    description: "Tooltip for the Registered Countries field",
    default: "Where the company is registered.",
  },
  onboardingPoliciesTooltipRegisteredAddress: {
    type: "markdown",
    description: "Tooltip for the Registered Address field",
    default: "Address used when registering the company.",
  },
  onboardingPoliciesTooltipFcaNumber: {
    type: "markdown",
    description: "Tooltip for the FCA Number field",
    default: "Company's number for the Financial Services Register.",
  },
  onboardingPoliciesTooltipPrivacyPolicyUrl: {
    type: "markdown",
    description: "Tooltip for the Privacy Policy URL field",
    default: "Explains what you do with user data.",
  },
  onboardingPoliciesTooltipTermsOfBusinessUrl: {
    type: "markdown",
    description: "Tooltip for the Terms of Business URL field",
    default: "Information about your services and costs.",
  },
  onboardingPoliciesTooltipAccessibilityPolicyUrl: {
    type: "markdown",
    description: "Tooltip for the Accessibility Policy URL field",
    default: "Explains how you meet accessibility requirements.",
  },
  onboardingPoliciesTooltipWebDisclaimer: {
    type: "markdown",
    description: "Tooltip for the Web Disclaimer field",
    default: "Appears in email footers and inside your consumer app.",
  },
  onboardingPoliciesTooltipOwnerOccupiedEmailDisclaimer: {
    type: "markdown",
    description: "Tooltip for the Owner Occupied Email Disclaimer field",
    default: "For all owner-occupier mortgage emails.",
  },
  onboardingPoliciesTooltipBuyToLetEmailDisclaimer: {
    type: "markdown",
    description: "Tooltip for the Buy To Let Email Disclaimer field",
    default: "For all owner-occupier mortgage emails.",
  },
  onboardingPoliciesTooltipTwoFactorRequired: {
    type: "markdown",
    description: "Tooltip for the Two Factor Required field",
    default: "Require a Two-Factor Authentication (2FA) for all employees.",
  },
} as const

export type OnboardingPaths = LabelPaths<typeof onboardingBlocks>
