import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { isArray, isString } from "lodash-es"
import invariant from "tiny-invariant"

import localStorageKeys from "../../constants/localStorageKeys"
import { getSessionStorageItem } from "../../lib/localStorageUtil"
import logSentryBreadcrumb from "../../lib/logSentryBreadcrumb"

function isRecord(obj: unknown): obj is Record<string, string[]> {
  if (typeof obj !== "object" || obj === null) return false

  if (isArray(obj)) return false

  if (Object.getOwnPropertySymbols(obj).length > 0) return false
  return Object.values(obj).every((prop) => isArray(prop) && prop.every(isString))
}

export type LearnSessionState = {
  // Record<consumerId, Record<subtopicId, conceptId[]>>
  subtopicsWithProgress: Record<string, Record<string, string[]>>
}

const getLearnSessionFromBrowser = (): LearnSessionState => {
  try {
    const sessionJSON = getSessionStorageItem(localStorageKeys.learnSession)
    const sessionData = sessionJSON ? JSON.parse(sessionJSON) : undefined
    if (!sessionData) {
      return {
        subtopicsWithProgress: {},
      }
    }
    const subtopicsWithProgress = sessionData.subtopicsWithProgress
    const consumers = Object.values(subtopicsWithProgress)
    invariant(consumers.every(isRecord))
    return { subtopicsWithProgress }
  } catch {
    logSentryBreadcrumb({
      level: "error",
      message: "Could not parse learn session from storage",
    })
    return {
      subtopicsWithProgress: {},
    }
  }
}

const learnSession = createSlice({
  name: "learnSession",
  initialState: getLearnSessionFromBrowser(),
  reducers: {
    setConceptRead: (
      state,
      action: PayloadAction<{
        consumerId?: string
        conceptId: string
        subtopicId: string
      }>
    ) => {
      const { conceptId, subtopicId, consumerId } = action.payload

      if (!consumerId) {
        return
      }

      if (!state.subtopicsWithProgress[consumerId]) {
        state.subtopicsWithProgress[consumerId] = {}
      }

      if (!state.subtopicsWithProgress[consumerId][subtopicId]) {
        state.subtopicsWithProgress[consumerId][subtopicId] = [conceptId]
      } else if (
        !state.subtopicsWithProgress[consumerId][subtopicId].includes(conceptId)
      ) {
        state.subtopicsWithProgress[consumerId][subtopicId].push(conceptId)
      }
    },
    setSubtopicsUnread: (
      state,
      action: PayloadAction<{ consumerId?: string; subtopicIds: string[] }>
    ) => {
      const { consumerId, subtopicIds } = action.payload
      if (!consumerId || !state.subtopicsWithProgress[consumerId]) {
        return
      }
      for (const subtopicId of subtopicIds) {
        if (state.subtopicsWithProgress[consumerId][subtopicId]) {
          delete state.subtopicsWithProgress[consumerId][subtopicId]
        }
      }
    },
  },
})

export const { setConceptRead, setSubtopicsUnread } = learnSession.actions

export default learnSession.reducer
