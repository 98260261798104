import { createApi } from "@reduxjs/toolkit/query/react"

import {
  GetSubtopicResponse,
  GetTopicsResponse,
} from "../../components/consumer/LearnTopics/LearnTopics.types"
import axiosBaseQuery from "./axiosBaseQuery"

const TOPICS = "Topics"

export const learnApi = createApi({
  reducerPath: "learnApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  tagTypes: [TOPICS],
  endpoints: (builder) => ({
    getTopics: builder.query<GetTopicsResponse, void>({
      query: () => ({
        url: "/learn/topic/",
      }),
      providesTags: [TOPICS],
    }),
    getSubtopic: builder.query<GetSubtopicResponse, { subtopicId: string }>({
      query: ({ subtopicId }) => ({
        url: `/learn/subtopic/${subtopicId}/`,
        reportContext: {
          prettyURL: "/learn/subtopic/{subtopicId}",
        },
      }),
    }),
    postSubtopicRead: builder.mutation<null, { subtopicId: string }>({
      query: ({ subtopicId }) => ({
        url: `/learn/subtopic/${subtopicId}/read/`,
        method: "POST",
        reportContext: {
          prettyURL: "/learn/subtopic/{subtopicId}/read",
        },
      }),
      invalidatesTags: [TOPICS],
    }),
    postRestartTopic: builder.mutation<null, { topicId: string }>({
      query: ({ topicId }) => ({
        url: `/learn/topic/${topicId}/restart/`,
        method: "POST",
        reportContext: {
          prettyURL: "/learn/topic/{topicId}/restart",
        },
      }),
      invalidatesTags: [TOPICS],
    }),
  }),
})

export const {
  useGetTopicsQuery,
  useGetSubtopicQuery,
  usePostSubtopicReadMutation,
  usePostRestartTopicMutation,
} = learnApi
