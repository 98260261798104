import { createApi } from "@reduxjs/toolkit/dist/query/react"
import axios from "axios"

import HttpStatusCode from "../../../lib/httpStatusCodes"
import reportAxiosError from "../../../lib/reportAxiosError"
import axiosBaseQuery from "../axiosBaseQuery"

export type SamlRequestType = {
  idpLoginUrl: string
  idpLoginBinding:
    | "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect"
    | "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST"
  requestQuery: string
}

export type SsoEndpointResponseType = {
  saml: SamlRequestType | undefined
  error: string | undefined
}

const errorDefaultMessage =
  "Sorry, we could not log you in. Contact your technical support person."

export const singleSignOnApi = createApi({
  reducerPath: "singleSignOnApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  endpoints: (builder) => ({
    getSingleSignOn: builder.query<SsoEndpointResponseType, string | undefined>({
      queryFn: async (firmId) => {
        try {
          const response = await axios<SsoEndpointResponseType>({
            url: "/api-v1/sso/",
            method: "GET",
          })

          return { data: response.data }
        } catch (err: unknown) {
          reportAxiosError(err, { extra: { firmId } })
          if (axios.isAxiosError(err)) {
            const isTooManyReq =
              err?.response?.status === HttpStatusCode.TOO_MANY_REQUESTS
            const tooManyReqMessage =
              err?.response?.data?.detail ||
              "Too many requests. Please try again later."

            return {
              error: {
                message: isTooManyReq ? tooManyReqMessage : errorDefaultMessage,
              },
            }
          }
          return {
            error: {
              message: String(err),
            },
          }
        }
      },
    }),
  }),
})

export const { useLazyGetSingleSignOnQuery } = singleSignOnApi
