import { LabelPaths } from "../../../hooks/blocks/util.types"

export const cookiePolicyBlocks = {
  cookiePolicyPopoverEssentialDropdown: {
    title: {
      type: "text",
      description: "Heading element for cookie policy popover - essential dropdown",
      default: "Essential",
    },
    content: {
      type: "markdown",
      description: "Body element for cookie policy popover - essential dropdown",
      default:
        "We need to use these cookies to make our website work, for example functions such as logging in to your account.",
    },
  },
  cookiePolicyPopoverFunctionalDropdown: {
    title: {
      type: "text",
      description: "Heading element for cookie policy popover - functional dropdown",
      default: "Functional",
    },
    content: {
      type: "markdown",
      description: "Body element for cookie policy popover - functional dropdown",
      default: `Enable us to provide additional functions to you such as
                  personalisation and remembering your saved preferences. Some
                  functionality cookies may also be strictly essential Cookies, but not
                  all necessarily fall into that category.`,
    },
  },
} as const

export type CookiePolicyLabelPaths = LabelPaths<typeof cookiePolicyBlocks>
