import { noop } from "lodash-es"
import { createContext, useState } from "react"

type InfoBoxContextType = [boolean, React.Dispatch<React.SetStateAction<boolean>>]

export const InfoBoxContext = createContext<InfoBoxContextType>([false, noop])

export function InfoBoxProvider({ children }: { children: React.ReactNode }) {
  const boxState = useState(false)
  return <InfoBoxContext.Provider value={boxState}>{children}</InfoBoxContext.Provider>
}
