import ClickableDiv from "../ClickableDiv"
import FloatingTooltip from "../FloatingTooltip"
import { toastNotification } from "../ToastNotification"
import s from "./Highlight.module.scss"

type HighlightProps = {
  path: string
  left: number
  top: number
  width: number
  height: number
}

function Highlight({ path, left, top, width, height }: HighlightProps) {
  return (
    <div
      style={{
        position: "fixed",
        left: left - 1,
        top: top - 1,
        width: width + 2,
        height: height + 2,
        border: "2px dashed firebrick",
        borderRadius: "3px",
        pointerEvents: "all",
      }}
    >
      <FloatingTooltip
        label={
          <div className={s.tooltip} role="tooltip">
            {path}
          </div>
        }
      >
        <ClickableDiv
          className={s.clickArea}
          onClick={async () => {
            try {
              await navigator.clipboard.writeText(path)
              toastNotification(`Copied "${path}" to clipboard`, "info")
            } catch (e) {
              toastNotification(
                "Unable to copy to clipboard, if the App is running locally then it's likely due to being on HTTP instead of HTTPS",
                "error"
              )
            }
          }}
        >
          &nbsp;
        </ClickableDiv>
      </FloatingTooltip>
    </div>
  )
}

export { Highlight }
