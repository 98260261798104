import { LabelPaths } from "../../../hooks/blocks/util.types"

export const withoutAdvicePageBlocks = {
  lender: {
    withoutAdvice: {
      headerTitle: {
        type: "text",
        description: "Heading element for the /without-advice/ page",
        default: "Account Number",
      },
      heroImage: {
        type: "image",
        description:
          "Hero image for the /without-advice/ page. Fallback icon is a tick within a circle.",
        default: null,
      },
      introContent: {
        type: "markdown",
        description:
          "Copy displayed on the /without-advice/ page as the intro content.",
        default: null,
      },
      urlButton: {
        labelContent: {
          type: "text",
          description: "URL button label for  /without-advice/ page ",
          default: "Switch now",
        },
      },
      codeHeaderTitle: {
        type: "text",
        description: "Code Heading element for the /without-advice/ page",
        default: null,
      },
      codeHeaderMdxContent: {
        type: "mdx",
        description: "Code Heading MDX content for the /without-advice/ page",
        default: null,
      },
      direct: {
        headerTitle: {
          type: "text",
          description:
            "Heading element for the /portal/link/without-advice/:propertyId page",
          default: "Click below to access our online portal:",
        },
      },
    },
    switch: {
      footerImage: {
        lowRes: {
          image: {
            type: "image",
            description: "Footer image low res for the without advice page",
            default: null,
          },
        },
        highRes: {
          image: {
            type: "image",
            description: "Footer image high res for the without advice page",
            default: null,
          },
        },
      },
    },
  },
  switch: {
    accountNumber: {
      infoBoxMdxContent: {
        type: "mdx",
        description: "Content displayed in the Lender without advice page info box",
        default: null,
      },
    },
  },
} as const

export type WithoutAdviceLabelPaths = LabelPaths<typeof withoutAdvicePageBlocks>
