import React, { useState } from "react"

import AnimateHeight from "../../../../components/common/AnimateHeight"
import ClickableDiv from "../../../../components/common/ClickableDiv"
import buttonTrackingIds, {
  ButtonTrackingId,
} from "../../../../constants/buttonTrackingIds"
import Chevron from "../../../../images/Chevron.svg?react"
import { HeadingLevel } from "../../../../types/retain"
import useTrackClick from "../../../trackClick"
import s from "./Accordion.module.scss"

type Props = {
  title: string
  startOpen?: boolean
  trackingId?: ButtonTrackingId
  headingLevel?: HeadingLevel
  children: React.ReactNode
}

const Accordion = ({
  title,
  startOpen = false,
  children,
  trackingId,
  headingLevel = HeadingLevel.H4,
}: Props) => {
  const [open, setOpen] = useState(startOpen)
  const { trackClick } = useTrackClick()

  const Heading = headingLevel

  const toggleOpen = () => {
    trackClick(trackingId || buttonTrackingIds.ACCORDION, open ? "closed" : "open")
    setOpen(!open)
  }

  return (
    <>
      <ClickableDiv onClick={toggleOpen} className={s.accordion}>
        <Heading className={s.accordionHeading}>{title}</Heading>
        <Chevron className={open ? s.chevronOpen : s.chevron} />
      </ClickableDiv>
      <AnimateHeight in={open}>{children}</AnimateHeight>
    </>
  )
}

export default Accordion
