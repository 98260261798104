import {
  useFloating,
  useInteractions,
  useClick,
  useRole,
  useDismiss,
  FloatingPortal,
  FloatingOverlay,
  FloatingFocusManager,
} from "@floating-ui/react-dom-interactions"
import cx from "classnames"
import { ReactNode, useEffect, useRef } from "react"

import Close from "../../../../images/Close.svg?react"
import { ButtonLink } from "../../../common"
import ClickableDiv from "../../../common/ClickableDiv/ClickableDiv"
import s from "./Modal.module.scss"

type ModalProps = {
  children: ReactNode
  open: boolean
  handleClose: () => void
  preventEscClose?: boolean
  modalClass?: string
  noFade?: boolean
  hideClose?: boolean
}

export default function Modal({
  children,
  open,
  handleClose,
  preventEscClose,
  modalClass,
  noFade,
  hideClose = false,
}: ModalProps) {
  const { floating, context } = useFloating({ open })
  const contentRef = useRef<EventTarget | null>(null)

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      contentRef.current = e.target
    }

    window.addEventListener("mousedown", handleMouseDown)

    return () => {
      window.removeEventListener("mousedown", handleMouseDown)
    }
  }, [])

  const { getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context, {
      escapeKey: !preventEscClose,
    }),
  ])

  return (
    <FloatingPortal>
      {open && (
        <>
          {/* @ts-expect-error FloatingOverlay is expected to handle this correctly */}
          <FloatingOverlay
            onClick={(e) => {
              if (contentRef.current && contentRef.current === e.target) {
                handleClose()
              }
            }}
            className={cx(s.overlay, { [s.noFade]: noFade })}
            lockScroll
          >
            <FloatingFocusManager context={context}>
              <div
                {...getFloatingProps({
                  ref: floating,
                  className: cx(s.modal, modalClass),
                })}
              >
                {!hideClose && (
                  <ButtonLink
                    label="Close modal"
                    className={s.closeButton}
                    onClick={(e) => {
                      e?.stopPropagation()
                      handleClose()
                    }}
                  >
                    <span>Close</span> <Close className={s.closeIcon} />
                  </ButtonLink>
                )}

                <ClickableDiv
                  className={s.content}
                  onClick={(e) => {
                    e?.stopPropagation()
                  }}
                >
                  {children}
                </ClickableDiv>
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        </>
      )}
    </FloatingPortal>
  )
}
