import Unsure from "../../../images/Unsure.svg?react"
import useDefaultButtonStyle from "../../../lib/useDefaultButtonStyle"
import Button from "../Button"
import s from "./MortgageOverview.module.scss"

const randomWidths = Array.from(
  { length: 4 },
  () => Math.floor(Math.random() * 50) + 30
)

const PlaceHolder = ({ index }: { index: number }) => {
  return (
    <div
      className={s.placeholder}
      style={{
        width: `${randomWidths[index]}%`,
      }}
    />
  )
}

type Props = {
  pronoun: string
  fakeTimeout: () => void
  loading: boolean
  disabled?: boolean
}

const CalculatePayments = ({ pronoun, fakeTimeout, loading, disabled }: Props) => {
  const defaultButtonStyle = useDefaultButtonStyle()

  return (
    <>
      <h3 className={s.title}>
        <Unsure className={s.titleIcon} />
        {`${pronoun} remaining term`}
      </h3>
      <div className={s.calculatePaymentTable}>
        <table>
          <thead>
            <tr>
              <th>
                <PlaceHolder index={0} />
              </th>
              <th>
                <PlaceHolder index={1} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className={s.followOnRate}>
                  <PlaceHolder index={2} />
                </span>
              </td>
              <td>
                <span className={s.followOnRate}>
                  <PlaceHolder index={3} />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={s.calculatePayments}>
        <Button
          loading={loading}
          style={defaultButtonStyle}
          fullWidth
          onClick={fakeTimeout}
          disabled={disabled}
        >
          Calculate payment difference
        </Button>
      </div>
    </>
  )
}

export default CalculatePayments
