export const optionsInformedConsumersBlocks = {
  optionsCard: {
    withoutAdvice: {
      heading: {
        type: "text",
        description:
          "The heading for the Without Advice card on Your Options (Informed Consumers Exp 1)",
        default: "Start Switch",
      },
      body: {
        type: "markdown",
        description:
          "The body for the Without Advice card on Your Options (Informed Consumers Exp 1)",
        default:
          "Click ‘Start switch’ if you’re happy to select a mortgage without advice or help.",
      },
    },
    internalAdvice: {
      heading: {
        type: "text",
        description:
          "The heading for the Internal Advice card on Your Options (Informed Consumers Exp 1)",
        default: "Speak to an Advisor",
      },
      body: {
        type: "markdown",
        description:
          "The body for the Internal Advice card on Your Options (Informed Consumers Exp 1)",
        default:
          "Connect with one of our mortgage experts to walk you through our currently available rates",
      },
    },
    externalAdvice: {
      heading: {
        type: "text",
        description:
          "The heading for the External Advice card on Your Options (Informed Consumers Exp 1)",
        default: "Find a Broker",
      },
      body: {
        type: "markdown",
        description:
          "The body for the External Advice card on Your Options (Informed Consumers Exp 1)",
        default:
          "If you need to speak to someone outside the organisation to get help with your mortgage, select this option",
      },
    },
    withSwitchSupport: {
      heading: {
        type: "text",
        description:
          "The heading for the With Switch Support card on Your Options (Informed Consumers Exp 1)",
        default: "Get help",
      },
      body: {
        type: "markdown",
        description:
          "The body for the With Switch Support card on Your Options (Informed Consumers Exp 1)",
        default:
          "Select this option if you would like to talk about your current mortgage or if you’re considering making any other changes, such as borrowing more or changing term.",
      },
    },
  },
} as const
