import { LabelPaths, ReplacesList } from "../../../../hooks/blocks/util.types"

export const productSelectionInfoPageBlocks = {
  lender: {
    selection: {
      productDetails: {
        reversionRateContent: {
          type: "text",
          description: "Reversion rate content",
          default: "Reversion rate",
        },
        followOnPaymentContent: {
          type: "text",
          description: "Follow on payment content",
          default: "Follow-on payment",
        },
        label: {
          collapsedContent: {
            type: "text",
            description: "Collapsed label content",
            default: "See details",
          },
          expandedContent: {
            type: "text",
            description: "Expanded label content",
            default: "Hide details",
          },
        },
      },
      productInfoModals: {
        discount: {
          body: {
            type: "mdx",
            description:
              "MDX copy for the generic discount product info modal. If not present then modal icon does not show. Available props are {props.preferentialRate}, {props.discountRate}, {props.baseRateDifference} {props.expiryDate} & {props.discountPercentage}.",
            default: null,
          },
        },
        fixed: {
          body: {
            type: "mdx",
            description:
              "MDX copy for the generic fixed product info modal. If not present then modal icon does not show. Available props are {props.preferentialRate}, {props.discountRate}, {props.baseRateDifference} {props.expiryDate} & {props.discountPercentage}.",
            default: null,
          },
        },
        tracker: {
          body: {
            type: "mdx",
            description:
              "MDX copy for the generic tracker product info modal. If not present then modal icon does not show. Available props are {props.preferentialRate}, {props.discountRate}, {props.baseRateDifference} {props.expiryDate} & {props.discountPercentage}.",
            default: null,
          },
        },
        twoYearDiscount: {
          label: {
            type: "text",
            description: "Label for launching the 2 year discount product info modal",
            default: null,
          },
          title: {
            type: "text",
            description: "Title of the 2 year discount product info modal",
            default: "2 year discount",
          },
          body: {
            type: "mdx",
            description:
              "MDX copy for the 2 year discount product info modal. If not present then modal icon does not show. Available props are {props.preferentialRate}, {props.discountRate}, {props.baseRateDifference} {props.expiryDate} & {props.discountPercentage}.",
            default: null,
          },
        },
        twoYearFixed: {
          label: {
            type: "text",
            description: "Label for launching the 2 year fixed product info modal",
            default: null,
          },
          title: {
            type: "text",
            description: "Title of the 2 year fixed product info modal",
            default: "2 year fixed",
          },
          body: {
            type: "mdx",
            description:
              "MDX copy for the 2 year fixed product info modal. If not present then modal icon does not show. Available props are {props.preferentialRate}, {props.discountRate}, {props.baseRateDifference} {props.expiryDate} & {props.discountPercentage}.",
            default: null,
          },
        },
        twoYearTracker: {
          label: {
            type: "text",
            description: "Label for launching the 2 year tracker product info modal",
            default: null,
          },
          title: {
            type: "text",
            description: "Title of the 2 year tracker product info modal",
            default: "2 year tracker",
          },
          body: {
            type: "mdx",
            description:
              "MDX copy for the 2 year tracker product info modal. If not present then modal icon does not show. Available props are {props.preferentialRate}, {props.discountRate}, {props.baseRateDifference} {props.expiryDate} & {props.discountPercentage}.",
            default: null,
          },
        },
      },
    },
  },
} as const

export type ProductSelectionInfoLabelPaths = LabelPaths<
  typeof productSelectionInfoPageBlocks
>

export const replacesProductSelectionInfoBlocks: ReplacesList = [
  {
    src: "lender.selection.productDetails.tempDescriptionMdx",
    dest: "lender.selection.productInfoModals.twoYearDiscount.body",
  },
]
