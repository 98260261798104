import { LabelPaths } from "../../../../hooks/blocks/util.types"

export const productSelectionSwitchPageBlocks = {
  lender: {
    selection: {
      switch: {
        title: {
          type: "text",
          description: "Heading element for the /product-selection/switch-date/ page",
          default: "Choose a switch date",
        },
        description: {
          type: "mdx",
          description:
            "Description for the switch page, use {props.baseRateCode} to display the Base Rate Code set on the Mortgage in ESA",
          default: `Our new deals start start on the 1st of the month. If you switch early, you will avoid being charged any days on the {props.baseRateCode}.`,
        },
        dealEnds: {
          type: "mdx",
          description: `Bold text that says when your deal ends, use {props.expiryDate} to display the expiry date in this format: 21 October 2015. You can use <IfExpired yes="text to display when expired" no="text to display when not expired" /> for example: "Your current deal <IfExpired yes="ended" no="ends" /> on {props.expiryDate}" could be "Your current deal ended on 21 October 2015" if it expired, and "Your current deal ends on 21 October 2077" if it hasn't expired yet.`,
          default: `Your current deal <IfExpired yes="ended" no="ends" /> on {props.expiryDate}`,
        },
        beforeDeal: {
          title: {
            type: "text",
            description: "Heading element for the before deal section",
            default: "Before your current deal ends",
          },
        },
        afterDeal: {
          title: {
            type: "text",
            description: "Heading element for the after deal section",
            default: "After your current deal ends",
          },
          description: {
            type: "mdx",
            description:
              "Description for the after deal section, use {props.baseRateCode} to display the Base Rate Code set on the Mortgage in ESA",
            default: `Switching after your current deal ends might mean you may be on the {props.baseRateCode} for a number of days before your new deal starts.`,
          },
          modalLink: {
            type: "text",
            description: "Link text that opens up the Here's Why modal",
            default: "Here's why",
          },
          modalTitle: {
            type: "mdx",
            description:
              "Title for the Here's Why modal, use {props.baseRateCode} to display the Base Rate Code set on the Mortgage in ESA",
            default: "What's the {props.baseRateCode} period?",
          },
          modalText: {
            type: "mdx",
            description:
              "Text for the Here's Why modal, use {props.baseRateCode} to display the Base Rate Code set on the Mortgage in ESA",
            default:
              "When your current deal ends, there is a transition period before your new product takes effect. During this period, you will be on the {props.baseRateCode}.",
          },
        },
        option: {
          before: {
            type: "mdx",
            description:
              "Text for the option to choose a switch date before the current deal ends. {props.dayWord} will be 'day' if props.dayCount is 1, otherwise 'days' (e.g. 'Switch to a new deal 5 days early' / 'Switch to a new deal 1 day early')",
            default: `Switch to a new deal {props.dayCount} {props.dayWord} early`,
          },
          immediate: {
            type: "mdx",
            description:
              "Text for the option to choose a switch date immediately after the current deal ends, use {props.baseRateCode} to display the Base Rate Code set on the Mortgage in ESA",
            default:
              "You'll switch immediately after your current deal ends, avoiding any days on our {props.baseRateCode}.",
          },
          after: {
            type: "mdx",
            description: `Text for the option to choose a switch date (at least 3 days) after the current deal ends. Here's an example of how the props will look if the value is: "You’ll be on the {props.baseRateCode} of {props.rate}% from **{props.rateStartDate} - {props.rateEndDate}**" - "You'll be on the AMR of 2.34% from **October 21 - October 31**"`,
            default:
              "You’ll be on the {props.baseRateCode} of {props.rate}% from **{props.rateStartDate} - {props.rateEndDate}**",
          },
          oneDayRate: {
            type: "mdx",
            description: `Text for the option to choose a switch date 2 days after the current deal ends, meaning the user will be on the reversion rate for 1 day. Here's an example of how the props will look if the value is: "You’ll be on the {props.baseRateCode} of {props.rate}% on **{props.rateStartDate}**" - "You'll be on the AMR of 2.34% on **October 21**"`,
            default:
              "You’ll be on the {props.baseRateCode} of {props.rate}% on **{props.rateStartDate}**",
          },
        },
      },
    },
  },
}

export type ProductSelectionSwitchLabelPaths = LabelPaths<
  typeof productSelectionSwitchPageBlocks
>
