export const propertyValuePageBlocks = {
  propertyValueModuleTitle: {
    type: "text",
    description:
      "Label that displays inside the Property Valuation Info Card on the Lifecycle Home page, and the top of modal that it opens",
    default: "Property Valuation",
  },
  propertyValueModuleAlertCard: {
    type: "text",
    description:
      "The label inside the clickable Property Valuation alerts cards at /property/:propertyId",
    default: "Property valuation",
  },
  propertyValueModulePageHeading: {
    type: "text",
    description:
      "Heading element for the Property Valuation page at /property-value/:propertyId",
    default: "Property valuation",
  },
  propertyValueBasedOn: {
    type: "markdown",
    description: `The text that is revealed after expanding the "Based on" section by clicking the + icon`,
    default:
      "The current property value shown is an estimate based on the original property we have on file. It is not a full market valuation of your property.",
  },
  propertyValueModalContentPage1: {
    title: {
      DEPRECATED_id: "propertyValueModalContentPage1",

      type: "text",
      description: "The title of the first page of the Property Value info modal",
      default: "Property valuation explained",
    },
    content: {
      DEPRECATED_id: "propertyValueModalContentPage1",

      type: "markdown",
      description: "The content of the first page of the Property Value info modal",
      default: null, // default is different based on the mortgage - see the description
    },
  },
  propertyValueModalContentPage2: {
    title: {
      DEPRECATED_id: "propertyValueModalContentPage2",

      type: "text",
      description: "The title of the second page of the Property Value info modal",
      default: "How does this impact my mortgage?",
    },
    content: {
      DEPRECATED_id: "propertyValueModalContentPage2",

      type: "markdown",
      description: `The content of the second page of the Property Value info modal. If there is no mortgage data the default will be: "Your original property value is the value of your property at the start of your mortgage. Your current property value is estimated by applying the UK House Price Index (HPI) for your borough to your original property value.". If there is mortgage data it will be: "Your original property value is the value of your property at the start of your {LENDER_NAME} mortgage on {START_DATE}. Your current property value is estimated by applying the UK House Price Index (HPI) for your borough to your original property value."`,
      default:
        "Changing property values can affect your loan-to-value (LTV) ratio. This is the % of the property you own or have a deposit for, compared to the mortgage amount. Your LTV is the amount of your mortgage divided by your property value, expressed as a percentage.",
    },
  },
  propertyValueModalContentPage3: {
    title: {
      DEPRECATED_id: "propertyValueModalContentPage3",

      type: "text",
      description: "The title of the third page of the Property Value info modal",
      default: "Why do we track it?",
    },
    content: {
      DEPRECATED_id: "propertyValueModalContentPage3",

      type: "markdown",
      description: "The content of the third page of the Property Value info modal",
      default:
        "If a property increases in value and/or your mortgage balance decreases, your LTV can reduce.\n" +
        "A lower LTV, may mean that you are able to get a lower interest rate or borrow more money against your equity.",
    },
  },
  loanToValueModuleTitle: {
    type: "text",
    description: `Label that displays at the top of the "Loan-to-value explained" modal that opens when clicking "Learn more about LTV" on the Property Valuation page`,
    default: "Loan-to-Value",
  },
  loanToValueAlertCard: {
    type: "markdown",
    description:
      "The text inside the alert card in the LTV section of the Property Valuation page",
    default:
      "It looks like you’ve moved to a lower LTV band. Lower rates may be available as a result.",
  },
  loanToValueModalContentPage1: {
    title: {
      DEPRECATED_id: "loanToValueModalContentPage1",

      type: "text",
      description: "The title of the first page of the LTV info modal",
      default: "Loan-to-value explained",
    },
    content: {
      DEPRECATED_id: "loanToValueModalContentPage1",

      type: "markdown",
      description: "The content of the first page of the LTV info modal",
      default:
        "Changing property values can affect your loan-to-value (LTV) ratio. This is the % of the property you own or have a deposit for, compared to the mortgage amount.\n" +
        "\n" +
        "If a property increases in value and/or your mortgage balance decreases, your LTV can reduce during your mortgage term. Your LTV can also increase if your property decreases in value and/or if you increase the size of your mortgage amount.",
    },
  },
  loanToValueModalContentPage2: {
    title: {
      DEPRECATED_id: "loanToValueModalContentPage2",

      type: "text",
      description: "The title of the second page of the LTV info modal",
      default: "How does this impact my mortgage?",
    },
    content: {
      DEPRECATED_id: "loanToValueModalContentPage2",

      type: "markdown",
      description: "The content of the second page of the LTV info modal",
      default:
        "To calculate your LTV ratio, divide the mortgage amount by the current property value. Then multiply by 100 to get the percentage. The LTV changes as you repay your mortgage, or when your property value increases or decreases.",
    },
  },
  loanToValueModalContentPage3: {
    title: {
      DEPRECATED_id: "loanToValueModalContentPage3",

      type: "text",
      description: "The title of the third page of the LTV info modal",
      default: "Why do we track it?",
    },
    content: {
      DEPRECATED_id: "loanToValueModalContentPage3",

      type: "markdown",
      description: "The content of the third page of the LTV info modal",
      default:
        "There are LTV bands, typically 90%, 80%, 75%, 70% and 60%. You may be able to get a lower rate if you move to a lower LTV band.",
    },
  },
} as const
