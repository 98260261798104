import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit"

import localStorageKeys from "../../constants/localStorageKeys"
import { setSessionStorageItem } from "../../lib/localStorageUtil"
import type { AppStartListening } from "../store"
import { showPropertyGraph } from "./ui"

export const uiListener = createListenerMiddleware()
// It's impossible for .getState() typing to be known ahead of time so we cast here.
// It's the recommended approach. https://redux-toolkit.js.org/api/createListenerMiddleware#typescript-usage
const startListening = uiListener.startListening as AppStartListening

startListening({
  matcher: isAnyOf(showPropertyGraph),
  effect: (_, listenerApi) => {
    const graphDateTime = listenerApi.getState().ui.isPropertyGraphShown
    if (graphDateTime) {
      setSessionStorageItem(localStorageKeys.propertyValueGraphBeenShown, graphDateTime)
    }
  },
})
