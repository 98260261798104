import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useListItem,
  useTypeahead,
  FloatingList,
  FloatingPortal,
  size,
} from "@floating-ui/react"
import cx from "classnames"
import { noop } from "lodash-es"
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react"

import Chevron from "../../../images/Chevron.svg?react"
import s from "./Select.module.scss"

interface SelectContextValue {
  activeIndex: number | null
  selectedIndex: number | null
  getItemProps: ReturnType<typeof useInteractions>["getItemProps"]
  handleSelect: (index: number | null, overrideOnClick?: () => unknown) => void
  valuesRef: React.MutableRefObject<string[]>
}

const SelectContext = createContext<SelectContextValue>({} as SelectContextValue)

export function Option({
  children: label,
  value,
  className,
  overrideOnClick,
}: {
  children: ReactNode
  value: string
  className?: string
  overrideOnClick?: () => unknown
}) {
  const { activeIndex, selectedIndex, getItemProps, handleSelect, valuesRef } =
    useContext(SelectContext)

  const { ref, index } = useListItem({ label: value })
  valuesRef.current[index] = value

  const isActive = activeIndex === index
  const isSelected = selectedIndex === index

  return (
    <button
      className={cx(s.Option, className)}
      ref={ref}
      role="option"
      aria-selected={isActive && isSelected}
      tabIndex={isActive ? 0 : -1}
      {...getItemProps({
        onClick: () => handleSelect(index, overrideOnClick),
      })}
    >
      {label}
    </button>
  )
}

type SelectProps = {
  name?: string
  onChange?: (value: string) => void
  label: ReactNode
  value: string
  children: ReactNode
  labelledBy?: string
  describedBy?: string
  disabled?: boolean
  dropdownClassName?: string
  buttonClassName?: string
}

export function Select({
  name,
  children,
  value,
  label,
  onChange = noop,
  disabled = false,
  labelledBy,
  describedBy,
  dropdownClassName,
  buttonClassName,
}: SelectProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const [selectedValue, setSelectedValue] = useState<string>(value)

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-start",
    open: isOpen,
    onOpenChange: (value) => !disabled && setIsOpen(value),
    whileElementsMounted: autoUpdate,
    middleware: [
      flip(),
      size({
        apply({ elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${elements.reference.getBoundingClientRect().width}px`,
            maxHeight: `${availableHeight}px`,
          })
        },
      }),
    ],
  })

  const elementsRef = useRef<Array<HTMLElement | null>>([])
  const labelsRef = useRef<Array<string | null>>([])
  const valuesRef = useRef<Array<string>>([])

  const handleSelect = useCallback(
    (index: number | null, overrideOnClick?: () => unknown) => {
      setSelectedIndex(index)
      setIsOpen(false)
      if (index !== null) {
        const value = valuesRef.current[index]
        if (overrideOnClick) {
          overrideOnClick()
        } else if (value !== undefined) {
          setSelectedValue(value)
          onChange(value)
        }
      }
    },
    [onChange]
  )

  function handleTypeaheadMatch(index: number | null) {
    if (isOpen) {
      setActiveIndex(index)
    } else {
      handleSelect(index)
    }
  }

  const listNav = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
  })
  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    activeIndex,
    selectedIndex,
    onMatch: handleTypeaheadMatch,
  })
  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: "listbox" })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    listNav,
    typeahead,
    click,
    dismiss,
    role,
  ])

  const selectContext = useMemo(
    () => ({
      activeIndex,
      selectedIndex,
      getItemProps,
      handleSelect,
      valuesRef,
    }),
    [activeIndex, selectedIndex, getItemProps, handleSelect]
  )

  return (
    <>
      <button
        ref={refs.setReference}
        className={cx(s.Button, buttonClassName)}
        tabIndex={0}
        aria-describedby={describedBy}
        aria-labelledby={labelledBy}
        {...getReferenceProps()}
      >
        {label}
        <Chevron className={cx({ [s.chevronOpen]: isOpen })} />
      </button>
      <input type="hidden" name={name} value={selectedValue} />
      <SelectContext.Provider value={selectContext}>
        {isOpen && (
          <FloatingPortal>
            <FloatingFocusManager context={context} modal={false}>
              <div
                ref={refs.setFloating}
                className={cx(s.Dropdown, dropdownClassName)}
                style={{ ...floatingStyles, overflowY: "auto" }}
                {...getFloatingProps()}
              >
                <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                  {children}
                </FloatingList>
              </div>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
      </SelectContext.Provider>
    </>
  )
}
