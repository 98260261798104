import { useState } from "react"
import ReactMarkdown from "react-markdown"

import InfoBox from "../../../components/common/InfoBox"
import s from "../../../components/common/InfoBox/InfoBox.module.scss"
import { ButtonTrackingId } from "../../../constants/buttonTrackingIds"

export interface BoxProps {
  title: string
  body: string
  onCloseTrackingId: ButtonTrackingId
}

export default function WrappedInfoBox({ title, body, onCloseTrackingId }: BoxProps) {
  const [isVisible, setVisible] = useState(true)
  return isVisible ? (
    <InfoBox
      title={<h4 className={s.title}>{title}</h4>}
      body={<ReactMarkdown>{body}</ReactMarkdown>}
      hide={() => {
        setVisible(false)
      }}
      onCloseTrackingId={onCloseTrackingId}
    />
  ) : null
}
