import { createApi } from "@reduxjs/toolkit/query/react"
import { isArray } from "lodash-es"

import { PRETTY_MORTGAGE_ID, PRETTY_PROPERTY_ID } from "../../../lib/reportAxiosError"
import axiosBaseQuery from "../axiosBaseQuery"
import { parseProduct } from "./parseProduct"
import {
  ProductSelectionMutation,
  ProductSelectionOptions,
  ProductSelectionResponse,
  ProductSelectionSubmission,
  ProductSelectionSwitchDatesResponse,
} from "./ProductSelection.types"

export const productSelectionApi = createApi({
  reducerPath: "productSelectionApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1/product-selection",
  }),
  endpoints: (builder) => ({
    getProductSelection: builder.query<ProductSelectionOptions, { propertyId: string }>(
      {
        query: ({ propertyId }) => ({
          url: `/${propertyId}/`,
          reportContext: {
            prettyURL: `/api-v1/product-selection/${PRETTY_PROPERTY_ID}`,
            ignoreStatuses: [401],
          },
        }),
        transformResponse: (response: ProductSelectionResponse) => ({
          ...response,
          mortgages: response.mortgages.map(
            ({
              id,
              products,
              monthlyFollowOnPayment,
              monthlyPreferentialPayment,
              followOnRate,
              followOnStartDate,
              expiryDate,
              givenCurrentLoanToValue,
              givenCurrentBalance,
              numberOfMortgages,
              baseRate,
            }) => ({
              id,
              expiryDate,
              followOnRate: parseFloat(followOnRate),
              followOnStartDate,
              monthlyFollowOnPayment: parseFloat(monthlyFollowOnPayment),
              monthlyPreferentialPayment: parseFloat(monthlyPreferentialPayment),
              givenCurrentLoanToValue: parseFloat(givenCurrentLoanToValue || "0"),
              givenCurrentBalance: parseFloat(givenCurrentBalance || "0"),
              numberOfMortgages,
              baseRate,
              products: isArray(products)
                ? {
                    feesUpfront: parseProduct(products),
                    feesAmortised: parseProduct(products),
                  }
                : {
                    feesUpfront: parseProduct(products.feesUpfront),
                    feesAmortised: parseProduct(products.feesAmortised),
                  },
            })
          ),
        }),
      }
    ),
    getSwitchDateChoices: builder.query<
      ProductSelectionSwitchDatesResponse,
      { propertyId: string; mortgageId: string }
    >({
      query: ({ propertyId, mortgageId }) => ({
        url: `/${propertyId}/products-switch-dates`,
        params: {
          mortgage_id: mortgageId,
        },
        reportContext: {
          prettyURL: `/api-v1/product-selection/${PRETTY_PROPERTY_ID}/products-switch-dates/?mortgage_id=${PRETTY_MORTGAGE_ID}`,
          ignoreStatuses: [401],
        },
      }),
    }),
    postProductsTermsAccepted: builder.mutation<
      void,
      {
        propertyId: string
      }
    >({
      query: ({ propertyId }) => ({
        url: `/${propertyId}/products-terms-accepted/`,
        method: "POST",
        reportContext: {
          prettyURL: `/api-v1/product-selection/${PRETTY_PROPERTY_ID}/products-terms-accepted/`,
          ignoreStatuses: [401],
        },
      }),
    }),

    postProductsSelected: builder.mutation<
      void,
      {
        propertyId: string
        mortgages: {
          id: string
          products: {
            id: string
          }[]
        }[]
        feesPreference: "fees-amortised" | "fees-upfront"
      }
    >({
      query: ({ propertyId, mortgages, feesPreference }) => ({
        url: `/${propertyId}/products-selected/`,
        method: "POST",
        requestData: {
          mortgages,
          productSelectionFeePaymentPreference: feesPreference,
        },
        reportContext: {
          prettyURL: `/api-v1/product-selection/${PRETTY_PROPERTY_ID}/products-selected/`,
          ignoreStatuses: [401],
        },
      }),
    }),

    postProductsConfirmed: builder.mutation<
      ProductSelectionMutation,
      {
        propertyId: string
        productConfirmationSubmission: ProductSelectionSubmission
      }
    >({
      query: ({ propertyId, productConfirmationSubmission }) => ({
        url: `/${propertyId}/products-confirmed/`,
        method: "POST",
        requestData: productConfirmationSubmission,
        reportContext: {
          prettyURL: `/api-v1/product-selection/${PRETTY_PROPERTY_ID}/products-confirmed/`,
          ignoreStatuses: [401],
        },
      }),
    }),
  }),
})

export const {
  useGetProductSelectionQuery,
  useGetSwitchDateChoicesQuery,
  usePostProductsTermsAcceptedMutation,
  usePostProductsSelectedMutation,
  usePostProductsConfirmedMutation,
} = productSelectionApi
