type Config<WP extends {}, SP extends WP> = {
  predicate: (props: SP) => boolean
  dummyProps?: Partial<WP> | ((props: SP) => Partial<WP>)
}

const isFunction = (func: unknown): func is Function => typeof func === "function"

function skeleton<
  WrappedComponentProps extends {},
  SkeletonProps extends WrappedComponentProps = WrappedComponentProps
>({ predicate, dummyProps }: Config<WrappedComponentProps, SkeletonProps>) {
  return (WrappedComponent: React.ComponentType<React.PropsWithChildren<WrappedComponentProps>>) => {
    return function skeletonInner(props: SkeletonProps) {
      const passDownProps = isFunction(dummyProps)
        ? () => dummyProps(props)
        : () => dummyProps
      return <WrappedComponent {...props} {...(predicate(props) && passDownProps())} />
    }
  };
}

export default skeleton
