import { Mortgage, Property } from "../types/retain/Mortgages.types"

/**
 * The mortgages we get from the API are guaranteed to be in the priority order
 * we wish to display them to the user i.e. closest to expiry first. This should
 * also be the same for the properties we build from the mortgages data.
 */
export const convertMortgagesIntoPropertiesList = (mortgages: Mortgage[]) => {
  return mortgages.reduce<Property[]>((arr, mortgage) => {
    const {
      prop,
      prop: { id },
    } = mortgage
    const propertyIndex = arr.findIndex((property) => property.id === id)
    const property =
      propertyIndex > -1 ? arr[propertyIndex] : { mortgages: [], ...prop }
    property.mortgages.push(mortgage)
    if (propertyIndex < 0) {
      arr.push(property)
    }
    return arr
  }, [])
}
