import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import dayjs from "dayjs"

import { userPermissionModalConstants } from "../../components/business/Onboarding/ManageUserPermissions/constant"
import { StepsIdentification } from "../../components/business/Onboarding/Steps/StepsIdentification"
import localStorageKeys from "../../constants/localStorageKeys"
import { getLocalStorageItem } from "../../lib/localStorageUtil"
import { EmployeeUser } from "../../types/retain/Advisor.types"
import { PermissionGroup } from "../../types/retain/Permission.types"

export interface OnboardingState {
  introArticleHasBeenShown: string | null
  completedModalHasBeenShown: string | null
  showWelcomeLaModal: boolean
  firstUploadModalShow: boolean
  completedModalShow: boolean
  userPermissions: {
    activeModal: userPermissionModalConstants | null
    chosenPermission: PermissionGroup | null
    chosenEmployee: EmployeeUser | null
    isUpdating: boolean
    shouldAutoScroll: boolean
    employeesWithUpdateError: EmployeeUser["id"][]
  }
  expandedItem: StepsIdentification | null
  visitedLoadApproval: string | null
  userPermissionsWelcomeModalBeenShown: string | null
}

export const initialState: OnboardingState = {
  introArticleHasBeenShown: getLocalStorageItem(
    localStorageKeys.onboardingWelcomeModalBeenShown
  ),
  completedModalHasBeenShown: getLocalStorageItem(
    localStorageKeys.onboardingCompletedModalBeenShown
  ),
  userPermissionsWelcomeModalBeenShown: getLocalStorageItem(
    localStorageKeys.userPermissionsWelcomeModalBeenShown
  ),
  showWelcomeLaModal: false,
  firstUploadModalShow: false,
  userPermissions: {
    activeModal: null,
    chosenPermission: null,
    chosenEmployee: null,
    isUpdating: false,
    shouldAutoScroll: false,
    employeesWithUpdateError: [],
  },
  expandedItem: null,
  completedModalShow: false,
  visitedLoadApproval: getLocalStorageItem(localStorageKeys.isVisitedLA),
}

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setExpandedItem(state, action: PayloadAction<StepsIdentification | null>) {
      state.expandedItem = action.payload
    },
    showCompletedModal(state) {
      state.completedModalHasBeenShown = dayjs().toISOString()
      state.completedModalShow = true
    },
    hideCompletedModal(state) {
      state.completedModalShow = false
    },
    showFirstUploadModal(state) {
      state.firstUploadModalShow = true
    },
    hideFirstUploadModal(state) {
      state.firstUploadModalShow = false
    },
    hideWelcomeLaModal(state) {
      state.showWelcomeLaModal = false
    },
    setActiveUserPermissionModal(
      state,
      action: PayloadAction<userPermissionModalConstants | null>
    ) {
      state.userPermissions.activeModal = action.payload
    },
    setIntroArticleHasBeenShown(state) {
      state.introArticleHasBeenShown = dayjs().toISOString()
    },
    setChosenPermission(state, action: PayloadAction<PermissionGroup | null>) {
      state.userPermissions.chosenPermission = action.payload
    },
    setChosenEmployee(
      state,
      action: PayloadAction<{ employee: EmployeeUser | null; shouldScroll?: boolean }>
    ) {
      state.userPermissions.chosenEmployee = action.payload.employee
      state.userPermissions.shouldAutoScroll = !!action.payload.shouldScroll
    },
    setIsUpdating(state, action: PayloadAction<boolean>) {
      state.userPermissions.isUpdating = action.payload
    },
    insertEmployeeWithError(state, action: PayloadAction<EmployeeUser["id"]>) {
      if (!state.userPermissions.employeesWithUpdateError.includes(action.payload)) {
        state.userPermissions.employeesWithUpdateError = [
          ...state.userPermissions.employeesWithUpdateError,
          action.payload,
        ]
      }
    },
    removeEmployeeWithError(state, action: PayloadAction<EmployeeUser["id"]>) {
      state.userPermissions.employeesWithUpdateError =
        state.userPermissions.employeesWithUpdateError.filter(
          (id) => id !== action.payload
        )
    },
    visitedLoadApproval(state) {
      state.visitedLoadApproval = dayjs().toISOString()
    },
  },
})

export const {
  setExpandedItem,
  hideWelcomeLaModal,
  setIntroArticleHasBeenShown,
  setActiveUserPermissionModal,
  setChosenPermission,
  setChosenEmployee,
  setIsUpdating,
  insertEmployeeWithError,
  removeEmployeeWithError,
  showCompletedModal,
  hideCompletedModal,
  showFirstUploadModal,
  hideFirstUploadModal,
  visitedLoadApproval,
} = onboardingSlice.actions
export default onboardingSlice.reducer
