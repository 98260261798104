import { createApi } from "@reduxjs/toolkit/dist/query/react"
import axios from "axios"

import { toastNotification } from "../../../components/common/ToastNotification"
import { DEFAULT_LIST_SIZE, errorMessages } from "../../../constants/retain"
import { buildSerializedError } from "../../../lib/buildSerializedError"
import downloadLink from "../../../lib/downloadLink"
import reportAxiosError from "../../../lib/reportAxiosError"
import {
  DownloadResponse,
  File,
  FilesResponse,
} from "../../../types/retain/Files.types"
import axiosBaseQuery from "../axiosBaseQuery"

export const FILES_TAG = "FilesList"

export const businessFileManagerApi = createApi({
  reducerPath: "businessFileManagerApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  tagTypes: [FILES_TAG],
  endpoints: (builder) => ({
    getFiles: builder.query<
      FilesResponse,
      { offset: number; source: "eligible" | "firm" }
    >({
      query: ({ offset, source }) => ({
        url: `/files/`,
        params: { limit: DEFAULT_LIST_SIZE, offset, source },
      }),
      providesTags: [FILES_TAG],
    }),
    getFile: builder.query<File, { fileId: string }>({
      query: ({ fileId }) => ({
        url: `/files/${fileId}/`,
      }),
    }),
    postFile: builder.mutation<
      undefined,
      {
        file: FormData
      }
    >({
      queryFn: async ({ file }) => {
        try {
          const response = await axios<undefined>({
            url: `/api-v1/files/upload/`,
            method: "POST",
            data: file,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          return { data: response.data }
        } catch (err) {
          reportAxiosError(err, { ignoreStatuses: [400] })
          if (axios.isAxiosError(err)) {
            let message = err.message
            if (err.response?.status === 400) {
              const errorResponseMessage = err.response?.data?.[0]
              message = errorResponseMessage
                ? `Sorry there has been an error: ${errorResponseMessage}`
                : "Sorry there has been an error while uploading your file."
            }
            toastNotification(message, "error")
          }
          const error = buildSerializedError(err)
          return { error }
        }
      },
      invalidatesTags: [FILES_TAG],
    }),
    downloadFile: builder.mutation<
      DownloadResponse,
      {
        fileId: string
      }
    >({
      query: ({ fileId }) => ({
        url: `/files/${fileId}/download/`,
        method: "POST",
      }),
    }),
    deleteFile: builder.mutation<
      undefined,
      {
        fileId: string
      }
    >({
      query: ({ fileId }) => ({
        url: `/files/${fileId}/`,
        method: "DELETE",
      }),
      invalidatesTags: [FILES_TAG],
    }),
    /* getOptedOutList is not currently being used but is ready on the API and will be used in the future
     * to implement the list on the Out-outs page  */
    getOptedOutList: builder.query({
      query: () => ({
        url: `/communication-opted-out-mortgages/`,
      }),
    }),
    downloadOptedOutList: builder.mutation<{ url: string }, void>({
      query: () => ({
        url: `/communication-opted-out-mortgages/download/`,
        method: "POST",
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const res = await queryFulfilled
          downloadLink(res.data.url)
        } catch {
          toastNotification(errorMessages.REQUEST, "error")
        }
      },
    }),
  }),
})

export const {
  useGetFilesQuery,
  useGetFileQuery,
  usePostFileMutation,
  useDownloadFileMutation,
  useDeleteFileMutation,
  useGetOptedOutListQuery,
  useDownloadOptedOutListMutation,
} = businessFileManagerApi
