const importedEnv = {
  ENV_BACKEND_ADMIN_HOST: import.meta.env.ENV_BACKEND_ADMIN_HOST,
  ENV_BACKEND_API_END_POINT: import.meta.env.ENV_BACKEND_API_END_POINT,
  ENV_DOWNTIME_MSG: import.meta.env.ENV_DOWNTIME_MSG,
  ENV_ENVIRONMENT: import.meta.env.ENV_ENVIRONMENT,
  ENV_GOOGLE_MAPS_GEOLOCATION_API_KEY: import.meta.env
    .ENV_GOOGLE_MAPS_GEOLOCATION_API_KEY,
  ENV_GOOGLE_MAPS_STATIC_API_KEY: import.meta.env.ENV_GOOGLE_MAPS_STATIC_API_KEY,
  ENV_BROKER_BEACON: import.meta.env.ENV_BROKER_BEACON,
  ENV_LENDER_BEACON: import.meta.env.ENV_LENDER_BEACON,
  ENV_LOG_LEVEL: import.meta.env.ENV_LOG_LEVEL,
  ENV_POSTHOG_KEY: import.meta.env.ENV_POSTHOG_KEY,
  ENV_POSTHOG_HOST: import.meta.env.ENV_POSTHOG_HOST,
  VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  ENV_SENTRY_REPORTS: import.meta.env.ENV_SENTRY_REPORTS,
  VITE_POST_SVR_SUB_JOURNEY: import.meta.env.VITE_POST_SVR_SUB_JOURNEY,
  VITE_POST_COMPLETION_SUB_JOURNEY_1: import.meta.env
    .VITE_POST_COMPLETION_SUB_JOURNEY_1,
  VITE_POST_COMPLETION_SUB_JOURNEY_2: import.meta.env
    .VITE_POST_COMPLETION_SUB_JOURNEY_2,
} as const

const initialEnv: EnvRecord = {
  ENV_BACKEND_ADMIN_HOST: "pending",
  ENV_BACKEND_API_END_POINT: "pending",
  ENV_ENVIRONMENT: import.meta.env.ENV_ENVIRONMENT || "unknown",
  VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
}

type EnvRecord = Partial<Record<keyof typeof importedEnv, string | undefined>>

export let runtimeEnv: EnvRecord = initialEnv

/**
 * If built, loads the env vars from the app server.
 * If local, will use values parsed from .env file.
 * Error handling is expected to be handled outside this function
 */
export async function initEnv(): Promise<void> {
  if (import.meta.env.PROD) {
    const response = await fetch("/env")
    const result = await response.json()
    runtimeEnv = result
  } else {
    runtimeEnv = importedEnv
  }
  Object.freeze(runtimeEnv)
}
