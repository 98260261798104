import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

import localStorageKeys from "../../constants/localStorageKeys"
import { isDevelopmentEnv } from "../../lib/isDevelopmentEnv"
import { getLocalStorageItem, getSessionStorageItem } from "../../lib/localStorageUtil"
import logSentryBreadcrumb from "../../lib/logSentryBreadcrumb"

export type PersistantState = {
  version?: number
  session: {
    id?: string
    authToken?: string | null
    userId?: string
    identToken?: string
    isCreatingPassword?: boolean
    resetToken?: string
    mortgageId?: string
    email?: string
  }
  longterm: {
    hideApprovalSubmitMsg?: string | null //DateTime
    currentSituationBeenExpanded?: string //DateTime
  }
}

export const SESSION_KEY = "session"
export const LONGTERM_KEY = "longterm"
export const UPDATED_KEY = "updated"

// This function is not good enough to use in production. LOCAL DEV ONLY
const DEV_ONLY_generateUUID = () =>
  "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx".replace(/[x]/g, () => {
    const r = Math.floor(Math.random() * 16)
    return r.toString(16)
  })

/**
 * generateUUID can only use window.crypto.randomUUID() in a secure
 * environment. Locally we run under http but on staging & production
 * we enforce https so it will be available.
 */
const generateUUID = () =>
  isDevelopmentEnv() ? DEV_ONLY_generateUUID() : window.crypto.randomUUID()

const initialiseSession = () => ({
  id: generateUUID(),
})

const initialiseEmptyState = () => ({
  version: 1,
  session: initialiseSession(),
  longterm: {},
})

/**
 * Attempts to get the JSON stored in session storage and parse into the data for the store.
 * If that JSON is not present then attempts to build from deprecated local storage key/values.
 */
const getStateFromStorage = (): PersistantState => {
  const sessionStorage = getSessionStorageItem(SESSION_KEY)
  const longtermStorage = getLocalStorageItem(LONGTERM_KEY)
  const updated = Number(getSessionStorageItem(UPDATED_KEY))
  const newSession = new Date().getTime() > updated + 1000 * 60 * 20
  if (sessionStorage || longtermStorage) {
    try {
      const session = JSON.parse(sessionStorage || "{}") as PersistantState["session"]
      const longterm = JSON.parse(
        longtermStorage || "{}"
      ) as PersistantState["longterm"]
      return {
        version: 1,
        session: {
          id: newSession || !session.id ? generateUUID() : session.id,
          authToken: session.authToken,
          identToken: session.identToken,
          userId: session.userId,
        },
        longterm: {
          currentSituationBeenExpanded: longterm.currentSituationBeenExpanded,
          hideApprovalSubmitMsg: longterm.hideApprovalSubmitMsg,
        },
      }
    } catch (error) {
      logSentryBreadcrumb({ message: `${error}` })
    }
  } else {
    const authToken = getLocalStorageItem(localStorageKeys.authToken)
    if (authToken) {
      return {
        version: 1,
        session: {
          id: generateUUID(),
          authToken,
          identToken: undefined,
          userId: undefined,
        },
        longterm: {},
      }
    } else {
      const identToken = getLocalStorageItem(localStorageKeys.identToken)
      const userId = getLocalStorageItem(localStorageKeys.currentUserId)
      if (identToken && userId) {
        return {
          version: 1,
          session: {
            id: generateUUID(),
            authToken: undefined,
            identToken,
            userId,
          },
          longterm: {},
        }
      }
    }
  }
  return initialiseEmptyState()
}

/**
 * Handles session state that we initialise from local or session storage
 * and expect to be passed in via URL Search Params.
 */
const persistantSlice = createSlice({
  name: "persistant",
  initialState: getStateFromStorage,
  reducers: {
    updateSession: (
      state,
      action: PayloadAction<{
        userId?: string
        authToken?: string | null
        identToken?: string
        mortgageId?: string
        email?: string
        isCreatingPassword?: boolean
        resetToken?: string
        init?: boolean
      }>
    ) => {
      const {
        authToken,
        identToken,
        mortgageId,
        email,
        isCreatingPassword,
        resetToken,
        userId,
        init,
      } = action.payload

      // This initialisation check clears the store if we're coming in with a different user
      if (init && userId && state.session.userId) {
        //Currently an authed user but ident user does not match
        if (state.session.authToken && identToken && userId !== state.session.userId) {
          state.session = initialiseSession()
          state.longterm = {}
        }
        //Current ident user does not match new ident user
        if (state.session.identToken && identToken && userId !== state.session.userId) {
          state.session = initialiseSession()
          state.longterm = {}
        }
      }
      const session = state.session

      // If the session is being updated from a fresh login after a logout,
      // the id will be missing and we need to generate a new one
      session.id = session.id || generateUUID()

      // Final check for authToken here is so that impersonate links from email can override on the ESA
      // by setting `&authToken=` which === "" but users who are authed can stay logged in
      if (
        authToken ||
        (session.userId === userId && session.authToken && authToken !== "")
      ) {
        session.authToken = authToken || session.authToken
        session.identToken = undefined
        session.userId = undefined
      } else if (identToken && userId) {
        session.authToken = null
        session.identToken = identToken
        session.userId = userId
      }

      if (userId) {
        session.userId = userId
      }

      if (authToken === null) {
        session.authToken = null
      }

      if (mortgageId) {
        session.mortgageId = mortgageId
      }

      if (email) {
        session.email = email
      }

      if (isCreatingPassword) {
        session.isCreatingPassword = isCreatingPassword
      }

      if (resetToken) {
        session.resetToken = resetToken
      }
    },
    hideApprovalSubmitMsg: (state, action: PayloadAction<boolean>) => {
      const update = action.payload ? new Date().toISOString() : null
      if (state.longterm) {
        state.longterm.hideApprovalSubmitMsg = update
      } else {
        state.longterm = { hideApprovalSubmitMsg: update }
      }
    },
    currentSituationBeenExpanded: (state) => {
      if (state.longterm) {
        state.longterm.currentSituationBeenExpanded = new Date().toISOString()
      } else {
        state.longterm = { currentSituationBeenExpanded: new Date().toISOString() }
      }
    },
    clearSession: (state) => {
      return {
        version: 1,
        session: {},
        longterm: state.longterm,
      }
    },
  },
})

export const {
  updateSession,
  clearSession,
  hideApprovalSubmitMsg,
  currentSituationBeenExpanded,
} = persistantSlice.actions

export default persistantSlice.reducer
