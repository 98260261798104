import cx from "classnames"
import { ReactNode } from "react"

import Button, { ButtonProps } from "../Button"
import ContentBox from "../ContentBox"
import PopIn from "../PopIn"
import s from "./BasicModal.module.scss"

interface BasicModalProps {
  title?: string | null
  titleRef?: (node: HTMLElement | null) => void | undefined
  preamble?: string | null
  preambleRef?: (node: HTMLElement | null) => void | undefined
  buttons?: ButtonProps[]
  shown: boolean
  hide: () => void
  children?: ReactNode
  horizontalButtons?: boolean
  modalClass?: string
  preambleClass?: string
  isScrollable?: boolean
  modalLabel?: string
  noFade?: boolean
}

const firstItemThatIsDefined = (options: (string | undefined)[]) =>
  options.find((option) => option)
const filterStringNode = (node: string) =>
  node !== "[object Object]" ? node : undefined

const BasicModal = ({
  title,
  titleRef,
  preamble,
  preambleRef,
  children,
  buttons,
  shown,
  hide,
  horizontalButtons,
  modalClass,
  preambleClass,
  isScrollable = true,
  modalLabel,
  noFade,
}: BasicModalProps) => {
  const orientationClass = horizontalButtons ? s.horizontal : s.vertical

  return (
    <PopIn
      hide={hide}
      shown={shown}
      modalClass={modalClass}
      modalLabel={modalLabel}
      noFade={noFade}
    >
      <ContentBox
        className={cx({ [s.scrollableContainer]: isScrollable })}
        topBorder
        noFullWidth
        topMargin={false}
      >
        {title && <h2 ref={titleRef}>{title}</h2>}
        {preamble && (
          <p className={preambleClass} ref={preambleRef}>
            {preamble}
          </p>
        )}
        <div className={orientationClass}>
          <span
            className={cx(s.childrenContainer, {
              [s.innerContentFullHeight]: isScrollable,
            })}
          >
            {children}
          </span>
          {buttons?.map((button, index) => (
            <Button
              {...button}
              fullWidth
              key={firstItemThatIsDefined([
                filterStringNode(`${button.children}`),
                button.trackingId,
                `${index}`,
              ])}
            >
              {button.children}
            </Button>
          ))}
        </div>
      </ContentBox>
    </PopIn>
  )
}

export default BasicModal
