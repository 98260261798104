import { LabelPaths } from "../../../../hooks/blocks/util.types"

export const blogBlocks = {
  articleSecondaryCta: {
    content: {
      type: "text",
      description: "Label for the 2nd CTA button on a blog post",
      default: "Back to current situation",
    },
    image: {
      type: "image",
      description: "Icon for the 2nd CTA button on a blog post",
      default: null,
    },
  },
  articleSecondaryCtaUrl: {
    type: "text",
    description: "URL for 2nd CTA button on a blog post",
    default: "/expiry-date",
  },
} as const

export type BlogLabelPaths = LabelPaths<typeof blogBlocks>
