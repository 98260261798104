export const TwoFactorTokenRequestErrorReasons = {
  TWILIO_NOT_SETUP: "twilio-not-setup",
  TWILIO_EXCEPTION: "twilio-exception",
  TWILIO_STATUS: "twilio-status-incorrect",
  PHONE_NUMBER_REQUIRED: "phone-number-required",
  INVALID_PHONE_NUMBER: "invalid-phone-number",
  MAX_ATTEMPTS_REACHED: "max-attempts-reached",
  LANDLINE: "landline-number",
  UNKNOWN: "unknown",
  TWO_FACTOR_REQUIRED: "2fa-required",
} as const

export type TwoFactorTokenRequestErrorReasons =
  typeof TwoFactorTokenRequestErrorReasons[keyof typeof TwoFactorTokenRequestErrorReasons]

export const TwoFactorVerificationErrorReasons = {
  TWILIO_NOT_SETUP: "twilio-not-setup",
  INVALID_PHONE_NUMBER: "invalid-phone-number",
  TWILIO_EXCEPTION: "twilio-exception",
  PHONE_NUMBER_REQUIRED: "phone-number-required",
  TWILIO_STATUS: "twilio-status-incorrect",
  INCORRECT_TOKEN: "incorrect-token",
  MAX_ATTEMPTS_REACHED: "max-attempts-reached",
  LANDLINE: "landline-number",
  UNKNOWN: "unknown",
  VALIDATION: "validation",
} as const

export type TwoFactorVerificationErrorReasons =
  typeof TwoFactorVerificationErrorReasons[keyof typeof TwoFactorVerificationErrorReasons]
