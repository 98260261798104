export const remindProductsPageBlocks = {
  lender: {
    remindProducts: {
      ready: {
        title: {
          type: "text",
          description: "Title of the ready page",
          default: "Select the button above to confirm your reminder",
        },
      },
      remindButton: {
        labelContent: {
          type: "text",
          description: "Label of the remind button",
          default: "Remind me",
        },
      },
      successButton: {
        labelContent: {
          type: "text",
          description: "Label of the success button",
          default: "View payments",
        },
      },
      success: {
        title: {
          type: "text",
          description: "Title of the success page",
          default: "Your reminder has been recorded",
        },
        copyContent: {
          type: "text",
          description: "Copy of the success page",
          default: "You can view your payments by selecting the button above",
        },
      },
    },
  },
} as const
