import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit"

import localStorageKeys from "../../constants/localStorageKeys"
import { setSessionStorageItem } from "../../lib/localStorageUtil"
import logSentryBreadcrumb from "../../lib/logSentryBreadcrumb"
import type { AppStartListening } from "../store"
import { setConceptRead, setSubtopicsUnread } from "./learnSession"

export const learnSessionListener = createListenerMiddleware()
// It's impossible for .getState() typing to be known ahead of time so we cast here.
// It's the recommended approach. https://redux-toolkit.js.org/api/createListenerMiddleware#typescript-usage
const startListening = learnSessionListener.startListening as AppStartListening

startListening({
  matcher: isAnyOf(setConceptRead, setSubtopicsUnread),
  effect: (_, listenerApi) => {
    try {
      setSessionStorageItem(
        localStorageKeys.learnSession,
        JSON.stringify(listenerApi.getState().learnSession)
      )
    } catch {
      logSentryBreadcrumb({
        level: "error",
        message: "Could not stringify learnSession",
      })
    }
  },
})
