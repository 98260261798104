const BASE_URL = "/api-v1"

type Params = {
  [index: string]: string | number
}

export default {
  callerFlowStepTrack: `${BASE_URL}/caller-flow-step-track/`,
  user: `${BASE_URL}/me/`,
  logIn: `${BASE_URL}/login/`,
  logOut: `${BASE_URL}/logout/`,
  employee: `${BASE_URL}/employee/`,
  employeeDetail({ id }: Params): string {
    return `${this.employee}${id}/`
  },
  suppression: `${BASE_URL}/suppression/`,
  suppressionDownload: `${BASE_URL}/suppression/download/`,
  invalidEmails: `${BASE_URL}/invalid-emails/`,
  invalidEmailsDownload: `${BASE_URL}/invalid-emails/download/`,
  firmSettings: ({ id }: Params): string => `${BASE_URL}/firm-settings/${id}/`,
  managementReports: `${BASE_URL}/management-reports/`,
  permissionGroups: `${BASE_URL}/permission-groups/`,
  setPointOfContact: (consumerID: string): string =>
    `${BASE_URL}/consumer/${consumerID}/set-point-of-contact/`,
  pageView: `${BASE_URL}/pageview/`,
  approval: (id?: string): string => `${BASE_URL}/approval/${id ? `${id}/` : ""}`,
  unsubscribe: `${BASE_URL}/unsubscribe/`,
  notInterested: `${BASE_URL}/not-interested/`,
  mortgages: `${BASE_URL}/mortgage/`,
  numMortgages: `${BASE_URL}/mortgage/num-mortgages/`,
  approvalSubmit: (id: string): string => `${BASE_URL}/approval/${id}/submit/`,
  approvalProblem: (id: string): string => `${BASE_URL}/approval/${id}/problem/`,
  lenders: `${BASE_URL}/lenders/`,
  validateEmail: `${BASE_URL}/validate/email/`,
  validatePhone: `${BASE_URL}/validate/phone-number/`,
  reportsCharts: (ids?: string, advisorId?: string): string => {
    if (advisorId)
      return `${BASE_URL}/management-reports/charts/?charts=${ids}&advisor_id=${advisorId}`
    return `${BASE_URL}/management-reports/charts/?charts=${ids}`
  },
  reportsInfo: (ids?: string, advisorId?: string): string => {
    if (advisorId)
      return `${BASE_URL}/management-reports/info/?charts=${ids}&advisor_id=${advisorId}`
    return `${BASE_URL}/management-reports/info/?charts=${ids}`
  },
  reportingAdvisors: `${BASE_URL}/management-reports/advisors/`,
  resetPasswordConfirm: `${BASE_URL}/reset-password-confirm/`,
  requestTwoAuthToken: `${BASE_URL}/request-2fa-token/`,
  resetPassword: `${BASE_URL}/reset-password/`,
  toolboxNetworks: `${BASE_URL}/toolbox-network/`,
  emailOptOut: `${BASE_URL}/email-tracking-opt-out/`,
  snoozeEmail: `${BASE_URL}/snooze-email/`,
  requestCall: `${BASE_URL}/request-call/`,
  questions: `${BASE_URL}/questions/`,
  answer: (id: string): string => `${BASE_URL}/questions/${id}/answer/`,
  answers: `${BASE_URL}/answers/`,
  content: `${BASE_URL}/content/`,
  haveYouActioned: `${BASE_URL}/have-you-actioned/`,
  sso: `${BASE_URL}/sso/`,
}
