import { useState } from "react"

const useToggle = (defaultState = false): [boolean, () => void] => {
  const [state, setState] = useState<boolean>(defaultState)

  const toggle = () => {
    setState(!state)
  }

  return [state, toggle]
}
export default useToggle
