import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export interface ProductSelectionState {
  selectedAdvisorId: string | undefined
}

const initialState: ProductSelectionState = {
  selectedAdvisorId: undefined,
}

const advisorsSlice = createSlice({
  name: "advisors",
  initialState,
  reducers: {
    setSelectedAdvisorId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedAdvisorId = action.payload
    },
  },
})

export const { setSelectedAdvisorId } = advisorsSlice.actions

export default advisorsSlice.reducer
