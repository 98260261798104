import { parseFloatOrNull } from "../../../lib/parsers/parseFloatOrNull"
import { ProductOptionBase } from "./ProductSelection.types"

export const parseProduct = (products: ProductOptionBase<string>[]) => {
  return products.map((product) => ({
    ...product,
    monthlyPreferentialPayment: parseFloat(product.monthlyPreferentialPayment),
    preferentialRate: parseFloat(product.preferentialRate),
    fees: parseFloat(product.fees),
    aprc: parseFloat(product.aprc),
    monthlyFollowOnPayment: parseFloat(product.monthlyFollowOnPayment),
    followOnRate: parseFloat(product.followOnRate),
    ltv: parseFloat(product.ltv),
    ercYear1: parseFloatOrNull(product.ercYear1),
    ercYear2: parseFloatOrNull(product.ercYear2),
    ercYear3: parseFloatOrNull(product.ercYear3),
    ercYear4: parseFloatOrNull(product.ercYear4),
    ercYear5: parseFloatOrNull(product.ercYear5),
  }))
}
