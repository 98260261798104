import { LabelPaths } from "../../../hooks/blocks/util.types"

export const menuBlocks = {
  menuActionedMortgage: {
    type: "text",
    description: "Label for actioned mortgage menu item",
    default: "Manage alerts",
  },
  menuChangePassword: {
    type: "text",
    description: "Label for change password menu item",
    default: "Change password",
  },
  menuContactPreferences: {
    type: "text",
    description: "Label for contact preferences menu item",
    default: "Contact preferences",
  },
  menuHome: {
    type: "text",
    description: "Label for home menu item",
    default: "Home",
  },
  menuJourneyExplore: {
    type: "text",
    description: "Label for lender switch exploration menu item",
    default: "Explore switching",
  },
  menuJourneyViewMortgage: {
    type: "text",
    description: "Label for lender mortgage menu item",
    default: "Your mortgage payment",
  },
  menuLogIn: {
    type: "text",
    description: "Label for log in menu item",
    default: "Log in",
  },
  menuLogOut: {
    type: "text",
    description: "Label for log out menu item",
    default: "Log out",
  },
  menuManageNotifications: {
    type: "text",
    description: "Label for notifications menu item",
    default: "Manage notifications",
  },
  menuMortgagesHome: {
    type: "text",
    description: "Label for home overview item",
    default: "Overview",
  },
  menuRequestCall: {
    type: "text",
    description: "Label for call request menu item",
    default: "Request a call",
  },
  menuResetPassword: {
    type: "text",
    description: "Label for reset password menu item",
    default: "Reset password",
  },
} as const

export type MenuPaths = LabelPaths<typeof menuBlocks>
