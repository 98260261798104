import cx from "classnames"
import { ReactNode } from "react"

import s from "./Container.module.scss"
export interface ContainerProps {
  children: ReactNode
  bg?: "white" | "transparent"
  isFullHeight?: boolean
  pageWrapper?: boolean
}

const Container = ({
  children,
  bg = "white",
  isFullHeight,
  pageWrapper,
}: ContainerProps) => {
  const inner = (
    <div
      className={cx(s.container, {
        [s[bg]]: bg && s[bg],
        [s.fullHeight]: isFullHeight,
      })}
    >
      {children}
    </div>
  )
  return pageWrapper ? <div className={s.wrapper}>{inner}</div> : inner
}

export default Container
