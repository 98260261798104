import { useContent_Unstable } from "../../../hooks/blocks/useContent_Unstable"
import useMdxContent from "../../../hooks/mdx/useMdxContent"
import useToggle from "../../../hooks/toggle"
import Minus from "../../../images/Minus.svg?react"
import Plus from "../../../images/Plus.svg?react"
import Unsure from "../../../images/Unsure.svg?react"
import { currencyWholePounds, percent } from "../../../lib/formatters"
import { ContentId } from "../../../types/retain/Content.types"
import { Mortgage } from "../../../types/retain/Mortgages.types"
import AnimateHeight from "../AnimateHeight"
import s from "./MortgageOverview.module.scss"
import { MortgageOverviewPaths } from "./mortgageOverviewBlocks"
import useTrackBasedOnExpansionToggle from "./useTrackBasedOnExpansionToggle"

interface FollowOnPaymentProps {
  loading?: boolean
  mortgage: Mortgage
  owned?: boolean
  pronoun: string
}

const rateHeaderPath = ContentId.FOLLOW_ON_PAYMENTS_RATE_TABLE_HEADER

const FollowOnPayments = ({
  mortgage,
  loading,
  owned,
  pronoun,
}: FollowOnPaymentProps) => {
  const [showBasedOn, toggleShowBasedOn] = useToggle()
  const trackBasedOnExpansionToggle = useTrackBasedOnExpansionToggle()

  function handleBasedOnClick() {
    trackBasedOnExpansionToggle({
      isPreviouslyExpanded: showBasedOn,
      mortgageId: mortgage.id,
    })
    toggleShowBasedOn()
  }

  // TODO: ENG-3175 Review givenCurrentBalance use within FollowOnPayments
  const basedOnCopy = useMdxContent<MortgageOverviewPaths>(
    mortgage.expired
      ? ContentId.FOLLOW_ON_PAYMENTS_BASED_ON__EXPIRED__MDX
      : ContentId.FOLLOW_ON_PAYMENTS_BASED_ON__ACTIVE__MDX,
    {
      balance: mortgage.givenCurrentBalance
        ? currencyWholePounds(mortgage.givenCurrentBalance)
        : currencyWholePounds(mortgage.debtAfterPreferentialRate),
    }
  )
  const { b, bRef } = useContent_Unstable<MortgageOverviewPaths>()

  return (
    <>
      <h3 className={s.title}>
        <Unsure className={s.titleIcon} />
        {mortgage.expired ? `${pronoun} current term` : `${pronoun} remaining term`}
      </h3>
      <div className={s.followOnTable}>
        <table summary="table of monthly payments">
          <thead>
            <tr>
              <th>Monthly payment</th>
              <th ref={bRef(rateHeaderPath)}>{b(rateHeaderPath)}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className={s.followOnPayment}>
                  {currencyWholePounds(mortgage.monthlyFollowOnPayment)}
                </span>
              </td>
              <td>
                <span className={s.followOnRate}>
                  {percent(mortgage.followOnRate / 100)}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {!loading && owned && (
        <div className={s.basedOnContainer}>
          <button
            type="button"
            className={s.basedOnButton}
            onClick={handleBasedOnClick}
          >
            <h3 className={s.basedOnTitle}>
              Based on{" "}
              <div className={s.basedOnIcon}>{showBasedOn ? <Minus /> : <Plus />}</div>
            </h3>
          </button>
          <AnimateHeight in={showBasedOn}>
            <div className={s.basedOnCopy}>
              {basedOnCopy}
              {mortgage.isSharedOwnership && (
                <p>
                  This payment does not include rental payments made to housing
                  associations.
                </p>
              )}
            </div>
          </AnimateHeight>
        </div>
      )}
    </>
  )
}

interface FollowOnPaymentsEarlyReturnProps {
  loading?: boolean
  mortgage: Mortgage | null
  owned?: boolean
  pronoun: string
}

const FollowOnPaymentsEarlyReturn = ({
  mortgage,
  loading,
  owned,
  pronoun,
}: FollowOnPaymentsEarlyReturnProps) => {
  if (mortgage === null) return null
  return (
    <FollowOnPayments
      mortgage={mortgage}
      loading={loading}
      owned={owned}
      pronoun={pronoun}
    />
  )
}

export default FollowOnPaymentsEarlyReturn
