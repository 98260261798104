import { LabelPaths } from "../../../../hooks/blocks/util.types"

export const defaultContentFallback = `The Product Switch process is execution-only, without any advice or help to select the right mortgage for you. Click ‘I understand’ to proceed.

Need help selecting a mortgage or want to borrow more? Click ‘I need help’ to get in touch with a mortgage broker.`

export const defaultAcceptButton = `I understand`
export const defaultBackButton = `I need help`

export const acceptanceModalsBlocks = {
  lender: {
    withoutAdvice: {
      modal: {
        descriptionContent: {
          type: "markdown",
          description: "Copy displayed on the acceptance modal",
          default: defaultContentFallback,
        },
        understandButton: {
          labelContent: {
            type: "text",
            description: "Label for the accept button on the acceptance modal",
            default: defaultAcceptButton,
          },
        },
        backButton: {
          labelContent: {
            DEPRECATED_field: "backButton",
            type: "text",
            description: "Label for the back button on the acceptance modal",
            default: defaultBackButton,
          },
        },
      },
    },
    internalAdvice: {
      modal: {
        descriptionContent: {
          type: "markdown",
          description: "Copy displayed on the acceptance modal",
          default: defaultContentFallback,
        },
        understandButton: {
          labelContent: {
            type: "text",
            description: "Label for the accept button on the acceptance modal",
            default: defaultAcceptButton,
          },
        },
        backButton: {
          labelContent: {
            type: "text",
            description: "Label for the back button on the acceptance modal",
            default: defaultBackButton,
          },
        },
      },
    },
    externalAdvice: {
      modal: {
        descriptionContent: {
          type: "markdown",
          description: "Copy displayed on the acceptance modal",
          default: defaultContentFallback,
        },
        understandButton: {
          labelContent: {
            type: "text",
            description: "Label for the accept button on the acceptance modal",
            default: defaultAcceptButton,
          },
        },
        backButton: {
          labelContent: {
            type: "text",
            description: "Label for the back button on the acceptance modal",
            default: defaultBackButton,
          },
        },
      },
    },
  },
} as const

export type AcceptanceModalsLabelPaths = LabelPaths<typeof acceptanceModalsBlocks>
