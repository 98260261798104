import { createTrickling } from "trickling"

const tricklingProgress = createTrickling({
  showSpinner: false,
  color: "#0485ff",
  progressBarHeight: "2.5px",
})

// We use counter here just so if there is more API calls at once
// we show the progress bar until all of them are done
const progressLoader = {
  counter: 0,
  start: function () {
    this.counter += 1
    !tricklingProgress.isStarted() && tricklingProgress.start()
  },
  complete: function () {
    if (this.counter > 0) {
      this.counter -= 1
    }
    this.counter === 0 && tricklingProgress.done()
  },
  setOptions: function (options: Parameters<typeof tricklingProgress.setOptions>[0]) {
    tricklingProgress.setOptions(options)
  },
}

export { progressLoader }
