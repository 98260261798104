import { toastNotification } from "../../../components/common/ToastNotification"
import isRtkQueryError from "../../../lib/isRtkQueryError"

async function toastOnLifecycleApiError(
  queryFulfilled: Promise<unknown>,
  ignoreStatuses?: string[]
) {
  try {
    await queryFulfilled
  } catch (err: unknown) {
    if (
      isRtkQueryError(err) &&
      err.code &&
      ignoreStatuses &&
      ignoreStatuses.includes(err.code)
    ) {
      return
    }
    toastNotification(
      "Sorry, there's been a problem. We're looking into a fix.",
      "error",
      {
        autoClose: false,
      }
    )
  }
}

export default toastOnLifecycleApiError
