import Alert from "../../../images/Alert.svg?react"
import TickNoCircle from "../../../images/TickNoCircle.svg?react"
import s from "./Icons.module.scss"

const Tick = () => (
  <div className={`${s.toastIcon} ${s.toastIconSuccess}`}>
    <TickNoCircle />
  </div>
)

const Bell = () => (
  <div className={`${s.toastIcon} ${s.toastIconError}`}>
    <Alert />
  </div>
)

export { Tick, Bell }
