import { retry } from "@reduxjs/toolkit/dist/query/react"
import { createApi } from "@reduxjs/toolkit/query/react"
import axios from "axios"

import { lifecycleModule } from "../../../components/consumer/Lifecycle/utils/lifecycleModules"
import { buildSerializedError } from "../../../lib/buildSerializedError"
import reportAxiosError from "../../../lib/reportAxiosError"
import { Content } from "../../../types/retain/Content.types"
import buildTagsFrom403 from "../../actions/buildTagsFrom403"
import axiosBaseQuery from "../axiosBaseQuery"
import {
  InterestRatesResponse,
  ProductRatesResponse,
  PropertyPriceResponse,
} from "../lifecycleFactor/lifecycleFactorTypes"
import toastOnLifecycleApiError from "../lifecycleFactor/toastOnLifecycleApiError"

export const consumerSideApi = createApi({
  reducerPath: "consumerSideApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  tagTypes: Object.values(lifecycleModule),
  endpoints: (builder) => ({
    getContent: builder.query<Content, void>({
      queryFn: retry(async () => {
        try {
          const { data } = await axios.get<Content>("/api-v1/content/")
          return { data }
        } catch (err) {
          reportAxiosError(err, {
            buildTagsFromError: buildTagsFrom403,
          })
          const error = buildSerializedError(err)
          return { error }
        }
      }),
      keepUnusedDataFor: 60 * 60 * 24, // 1 day in seconds
    }),
    getPropertyPriceByMortgageId: builder.query<PropertyPriceResponse, string>({
      query: (id) => ({
        url: `/mortgage/${id}/property-value/`,
        reportContext: {
          ignoreStatuses: [401],
          prettyURL: "/api-v1/mortgage/{mortgage.id}/property-value/",
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await toastOnLifecycleApiError(queryFulfilled)
      },
      providesTags: (result, error, arg) => [
        { type: lifecycleModule.PROPERTY_VALUE, id: arg },
      ],
    }),
    getInterestRatesByMortgageId: builder.query<InterestRatesResponse, string>({
      query: (id) => ({
        url: `/mortgage/${id}/interest-rates/`,
        reportContext: {
          ignoreStatuses: [401, 404],
          prettyURL: "/api-v1/mortgage/{mortgage.id}/interest-rates/",
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await toastOnLifecycleApiError(queryFulfilled, ["404"])
      },
      providesTags: (result, error, arg) => [
        { type: lifecycleModule.INTEREST_RATES, id: arg },
      ],
    }),
    requestProductRatesByMortgageId: builder.query<
      ProductRatesResponse | null,
      { id?: string; initialTerm?: string }
    >({
      query: ({ id, initialTerm }) => ({
        url: `/mortgage/${id}/product-rates/`,
        params: { initial_term: initialTerm },
        reportContext: {
          ignoreStatuses: [401],
          prettyURL: "/api-v1/mortgage/{mortgage.id}/product-rates/",
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await toastOnLifecycleApiError(queryFulfilled)
      },
      providesTags: (result, error, arg) => [
        { type: lifecycleModule.PRODUCT_RATES, id: arg.id },
      ],
    }),
    requestCallByModuleByMortgageId: builder.mutation<
      null,
      {
        id: string
        module: lifecycleModule
        initialTerm?: string
      }
    >({
      query: ({ id, module, initialTerm }) => ({
        url: `/mortgage/${id}/${module}/call-request/`,
        method: "POST",
        requestData: {
          initialTerm,
        },
        reportContext: {
          ignoreStatuses: [401],
          prettyURL: `/api-v1/mortgage/{mortgage.id}/${module}/call-request/`,
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await toastOnLifecycleApiError(queryFulfilled)
      },
      invalidatesTags: (result, error, arg) => [{ type: arg.module, id: arg.id }],
    }),
    confirmAllGood: builder.mutation<
      null,
      {
        id: string
        module: lifecycleModule
        initialTerm?: string
      }
    >({
      query: ({ id, module, initialTerm }) => ({
        url: `/mortgage/${id}/${module}/confirm/`,
        method: "POST",
        requestData: {
          initialTerm,
        },
        reportContext: {
          ignoreStatuses: [401],
          prettyURL: `/api-v1/mortgage/{mortgage.id}/${module}/confirm/`,
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await toastOnLifecycleApiError(queryFulfilled)
      },
      invalidatesTags: (result, error, arg) => [{ type: arg.module, id: arg.id }],
    }),
    confirmIntroSeen: builder.mutation({
      query: () => ({
        url: `/mortgage/intro-seen/`,
        method: "POST",
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await toastOnLifecycleApiError(queryFulfilled)
      },
    }),
  }),
})

export const {
  useGetContentQuery,
  useGetPropertyPriceByMortgageIdQuery,
  useGetInterestRatesByMortgageIdQuery,
  useRequestProductRatesByMortgageIdQuery,
  useRequestCallByModuleByMortgageIdMutation,
  useConfirmAllGoodMutation,
  useConfirmIntroSeenMutation,
} = consumerSideApi
