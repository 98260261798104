import { isAxiosError } from "axios"
import { Dispatch } from "redux"

import { progressLoader } from "../../components/common/tricklingProgress"
import ActionType from "../../constants/actionTypes"
import { isConsumer } from "../../lib/isConsumer"
import { setSessionStorageItem } from "../../lib/localStorageUtil"
import reportAxiosError from "../../lib/reportAxiosError"
import { Consumer } from "../../types/retain/Consumer.types"
import { Firm } from "../../types/retain/Firms.types"
import { UPDATED_KEY, updateSession } from "../slices/persistantSession"
import { RootState } from "../store"
import buildTagsFrom403 from "./buildTagsFrom403"
import { ORIGIN_NULL_MSG, retryUserOnce } from "./retryUserOnce"

export const getUser = () => {
  return async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    dispatch({ type: ActionType.USER_REQUEST })
    const userId = getState().persistant.session.userId
    const token = getState().persistant.session.identToken
    const params =
      token && userId
        ? {
            token,
            user_id: userId,
          }
        : undefined
    try {
      const response = await retryUserOnce(params)
      setSessionStorageItem(UPDATED_KEY, `${new Date().getTime()}`)
      const {
        firm,
        currentUser,
        canRequestCall,
        businessSide,
        firstTimeUser,
        home,
        permissions,
        lists,
        featureFlags,
        onboarding,
      } = response.data

      if (!businessSide) {
        progressLoader.setOptions({
          color: firm?.primaryColor ?? "#0485ff",
        })
      }

      const isAuth = !!currentUser?.id
      dispatch(updateSession({ userId: currentUser?.id }))
      dispatch({
        type: ActionType.USER_SUCCESS,
        isAuth,
        authExpiry: response.headers["x-auth-expiry"],
        firstTimeUser: !!firstTimeUser,
        businessSide,
        firm,
        currentUser,
        currentUserId: currentUser?.id,
        canRequestCall,
        home,
        permissions,
        availableLists: lists,
        featureFlags,
        onboarding,
      })
    } catch (e) {
      reportAxiosError(e, {
        buildTagsFromError: buildTagsFrom403,
      })
      let errorMessage = "Error fetching user"
      if (
        isAxiosError(e) &&
        e.response?.status === 403 &&
        e.response?.data === ORIGIN_NULL_MSG
      ) {
        errorMessage =
          "Error fetching user. Update your browser to the latest version if this error keeps happening."
      }
      dispatch({
        type: ActionType.USER_FAILURE,
        errorMessage,
      })
    }
  }
}

export const updateUser = (firm: Firm, currentUser: Consumer, token: string) => {
  return (dispatch: Dispatch): void => {
    dispatch(updateSession({ authToken: token, userId: currentUser?.id }))
    const isAuth = !!currentUser?.id
    dispatch({
      type: ActionType.USER_SUCCESS,
      isAuth,
      businessSide: !currentUser.isConsumer,
      firm,
      currentUser,
      currentUserId: currentUser?.id,
      canRequestCall: isConsumer(currentUser) && currentUser?.canRequestCall,
    })
  }
}
