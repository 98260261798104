const AuthErrorReason = {
  BAD_TOKEN: "bad-token",
  CONSUMER_EXPECTED: "consumer-expected",
  EMPLOYEE_EXPECTED: "employee-expected",
  INVALID_AUTH_PARAMETERS: "invalid-auth-parameters",
  NO_AUTH_PARAMETERS: "no-auth-parameters",
  EXPIRED_TOKEN: "expired-token",
  USER_ARCHIVED: "user-archived",
  SITE_DISABLED: "site-disabled",
  USER_DOES_NOT_EXIST: "user-does-not-exist",
  BAD_PASSWORD_RESET_TOKEN: "bad-password-reset-token",
} as const

export { AuthErrorReason }
