import * as Sentry from "@sentry/react"
import axios, { AxiosResponse, AxiosError } from "axios"
import { Dispatch } from "redux"

import { toastNotification } from "../../components/common/ToastNotification"
import { TwoFactorVerificationErrorReasons } from "../../components/common/TwoFactorAuthentication/TwoFactorAuthenticationReasons"
import ActionType from "../../constants/actionTypes"
import { AuthErrorReason } from "../../constants/authErrorReasons"
import endpoints from "../../constants/endpoints"
import localStorageKeys from "../../constants/localStorageKeys"
import { errorMessages } from "../../constants/retain"
import { removeLocalStorageIdentificationItems } from "../../lib/auth"
import createTagsFromPrimitives from "../../lib/createTagsFromPrimitives"
import { pluralize } from "../../lib/formatters"
import HttpStatusCode from "../../lib/httpStatusCodes"
import {
  removeSessionStorageItem,
  setSessionStorageItem,
} from "../../lib/localStorageUtil"
import reportAxiosError from "../../lib/reportAxiosError"
import { Consumer } from "../../types/retain/Consumer.types"
import { Firm } from "../../types/retain/Firms.types"
import resetApiStateOnAllServices from "../services/resetApiStateOnAllServices"
import { clearSession, updateSession } from "../slices/persistantSession"
import { clearModalQueue } from "../slices/ui"
import { RootState } from "../store"

export enum LoginErrorReasons {
  INVALID_PHONE_NUMBER = "invalid-phone-number",
  LANDLINE = "landline-number",
  TWO_FACTOR_REQUIRED = "2fa-required",
}

export type PasswordResetRequestCredentials = {
  email: string
  userId: string | null
  token: string | null
}

export type PasswordConfirmCredentials = {
  newPassword: string
  twoFactorToken?: string
  userId: string | undefined
  token: string | undefined
  isCreating: boolean
  resetToken?: string
}

export type AuthCredentials = {
  email: string
  password: string
  phoneNumber?: string
  twoFactorToken?: string
}

export type KnowledgeBasedAuth = {
  userId: string | undefined
  token: string | undefined
}

export const defaultErrorMessage =
  "Sorry, there's been an error on our side. We've been notified and are looking into it."

export const invalidCredentialsMessage = (kba: boolean): string =>
  kba
    ? "Sorry, we couldn't match your details."
    : "Either the email or the password are not valid."

const loginAttemptsExceededMessageParts = (kba: boolean): string[] => [
  invalidCredentialsMessage(kba),
  "You have exceeded the maximum number of login attempts. Please try again later.",
]

const loginFailedWarningMessageParts = (
  kba: boolean,
  minutesCooloff: number
): string[] => [
  invalidCredentialsMessage(kba),
  `For security reasons, too many failed login attempts in a short period of time will`,
  `block further login attempts for ${minutesCooloff} ${pluralize(
    "minute",
    minutesCooloff
  )}.`,
]

const isKBA = (credentials: AuthCredentials | KnowledgeBasedAuth): boolean =>
  "dob" in credentials

export const shouldRedirectTo2FA = (
  resp: AxiosResponse,
  email: string,
  password: string
): boolean => {
  const { reason } = resp.data

  return (
    [
      LoginErrorReasons.TWO_FACTOR_REQUIRED,
      LoginErrorReasons.INVALID_PHONE_NUMBER,
      LoginErrorReasons.LANDLINE,
    ].indexOf(reason) > -1 &&
    email !== undefined &&
    password !== undefined
  )
}

export const logIn = (
  credentials: AuthCredentials | KnowledgeBasedAuth,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  successCb?: (data: { [key: string]: any }) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCb?: (data: { [key: string]: any }) => void,
  onTwoFactorRequired?: (phoneNumberRequired: boolean) => void
) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<AxiosResponse | Error> => {
    dispatch({ type: ActionType.LOGIN_REQUEST })

    try {
      const resp = await axios.post(endpoints.logIn, credentials)
      let errorMessage
      if (resp.data.access === "denied") {
        const { email, password } = credentials as AuthCredentials

        if (shouldRedirectTo2FA(resp, email, password)) {
          setSessionStorageItem(localStorageKeys.emailForTwoFA, email)
          setSessionStorageItem(localStorageKeys.passwordForTwoFA, password)

          const pnRequiredArg = resp.data["phone-number-required"]
          const phoneNumberRequired = pnRequiredArg || pnRequiredArg === undefined

          if (phoneNumberRequired) {
            setSessionStorageItem(localStorageKeys.phoneNumberRequired, String(true))
          } else {
            removeSessionStorageItem(localStorageKeys.phoneNumberRequired)
          }

          errorMessage = "Multi-factor authentication required"

          if (onTwoFactorRequired) onTwoFactorRequired(phoneNumberRequired)
        } else if (resp.data.throttling) {
          if (resp.data.reason === TwoFactorVerificationErrorReasons.INCORRECT_TOKEN) {
            if (errorCb) errorCb(resp.data)
          }
          const { numLeft, secondsCooloff } = resp.data.throttling
          const minutesCooloff = Math.ceil(secondsCooloff / 60)
          if (numLeft === 0) {
            // edge case that technically doesn't raise a 429 but should be dealt
            // with as such because UX
            errorMessage = loginAttemptsExceededMessageParts(isKBA(credentials)).join(
              " "
            )
          } else {
            errorMessage = loginFailedWarningMessageParts(
              isKBA(credentials),
              minutesCooloff
            ).join(" ")
          }
        } else {
          if (errorCb) errorCb(resp.data)

          // we don't have throttling context
          errorMessage = errorMessages.UNKNOWN
        }
        dispatch({ type: ActionType.LOGIN_FAILURE, errorMessage })
      } else {
        const {
          data: {
            firm,
            currentUser,
            token,
            home,
            permissions,
            lists,
            featureFlags,
            onboarding,
          },
        } = resp
        if (getState().persistant.session.isCreatingPassword) {
          toastNotification("Your account has been successfully activated.", "info")
        }

        // we're properly authorised now, so we discard previous
        // "identify" information
        removeLocalStorageIdentificationItems()
        dispatch(updateSession({ authToken: token }))

        dispatch({
          type: ActionType.LOGIN_SUCCESS,
          firm,
          authExpiry: resp.headers["x-auth-expiry"],
          isAuth: true,
          businessSide: !currentUser.isConsumer,
          currentUser,
          currentUserId: currentUser.id,
          home,
          permissions,
          availableLists: lists,
          featureFlags,
          onboarding,
        })

        removeSessionStorageItem(localStorageKeys.emailForTwoFA)
        removeSessionStorageItem(localStorageKeys.passwordForTwoFA)
        removeSessionStorageItem(localStorageKeys.phoneNumberRequired)

        if (successCb) successCb(resp.data)
      }

      return resp
    } catch (err) {
      if (axios.isAxiosError(err)) {
        // We ignore BAD_REQUEST/400 and TOO_MANY_REQUESTS/429 as they are handled below and expected.
        reportAxiosError(err, {
          tags: createTagsFromPrimitives(credentials),
          ignoreStatuses: [
            HttpStatusCode.BAD_REQUEST,
            HttpStatusCode.TOO_MANY_REQUESTS,
          ],
        })
        if (err.response?.status === HttpStatusCode.TOO_MANY_REQUESTS) {
          dispatch({
            type: ActionType.LOGIN_FAILURE,
            errorMessage: loginAttemptsExceededMessageParts(isKBA(credentials)).join(
              " "
            ),
          })
        } else if (err.response?.status === HttpStatusCode.BAD_REQUEST) {
          dispatch({
            type: ActionType.LOGIN_FAILURE,
            errorMessage: errorMessages.EXPIRED_TOKEN,
          })
        } else {
          dispatch({
            type: ActionType.LOGIN_FAILURE,
            errorMessage: defaultErrorMessage,
          })
        }
        if (errorCb) {
          errorCb(err)
        }
        return err
      } else {
        reportAxiosError(err, { tags: createTagsFromPrimitives(credentials) })
        dispatch({
          type: ActionType.LOGIN_FAILURE,
          errorMessage: defaultErrorMessage,
        })
        if (err instanceof Error) {
          if (errorCb) {
            errorCb(err)
          }
          return err
        }
      }
      return new Error("Value thrown within auth/logIn was not an Error")
    }
  }
}

export const logOut = () => {
  return (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: ActionType.LOGOUT_REQUEST })
    return axios
      .post(endpoints.logOut)
      .then(() => {
        dispatch({ type: ActionType.LOGOUT_SUCCESS })
      })
      .catch((err: unknown) => {
        reportAxiosError(err)
        dispatch({ type: ActionType.LOGOUT_FAILURE })
      })
      .finally(() => {
        resetApiStateOnAllServices(dispatch)
        dispatch(clearSession())
        dispatch(clearModalQueue())
      })
  }
}

export const resetPasswordRequest = (credentials: PasswordResetRequestCredentials) => {
  return async (
    dispatch: Dispatch
  ): Promise<AxiosResponse | AxiosError | undefined> => {
    dispatch({ type: ActionType.PASSWORD_RESET_REQUEST })

    try {
      const r = await axios.post(endpoints.resetPassword, credentials)
      dispatch({ type: ActionType.PASSWORD_RESET_REQUEST_SUCCESS })
      return r
    } catch (err) {
      reportAxiosError(err, { ignoreStatuses: [400, 409] })
      dispatch({ type: ActionType.PASSWORD_RESET_REQUEST_FAILURE })
      if (axios.isAxiosError(err)) {
        return err
      }
      return undefined
    }
  }
}

export type RequestPasswordConfirmResponseData = {
  passwordResetConfirm: boolean
  reason: "2fa-required" | "phone-number-required" | "validation"
  error: Record<string, string>
  phoneNumberRequired?: boolean
  newPassword?: string
  firm?: Firm
  currentUser?: Consumer
  token?: string
}

const { BAD_PASSWORD_RESET_TOKEN, EXPIRED_TOKEN } = AuthErrorReason

export const requestPasswordConfirm = (credentials: PasswordConfirmCredentials) => {
  return async (
    dispatch: Dispatch
  ): Promise<AxiosResponse<RequestPasswordConfirmResponseData> | Error> => {
    dispatch({ type: ActionType.PASSWORD_RESET_CONFIRM_REQUEST })

    try {
      const r = await axios.post(endpoints.resetPasswordConfirm, credentials)
      dispatch({ type: ActionType.PASSWORD_RESET_CONFIRM_SUCCESS })

      if (r?.data?.passwordResetConfirm && !credentials.isCreating) {
        toastNotification("Your password has been successfully reset.", "info")
      }

      if (!r.data) {
        // https://sentry.io/organizations/eligible/issues/2496895242/?project=1381037
        Sentry.captureMessage(
          `Password confirmation got no "data" in response! ${JSON.stringify(r)}`,
          "error"
        )
      }
      return r
    } catch (err) {
      // Ignored statuses are handled
      reportAxiosError(err, {
        ignoreStatuses: [401, 403, 404],
        tags: createTagsFromPrimitives(credentials),
      })
      let errorMessage =
        "Something went wrong. Please contact support if this persists."
      if (axios.isAxiosError(err)) {
        switch (err.response?.status) {
          case 401:
            if (
              [BAD_PASSWORD_RESET_TOKEN, EXPIRED_TOKEN].includes(
                err.response?.data?.errorReason
              )
            ) {
              errorMessage =
                "The link used to access this page is no longer valid. Please request a new one and contact support if this isn't possible."
            } else {
              // If this was a different 401, we do actually want to report it
              reportAxiosError(err, {
                tags: createTagsFromPrimitives(credentials),
              })
            }
            break
          case 403:
          case 404:
            errorMessage =
              "Please use an unexpired link in a reset email to load this page."
            break
        }
      }
      toastNotification(errorMessage, "error")
      dispatch({ type: ActionType.PASSWORD_RESET_CONFIRM_FAILURE })
      if (err instanceof Error) {
        return err
      } else {
        return new Error(`Exception was unknown type: ${err}`)
      }
    }
  }
}

export const setBrandingOptimistic =
  (isColorFinished: boolean, isLogoFinished: boolean) =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: ActionType.SET_BRANDING_OPTIMISTIC,
      isColorFinished,
      isLogoFinished,
    })
  }
