import { LabelPaths } from "../../../../hooks/blocks/util.types"

export const productSelectionListPageBlocks = {
  lender: {
    selection: {
      list: {
        title: {
          type: "text",
          description: "Heading element for the /product-selection/list page",
          default: "Review new deals",
        },
        descriptionContent: {
          type: "text",
          description: "Description content on product-selection list page",
          default:
            "Select the deals you want to explore moving to. You’ll receive mortgage illustrations for your selections. You can request up to [x] illustrations to help you decide which deal to apply for.",
        },
        whatHappens: {
          labelContent: {
            type: "text",
            description: "Label for what happens text link",
            default: "What happens to my payments if I move products?",
          },
        },
        footerButton: {
          labelContent: {
            type: "text",
            description: "Footer button content",
            default: "Continue",
          },
        },
        checkboxLabel: {
          selectedContent: {
            type: "text",
            description: "Checkbox label selected",
            default: "Product selected",
          },
          unselectedContent: {
            type: "text",
            description: "Checkbox label unselected",
            default: "Select this product",
          },
        },
        seeMore: {
          labelContent: {
            type: "text",
            description: "See more button link",
            default: "See more",
          },
        },
        productsAvailable: {
          mdxContent: {
            type: "mdx",
            description: "Content displayed on mortgage panel in heading",
            default: "Our products ({props.totalProducts})",
          },
        },
        mortgage: {
          mdxContent: {
            type: "mdx",
            description:
              'Description displayed on mortgage panel. Available {props} are: props.expiryDate, props.followOnRate, props.numberOfMortgages and props.monthlyFollowOnPayment. Two components can be used: <IfExpired yes="text to display when expired" no="text to display when not expired" /> and <IfMultiple yes="text to display when representing multiple consolidated mortgages" no="text for singular mortgage" />',
            default: `On {props.expiryDate}, your current deal <IfExpired yes="expired" no="expires" /> and you <IfExpired yes="moved" no="will move" /> to <strong>our reversion rate of {props.followOnRate}</strong>. Your monthly payments <IfExpired yes="changed" no="will change" /> to <strong>{props.monthlyFollowOnPayment} / month</strong> as a result of this change.`,
          },
        },
        productsModal: {
          title: {
            type: "text",
            description: "Move products modal title",
            default: "What happens to my payments if I move products?",
          },
          descriptionContent: {
            type: "markdown",
            description: "Move products modal content",
            default:
              `Representative example:\n\n` +
              "A mortgage of £85,000 payable over 19 years, starts on a fixed rate of 4.75% for 5 years.\n\n" +
              "This mortgage would revert to our standard variable rate of 6.50% five years after it starts, for 14 years.\n\n" +
              "This mortgage would require 60 payments of £567 and 168 payments of £631. The total amount payable would be £140,285 made up of the loan amount plus interest (£55,285). The overall cost for comparison is 5.75% APRC.",
          },
        },
        zeroProductsWarning: {
          type: "markdown",
          description:
            "Warning if somehow user has visited this page yet has zero products to view",
          default:
            "**Sorry but no products match your mortgage today.**\n\n" +
            "**Please contact us if you would like to discuss.\n\n**",
        },
      },
    },
  },
} as const

export type ProductSelectionListPaths = LabelPaths<
  typeof productSelectionListPageBlocks
>
