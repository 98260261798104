import * as Sentry from "@sentry/react"

import { runtimeEnv } from "./runtimeEnv"

const devEnv = runtimeEnv.ENV_ENVIRONMENT === "development"
const logLevel = runtimeEnv.ENV_LOG_LEVEL

export default function logSentryBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
  Sentry.addBreadcrumb(breadcrumb)
  if (devEnv && logLevel === "verbose") {
    if (breadcrumb.type !== "error") {
      console.log(breadcrumb.message, breadcrumb)
    } else {
      console.error(breadcrumb.message, breadcrumb)
    }
  }
}
