import * as Sentry from "@sentry/react"

import { ButtonStyle } from "../components/common/Button"
import { useContent_Unstable } from "../hooks/blocks/useContent_Unstable"
import { ContentId } from "../types/retain/Content.types"
import { StylePaths } from "./styleBlocks"

const isButtonStyle = (style: string | null): style is ButtonStyle =>
  style !== null && Object.values<string>(ButtonStyle).includes(style)

const useDefaultButtonStyle = (
  defaultButton: ButtonStyle = ButtonStyle.OUTLINE,
  path: StylePaths = ContentId.DEFAULT_BUTTON_STYLE
) => {
  const { bMaybe } = useContent_Unstable<StylePaths>()
  const buttonStyle = bMaybe(path)
  const validStyle = isButtonStyle(buttonStyle)

  if (!validStyle && buttonStyle !== null && buttonStyle !== "") {
    Sentry.captureMessage(
      `defaultButtonStyle content block was passed an invalid style: ${buttonStyle}`,
      { level: "warning" }
    )
  }

  return validStyle ? buttonStyle : defaultButton
}

export default useDefaultButtonStyle
