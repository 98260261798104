import { createSlice } from "@reduxjs/toolkit"
import { isEqual } from "lodash-es"

import {
  LoadApprovalState,
  Mortgage,
  Property,
} from "../../components/business/LoadApproval/LoadApproval.types"

const initialState = {
  expandedProperties: [],
  expandedMortgages: [],
  properties: [],
} as LoadApprovalState

const checkMortgage = (mortgage: Mortgage) => {
  return mortgage.hasError || mortgage.hasReview || mortgage.hasClassification
}

const checkMortgages = (mortgages: Mortgage[]) => {
  return mortgages.some((mortgage: Mortgage) => checkMortgage(mortgage))
}

const loadApprovalSlice = createSlice({
  name: "loadApproval",
  initialState,
  reducers: {
    setDefaultExpandedProp(state, { payload }) {
      state.expandedProperties = payload.filter((property: Property) =>
        checkMortgages(property.mortgages)
      )
      state.expandedMortgages = state.expandedProperties.flatMap(
        (property: Property) => {
          return property.mortgages
            .filter((mortgage) => checkMortgage(mortgage))
            .map((mortgage) => mortgage.id)
        }
      )

      state.properties = payload
    },
    expandAlertedProp(state) {
      state.expandedProperties = state.properties.filter((property: Property) =>
        checkMortgages(property.mortgages)
      )
      state.expandedMortgages = state.expandedProperties.flatMap(
        (property: Property) => {
          return property.mortgages
            .filter((mortgage) => checkMortgage(mortgage))
            .map((mortgage) => mortgage.id)
        }
      )
    },
    triggerExpandedProp(state, { payload }) {
      if (!state.expandedProperties.some((prop) => isEqual(prop, payload))) {
        state.expandedProperties.push(payload)
      } else {
        state.expandedProperties = state.expandedProperties.filter(
          (prop) => !isEqual(prop, payload)
        )
      }
    },
    triggerExpandedMortgage(state, { payload }) {
      if (!state.expandedMortgages.includes(payload)) {
        state.expandedMortgages.push(payload)
      } else {
        state.expandedMortgages = state.expandedMortgages.filter((id) => id !== payload)
      }
    },
  },
})

export const {
  setDefaultExpandedProp,
  triggerExpandedProp,
  triggerExpandedMortgage,
  expandAlertedProp,
} = loadApprovalSlice.actions

export default loadApprovalSlice.reducer
