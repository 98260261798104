export const seeOptionsBoxInformedConsumersBlocks = {
  seeOptionsBox: {
    body: {
      type: "markdown",
      description:
        "The body for the See Options box on Current Situation page (Informed Consumers Exp 1)",
      default:
        "Explore a range of solutions for your mortgage needs. Click ‘See Options’ to discover various pathways and support",
    },
    button: {
      type: "text",
      description:
        "The button label for the See Options box on Current Situation page (Informed Consumers Exp 1)",
      default: "See options",
    },
  },
}
