import dayjs from "dayjs"
import "./dayjsConfig"

export function currency(amount?: number) {
  if (typeof amount === "number") {
    return amount.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP",
    })
  }
  return ""
}

export function currencyWholePounds(amount?: number) {
  if (typeof amount === "number") {
    return Math.ceil(amount).toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 0,
    })
  }
  return ""
}

export function percent(amount?: number) {
  if (typeof amount === "number") {
    return amount.toLocaleString("en-GB", {
      style: "percent",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
  }
  return ""
}

export function percentDecimal(amount?: number | null) {
  if (typeof amount === "number") {
    return (amount / 100).toLocaleString("en-GB", {
      style: "percent",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
  }
  return ""
}

export function roundedInteger(amount?: number | null) {
  if (typeof amount === "number") {
    return Math.round(amount)
  }
  return ""
}

export function pluralize(
  unit: string,
  count: number,
  pluralFn = (s: string): string => `${s}s`
) {
  return count === 1 ? unit : pluralFn(unit)
}

export function capitalize(s: string): string {
  if (s) {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  return ""
}

export function numberToOrdinal(n: 0 | null | number): string | undefined {
  if (n === null || n < 0) return undefined

  const modTen = n % 10
  const modHundred = n % 100
  const isTeen = modHundred > 10 && modHundred < 20

  let suffix = "th"

  if (!isTeen) {
    switch (modTen) {
      case 1:
        suffix = "st"
        break
      case 2:
        suffix = "nd"
        break
      case 3:
        suffix = "rd"
        break
      default:
        suffix = "th"
    }
  }

  return `${n}${suffix}`
}

export function camelToKebabCase(str: string) {
  if (str) {
    return str.replace(/[A-Z]/g, (x) => `-${x.toLowerCase()}`)
  }
  return ""
}

export function listFromArray<T>(array: T[]): string {
  return capitalize(
    array.reduce((acc, current, index) => {
      return index === 0 ? `${current}` : `${acc}, ${current}`
    }, "")
  )
}

export function dateSlashToHypen(value: string): string {
  if (value) {
    const [day, month, year] = value.split("/")
    return dayjs([
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10),
    ]).format("YYYY-MM-DD")
  }
  return ""
}
