import { SerializedError } from "@reduxjs/toolkit"

function isRtkQueryError(err: unknown): err is SerializedError {
  return (
    typeof err === "object" &&
    err !== null &&
    "code" in err &&
    "message" in err &&
    "name" in err
  )
}

export default isRtkQueryError
