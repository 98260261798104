const productAlertContentMdxDefault = `
You will still receive factual information and notifications about products and services that we provide to you. If you are no longer interested in receiving property updates {props.isAuth ? <Link to={props.authedLink}>please let us know here.</Link> : <Link to={props.notAuthedLink}>login to let us know.</Link>}
`

export const unsubscribePageBlocks = {
  unsubscribeContactDetails: {
    image: {
      type: "image",
      description:
        "The image that appears to the left of the email and phone number, in the Contact Details section at the top of the Unsubscribe page",
      default: null,
    },
    title: {
      type: "text",
      description:
        "The title that goes above the email and phone number, in the Contact Details section at the top of the Unsubscribe page",
      default: "Contact details",
    },
  },
  unsubscribeContactDetailsAuth: {
    type: "text",
    description:
      "The title of the Unsubscribe page main content for authenticated users <!> We should rename this to unsubscribeContactPreferencesAuth",
    default: "Contact preferences",
  },
  unsubscribeContactDetailsNoAuth: {
    type: "text",
    description:
      "The title of the Unsubscribe page main content for ident users (coming from email) <!> We should rename this to unsubscribeContactPreferencesNoAuth",
    default: "Are you sure you want to unsubscribe?",
  },
  unsubscribeInfoBox: {
    type: "markdown",
    description:
      "The alert that appears once the user makes a change, reminding the user to save",
    default: "Don’t forget to hit ‘save preferences’ to submit your changes",
  },
  unsubscribeProductAlert: {
    title: {
      type: "text",
      description:
        "The title of the card that appears at the bottom of the Unsubscribe page which contains a link to the Not Interested page",
      default: "Product alerts",
    },
    content: {
      type: "mdx",
      description:
        "The contents of the card that appears at the bottom of the Unsubscribe page which contains a link to the Not Interested page",
      default: productAlertContentMdxDefault,
    },
  },
  unsubscribeSubmitButton: {
    type: "text",
    description:
      "The text that appears on the button that submits the user's changes on the Unsubscribe page",
    default: "Save preferences",
  },
  unsubscribeToAll: {
    type: "text",
    description:
      "The clickable text that sets all the toggles to 'off' on the Unsubscribe page",
    default: "Unsubscribe from all",
  },
  subscribeToAll: {
    type: "text",
    description:
      "The clickable text that sets all the toggles to 'on' on the Unsubscribe page",
    default: "Subscribe to all",
  },
  unsubscribeToastMdx: {
    // Normally we'd use `type: "mdx"` for Content IDs with MDX in the name, but this is a special case where we only need the {props.unsubscribeList} value and replace it below
    type: "text",
    description:
      "The text in the toast that appears when the user submits a change on the Unsubscribe page and is unsubscribed from at least one item on the list",
    default:
      "Your preferences have been updated. Thanks, we won't send you any more emails about {props.unsubscribeList}",
  },
  noUnsubscribeToast: {
    type: "text",
    description:
      "The text in the toast that appears when the user submits a change on the Unsubscribe page is subscribed to all items on the list",
    default: "Your preferences have been updated.",
  },
} as const
