import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/react"
import axios from "axios"

import { setLocalStorageItem, setSessionStorageItem } from "../../lib/localStorageUtil"
import type { AppStartListening } from "../store"
import {
  updateSession,
  clearSession,
  SESSION_KEY,
  LONGTERM_KEY,
  currentSituationBeenExpanded,
  hideApprovalSubmitMsg,
} from "./persistantSession"

export const persistantSessionListener = createListenerMiddleware()
// It's impossible for .getState() typing to be known ahead of time so we cast here.
// It's the recommended approach. https://redux-toolkit.js.org/api/createListenerMiddleware#typescript-usage
const startSessionListening =
  persistantSessionListener.startListening as AppStartListening

startSessionListening({
  matcher: isAnyOf(
    updateSession,
    clearSession,
    currentSituationBeenExpanded,
    hideApprovalSubmitMsg
  ),
  effect: (action, listenerApi) => {
    const authToken = listenerApi.getState().persistant.session.authToken
    if (authToken) {
      axios.defaults.headers.common.Authorization = `Token ${authToken}`
    } else {
      delete axios.defaults.headers.common.Authorization
    }
    const sessionId = listenerApi.getState().persistant.session.id
    if (sessionId) {
      axios.defaults.headers.common["X-Session-Id"] = sessionId
    } else {
      delete axios.defaults.headers.common["X-Session-Id"]
    }
    try {
      const session = listenerApi.getState().persistant.session
      if (
        action.type === "persistant/clearSession" ||
        Object.values(session).filter((v) => v !== undefined).length
      ) {
        setSessionStorageItem(SESSION_KEY, JSON.stringify(session))
      }
    } catch (err) {
      Sentry.addBreadcrumb({
        message: `JSON for session could not be built: ${err}`,
        type: "info",
        level: "error",
      })
    }
    try {
      const longterm = listenerApi.getState().persistant.longterm
      if (
        action.type === "persistant/clearSession" ||
        Object.values(longterm).filter((v) => v !== undefined).length
      ) {
        setLocalStorageItem(LONGTERM_KEY, JSON.stringify(longterm))
      }
    } catch (err) {
      Sentry.addBreadcrumb({
        message: `JSON for longterm could not be built: ${err}`,
        type: "info",
        level: "error",
      })
    }
  },
})
