import ActionType from "../../../constants/actionTypes"
import { CtaPromptStore } from "../../../types/retain"

export const initialState: CtaPromptStore = {
  isLoading: false,
  errorMessage: undefined,
  dismissed: false,
  settings: undefined,
}

type RequestAction = {
  type: ActionType.CTA_SETTINGS_REQUEST
}

type SuccessAction = {
  type: ActionType.CTA_SETTINGS_SUCCESS
  settings: CtaPromptStore["settings"]
}

type FailureAction = {
  type: ActionType.CTA_SETTINGS_FAILURE
  errorMessage: CtaPromptStore["errorMessage"]
}

type DismissRequestAction = {
  type: ActionType.CTA_DISMISS_REQUEST
}

type DismissSuccessAction = {
  type: ActionType.CTA_DISMISS_SUCCESS
}

type DismissFailureAction = {
  type: ActionType.CTA_DISMISS_FAILURE
  errorMessage: CtaPromptStore["errorMessage"]
}

export type Action =
  | RequestAction
  | SuccessAction
  | FailureAction
  | DismissRequestAction
  | DismissSuccessAction
  | DismissFailureAction

export default function (
  state: CtaPromptStore = initialState,
  action: Action
): CtaPromptStore {
  switch (action.type) {
    case ActionType.CTA_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: undefined,
      }

    case ActionType.CTA_SETTINGS_SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        errorMessage: undefined,
        settings: action.settings,
      }

    case ActionType.CTA_SETTINGS_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isLoading: false,
      }

    case ActionType.CTA_DISMISS_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: undefined,
      }

    case ActionType.CTA_DISMISS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: undefined,
        dismissed: true,
      }

    case ActionType.CTA_DISMISS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      }

    default:
      return state
  }
}
