import { LabelPaths } from "../../../hooks/blocks/util.types"

export const getHelpPageBlocks = {
  lender: {
    getHelp: {
      headerTitle: {
        type: "text",
        description: "Heading element for the /get-help/ page",
        default: "Get help",
      },
      heroImage: {
        type: "image",
        description:
          "Hero image for the /get-help/ page. Fallback icon is a tick within a circle.",
        default: null,
      },
      introContent: {
        type: "markdown",
        description: "Copy displayed on the /get-help/ page as the intro content.",
        default: null,
      },
      urlButton: {
        labelContent: {
          type: "text",
          description: "URL button label for  /get-help/ page ",
          default: "Get help",
        },
      },
      upperBodyContent: {
        type: "markdown",
        description: "Copy displayed on the /get-help/ page as the upper body content.",
        default: null,
      },
      lowerBodyContent: {
        type: "markdown",
        description: "Copy displayed on the /get-help/ page as the lower body content.",
        default: null,
      },
      codeLowerBodyContent: {
        type: "markdown",
        description: "Code displayed on the /get-help/ page as the lower body content.",
        default: null,
      },
    },
  },
} as const

export type GetHelpLabelPaths = LabelPaths<typeof getHelpPageBlocks>
