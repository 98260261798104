export const productRatesPageBlocks = {
  productRatesModuleTitle: {
    type: "text",
    description:
      "Label that displays inside the Product Rate Info Card on the Lifecycle Home page, and the top of modal that it opens",
    default: "Mortgage Rates",
  },
  productRatesModuleAlertCard: {
    type: "text",
    description:
      "The label inside the clickable Product Rate alerts cards at /property/:propertyId",
    default: "Mortgage rates",
  },
  productRatesModulePageHeading: {
    type: "text",
    description:
      "Heading element for the Product Rates page at /product-rates/:propertyId/:mortgageId",
    default: "Mortgage rates",
  },
  productRatesNoProductsFoundAlert: {
    type: "text",
    description:
      "The alert that displays below the Current Payment card and above the disclaimer on the Product Rates page when no products are found",
    default:
      "No similar products were found. You will receive an update alerting you about a comparable deal.",
  },
  productRatesDisclaimerBalanceMissingMdx: {
    type: "mdx",
    description:
      "Paragraph at the bottom of the Product Rates page when the currentLoanAmount on the mortgage is null or undefined",
    default: `<p><b>Speak to your adviser to confirm products and rates available to you.</b></p><p>Estimated payments are calculated based on your current remaining term and an estimated remaining mortgage amount as of today. The estimated remaining mortgage amount assumes you have made no extra payments on your mortgage.</p><p>The rate used above is based on a {props.initialTerm} year product, using comparable terms to your existing mortgage, available as at {props.validAt}. This rate is for illustrative purposes only, as you may not be eligible for this product.</p>`,
  },
  productRatesDisclaimerBalancePresentMdx: {
    type: "mdx",
    description:
      "Paragraph at the bottom of the Product Rates page when the currentLoanAmount on the mortgage is a numerical value",
    default: `<p><b>Speak to your adviser to confirm products and rates available to you.</b></p><p>Estimated payments are calculated based on your current remaining term and an estimated remaining mortgage amount of {props.currentLoanAmount}. The estimated remaining mortgage amount assumes you have made no extra payments on your mortgage.</p><p>The rate used above is based on a {props.initialTerm} year product, using comparable terms to your existing mortgage, available as at {props.validAt}. This rate is for illustrative purposes only, as you may not be eligible for this product.</p>`,
  },
  productRatesModalMonthlyCostOfSwitching: {
    title: {
      type: "text",
      description:
        "The title of the modal that opens when clicking the info icon for Monthly Cost of Switching",
      default: "Monthly cost of switching",
    },
    content: {
      type: "markdown",
      description:
        "The content of the modal that opens when clicking the info icon for Monthly Cost of Switching",
      default:
        "There are fees to pay when switching mortgage. We divide the total fees by the number of months in your initial term, to illustrate the full costs (and potential savings) of deals.\n" +
        "You may be able to pay this upfront, instead of adding it to your loan.",
    },
  },
  productRatesModalEarlyRepaymentCharges: {
    title: {
      type: "text",
      description:
        "The title of the modal that opens when clicking the info icon for Early Repayment Charges",
      default: "Early Repayment Charges",
    },
    content: {
      type: "mdx",
      description:
        "The content of the modal that opens when clicking the info icon for Early Repayment Charges",
      default: `
    These are fees that you may have to pay your lender to end your deal before your initial term ends. We estimate ERC charges as a percentage of your current outstanding balance of {props.balance}. This percentage is typically 5-1%, decreasing the closer you are to the end of your initial term.
    `,
    },
  },
  productRatesModalLenderArrangementFees: {
    title: {
      type: "text",
      description:
        "The title of the modal that opens when clicking the info icon for Lender Arrangement Fees",
      default: "Lender arrangement fees",
    },
    content: {
      type: "markdown",
      description:
        "The content of the modal that opens when clicking the info icon for Lender Arrangement Fees",
      default:
        "These are the arrangement fees that you may have to pay a lender to set up a new mortgage. These fees are lender and product specific. We estimate lender arrangement fees based on the information related to the product (and rate) illustrated. Arrangement fees can be zero, a percentage of the loan (usually up to 1%) or a fixed amount.",
    },
  },
  productRatesModalLegalFees: {
    title: {
      type: "text",
      description:
        "The title of the modal that opens when clicking the info icon for Legal Fees",
      default: "Legal fees",
    },
    content: {
      type: "markdown",
      description:
        "The content of the modal that opens when clicking the info icon for Legal Fees",
      default:
        "These are the conveyancing costs for refinancing your mortgage. Fees are estimated based on current market averages.",
    },
  },
  productRatesModalAprc: {
    title: {
      type: "text",
      description:
        "The title of the modal that opens when clicking the info icon for APRC",
      default: "APRC",
    },
    content: {
      type: "markdown",
      description:
        "The content of the modal that opens when clicking the info icon for APRC",
      default:
        "APRC (or Annual Percentage Rate of Charge) is the percentage rate which your loan will cost you each year over the full mortgage term including all charges such as administration fees, valuation fees and booking fees at the start of the mortgage. The APRC should always be quoted to you when you make a mortgage application.",
    },
  },
  productRatesEstimatedSwitching: {
    type: "text",
    description: `Additional information about the estimated switching costs that displays in the "Estimated new payment" card on the Product Rates page when products are found`,
    default: "(Includes ERCs based on market averages)",
  },
  productRatesModalEarlyRepaymentChargesInfoLabel: {
    type: "text",
    description: `The label for the Early Repayment Charges info icon in the "Example breakdown" for when products are found`,
    default: "Early Repayment Charges",
  },
  productRatesModalLegalFeesInfoLabel: {
    type: "text",
    description: `The label for the Legal Fees info icon in the "Example breakdown" for when products are found`,
    default: "Legal fees",
  },
  productRatesIfEligible: {
    type: "markdown",
    description:
      "The text underneath the monthly savings display on the Product Rates page when there are better deals available",
    default: "Speak to your adviser to confirm products and savings available to you.",
  },
  productRatesBestDeal: {
    type: "text",
    description:
      "The text on the Product Rates page if you're currently on the best deal, underneath the count of products searched",
    default: "Looks good. No deals to review right now.",
  },
  productRatesModalContentPage1: {
    title: {
      DEPRECATED_id: "productRatesModalContentPage1",

      type: "text",
      description: "The title of the first page of the Product Rates info modal",
      default: "Mortgage rates explained",
    },
    content: {
      DEPRECATED_id: "productRatesModalContentPage1",

      type: "markdown",
      description: "The content of the first page of the Product Rates info modal",
      default:
        "New product rates become available daily. Some may be low enough to cover costs of changing products and reducing your monthly payments.",
    },
  },
  productRatesModalContentPage2: {
    title: {
      DEPRECATED_id: "productRatesModalContentPage2",

      type: "text",
      description: "The title of the second page of the Product Rates info modal",
      default: "How does this impact my mortgage?",
    },
    content: {
      DEPRECATED_id: "productRatesModalContentPage2",

      type: "markdown",
      description: "The content of the second page of the Product Rates info modal",
      default:
        "A new product becoming available is one of many factors that determine whether you can move to a lower rate or save money. Competition among lenders, your credit history, and LTV ratio also have a role.",
    },
  },
  productRatesModalContentPage3: {
    title: {
      DEPRECATED_id: "productRatesModalContentPage3",

      type: "text",
      description: "The title of the third page of the Product Rates info modal",
      default: "Why do we track it?",
    },
    content: {
      DEPRECATED_id: "productRatesModalContentPage3",

      type: "markdown",
      description: "The content of the third page of the Product Rates info modal",
      default:
        "We have to consider the range of rates available. Plus, how many products are similar to yours. We also have to include any fees (for example, early repayment charges) that affect the overall cost.",
    },
  },
} as const
