/**
 * Simple retry with exponential backoff function to attempt a promise,
 * then try again with an increasing interval of time until finally failing.
 * @param targetFunction Should return a promise to handle
 * @param retriesLeft Number of attempts to try
 * @param attempts Used to handle the backoff, no need to pass
 * @returns a Promise that will resolve eventually or fail after a number of retries
 */
export default function retry<T = unknown>(
  targetFunction: () => Promise<T>,
  retriesLeft = 3,
  attempts = 0
) {
  return new Promise<T>((resolve, reject) => {
    targetFunction()
      .then(resolve)
      .catch((error: unknown) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error)
            return
          }
          retry(targetFunction, retriesLeft - 1, attempts + 1).then(resolve, reject)
        }, 2 ** attempts * 100)
      })
  })
}
