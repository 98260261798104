import dayjs from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"
import customParseFormat from "dayjs/plugin/customParseFormat"
import duration from "dayjs/plugin/duration"
import minMax from "dayjs/plugin/minMax"
import objectSupport from "dayjs/plugin/objectSupport"
import relativeTime from "dayjs/plugin/relativeTime"
import updateLocale from "dayjs/plugin/updateLocale"

const config = {
  thresholds: [
    { l: "s", r: 1 },
    { l: "m", r: 1 },
    { l: "mm", r: 59, d: "minute" },
    { l: "h", r: 1 },
    { l: "hh", r: 23, d: "hour" },
    { l: "d", r: 1 },
    { l: "dd", r: 25, d: "day" },
    { l: "M", r: 1 },
    { l: "MM", r: 11, d: "month" },
    { l: "y", r: 1 },
    { l: "yy", d: "year" },
  ],
}

dayjs.extend(updateLocale)
dayjs.extend(relativeTime, config)
dayjs.extend(arraySupport)
dayjs.extend(customParseFormat)
dayjs.extend(minMax)
dayjs.extend(duration)
dayjs.extend(objectSupport)
