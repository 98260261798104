import buttonTrackingIds from "../../../constants/buttonTrackingIds"
import Info from "../../../images/Info.svg?react"
import Play from "../../../images/Play.svg?react"
import { currencyWholePounds, percent } from "../../../lib/formatters"
import { Mortgage } from "../../../types/retain/Mortgages.types"
import Button, { ButtonStyle } from "../Button"
import s from "./MortgageOverview.module.scss"

type InitialPaymentsProps = {
  mortgage: Mortgage | null
  isPortal?: boolean
  owned?: boolean
  pronoun: string
}

const InitialPayments = ({
  mortgage,
  isPortal,
  owned,
  pronoun,
  openMortgageInfo,
}: InitialPaymentsProps & {
  openMortgageInfo: () => void
}) => {
  // to retain some of the logic around the skeleton, we had to add this to catch mortgages now that they are a truly required field
  if (mortgage === null) return null

  return (
    <>
      <h3 className={s.title}>
        <Play className={s.titleIcon} />
        {pronoun} initial term
      </h3>
      <div className={s.preferentialTable}>
        <table>
          <thead>
            <tr>
              <th>Monthly payment</th>
              <th>
                {isPortal
                  ? `${mortgage.expired ? "Previous" : "Current"} rate`
                  : "Initial interest rate"}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className={s.preferentialPayment}>
                  {currencyWholePounds(mortgage.monthlyPreferentialPayment)}
                </span>
              </td>
              <td className={s.preferentialRateContainer}>
                <span className={s.preferentialRate}>
                  {percent(mortgage.preferentialRate / 100)}
                </span>
                {owned && (
                  <Button
                    className={s.mortgageInfoButton}
                    style={ButtonStyle.NONE}
                    trackingId={buttonTrackingIds.MORTGAGE_OVERVIEW_MORE_INFO}
                    onClick={openMortgageInfo}
                  >
                    <Info />
                  </Button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default InitialPayments
