import { CSSProperties } from "react"

import s from "./LoadingIndicator.module.scss"

type LoadingIndicatorProps = {
  color?: string
}

const LoadingIndicator = ({ color }: LoadingIndicatorProps) => {
  return (
    <div
      className={s.spinner}
      style={{ "--primary-color": color } as CSSProperties}
      aria-label="loading indicator"
    >
      <div className={s.bounce1} />
      <div className={s.bounce2} />
      <div className={s.bounce3} />
    </div>
  )
}

export default LoadingIndicator
