import { LabelPaths } from "../../../hooks/blocks/util.types"

export const learnMoreBlocks = {
  learn: {
    complete: {
      heading: {
        type: "text",
        description: "Heading for the Learn More complete page",
        default: "You have completed all topics",
      },
      copy: {
        type: "text",
        description: "Main copy for the Learn More complete page",
        default: `You're now well-informed about your mortgage options and should be ready to
          make a decision on your mortgages. Click 'see options' to let us know what
          you'd like to do next.`,
      },
    },
  },
} as const

export type LearnMorePaths = LabelPaths<typeof learnMoreBlocks>
