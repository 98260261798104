import { createApi } from "@reduxjs/toolkit/query/react"

import { AuthErrorReason } from "../../../../constants/authErrorReasons"
import {
  PRETTY_ACTIVITY_ID,
  PRETTY_CONSUMER_ID,
} from "../../../../lib/reportAxiosError"
import axiosBaseQuery from "../../../../redux/services/axiosBaseQuery"
import { ActivityDetail } from "../userActivityTypes"

const { EXPIRED_TOKEN } = AuthErrorReason

export const detailActivityApi = createApi({
  reducerPath: "detailActivityApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  endpoints: (builder) => ({
    getActivityDetail: builder.query<
      ActivityDetail,
      { consumerId: string; activityId: string }
    >({
      query: ({ consumerId, activityId }) => ({
        url: `/consumer/${consumerId}/activity/${activityId}`,
        reportContext: {
          ignoreStatuses: [{ code: 401, reasons: [EXPIRED_TOKEN] }],
          prettyURL: `/consumer/${PRETTY_CONSUMER_ID}/activity/${PRETTY_ACTIVITY_ID}`,
        },
      }),
    }),
  }),
})

export const { useGetActivityDetailQuery } = detailActivityApi
