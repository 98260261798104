import { LabelPaths } from "../../../hooks/blocks/util.types"

export const kbaLoginBlocks = {
  kbaLogin: {
    headerContent: {
      type: "text",
      description: "Optional heading element for the KBA Login",
      default: null,
    },
    introMarkdownContent: {
      type: "markdown",
      description: "Optional intro for the KBA Login",
      default: null,
    },
    submitButton: {
      fillContent: {
        type: "text",
        description: "Fill colour for the KBA login submit button",
        default: "primary",
      },
      submittingContent: {
        type: "text",
        description: "In progress label when submitting KBA Login",
        default: "Verifying identity...",
      },
      labelContent: {
        type: "text",
        description: "KBA Login submit button label",
        default: "Verify identity",
      },
    },
    accountNumber: {
      infoBox: {
        labelContent: {
          type: "text",
          description: "Label for account number info box",
          default: null,
        },
        titleContent: {
          type: "text",
          description: "Title for account number info box",
          default: null,
        },
        bodyContent: {
          type: "markdown",
          description: "Body for account number info box",
          default: null,
        },
      },
    },
    monthlyPayment: {
      infoBox: {
        labelContent: {
          type: "text",
          description: "Label for monthly payment info box",
          default: null,
        },
        titleContent: {
          type: "text",
          description: "Title for monthly payment info box",
          default: null,
        },
        bodyContent: {
          type: "markdown",
          description: "Body for monthly payment info box",
          default: null,
        },
      },
    },
  },
} as const

export type KbaLoginPaths = LabelPaths<typeof kbaLoginBlocks>
