import { LabelPaths } from "./blocks/util.types"

export const lenderOptionsBlocks = {
  lender: {
    notInterestedLabel: {
      advisorContent: {
        type: "text",
        description: "Not interested lender option for advisor",
        default: "Using an adviser",
      },
      soldContent: {
        type: "text",
        description: "Not interested lender option for sold",
        default: "Selling property",
      },
      differentContent: {
        type: "text",
        description: "Not interested lender option for different lender",
        default: "Using another lender",
      },
      notSwitchingContent: {
        type: "text",
        description: "Not interested lender option for not switching",
        default: "Staying on Follow-on Rate",
      },
      preferContent: {
        type: "text",
        description: "Not interested lender option for prefer not to say",
        default: "Prefer not to say",
      },
    },
  },
} as const

export type LenderOptionsPaths = LabelPaths<typeof lenderOptionsBlocks>
