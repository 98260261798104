import s from "./Loading.module.scss"

const Loading = () => (
  <div className={s.loading} aria-label="Loading">
    <div />
    <div />
    <div />
    <div />
  </div>
)

export default Loading
