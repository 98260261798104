import dayjs from "dayjs"

import useMdxContent from "../../hooks/mdx/useMdxContent"
import formatInitialTerm from "../../lib/formatInitialTerm"
import { useAppSelector } from "../../redux/store"
import "../../lib/dayjsConfig"
import { CurrentSituationContentId } from "../../types/retain/Content.types"
import { loanPurpose, Mortgage } from "../../types/retain/Mortgages.types"
import { CurrentSituationPagePaths } from "../consumer/LenderExpiryPage/currentSituationPageBlocks"

interface Props {
  mortgage: Mortgage
  owned?: boolean
  isOverSixMonths?: boolean
}

/**
 * The preamble text to describe the time remaining, time elapsed since expiry, or the total term on a mortgage.
 * Used on the Current Situation page for Lender Consumers.
 * Used on the Expiry Date page for Broker Lifecycle Consumers, on each mortgage panel.
 * Used on the Business-Side Leads page next to the mortgage details.
 *
 * @param {Mortgage} mortgage - The mortgage to display the preamble for.
 * @param {boolean} owned - Whether the mortgage is owned by the user. Defaults to false.
 * @param {boolean} isOverSixMonths - Whether the mortgage is over six months old. Defaults to true.
 * @param {Dayjs} now - The current date and time as a Dayjs instance, should only be set in tests. Defaults to dayjs().
 */
const CurrentSituationPreamble = ({
  mortgage,
  owned = false,
  isOverSixMonths = true,
}: Props) => {
  const isPortal = useAppSelector((state) => state.auth.firm?.isPortal)

  const isAdditionalMortgage =
    mortgage.loanPurpose === loanPurpose.FURTHER_ADVANCE ||
    mortgage.loanPurpose === loanPurpose.ADDITIONAL_BORROWING

  if (isPortal) {
    return <LenderPreamble mortgage={mortgage} owned={owned} />
  }

  const startOfToday = dayjs().startOf("day")

  if (mortgage.expired) {
    return (
      <p>
        The initial rate of {owned ? "your" : "their"}
        {isAdditionalMortgage && " additional"} mortgage{" "}
        {mortgage?.lender && mortgage.lender.name ? (
          <>with {mortgage.lender.name} </>
        ) : (
          ""
        )}
        has expired.
        {owned ? " You've" : " They've"} now been paying a higher rate for{" "}
        {dayjs(mortgage.expiryDate).from(startOfToday, true)}.
      </p>
    )
  }

  if (isOverSixMonths) {
    return (
      <p>
        {owned ? "Your" : "Their"}
        {mortgage?.lender && mortgage.lender.name ? <> {mortgage.lender.name} </> : ""}
        mortgage has an initial term of{" "}
        {formatInitialTerm(mortgage.expiryDate, mortgage.startDate)}. The initial term
        ends {dayjs(mortgage.expiryDate).from(startOfToday)}.
      </p>
    )
  }

  return (
    <p>
      {owned ? "Your" : "Their"}
      {mortgage?.lender && mortgage.lender.name ? <> {mortgage.lender.name} </> : ""}
      mortgage has an initial term of{" "}
      {formatInitialTerm(mortgage.expiryDate, mortgage.startDate)}.
    </p>
  )
}

function LenderPreamble({ mortgage, owned }: Props) {
  const contentIdPath = mortgage.expired
    ? CurrentSituationContentId.PREAMBLE_EXPIRED_MORTGAGE_MDX
    : CurrentSituationContentId.PREAMBLE_ACTIVE_MORTGAGE_MDX

  const startOfToday = dayjs().startOf("day")
  const expiryDayjs = mortgage.expiryDate
  const expiresToday = dayjs(expiryDayjs).isSame(startOfToday, "day")

  const humanizedDuration = expiresToday
    ? "today"
    : dayjs(expiryDayjs).from(startOfToday)

  const content = useMdxContent<CurrentSituationPagePaths>(contentIdPath, {
    pronounLowercase: owned ? "your" : "their",
    pronounCapitalized: owned ? "Your" : "Their",
    expiryDate: humanizedDuration,
  })

  return <>{content}</>
}

export default CurrentSituationPreamble
