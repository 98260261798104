export const propertyHealthBlocks = {
  propertyHealthCardAlertChange: {
    type: "text",
    description:
      "The text inside the alert badge in the property health card when there is a change",
    default: "Review now",
  },
  propertyHealthCardBodyChange: {
    type: "markdown",
    description:
      "The main content below the alert badge in the property health card when there is a change",
    default: "Changes found that may affect you.",
  },
  propertyHealthCardCtaChange: {
    type: "text",
    description:
      "The button label text in the property health card when there is a change",
    default: "View",
  },
  propertyHealthCardAlertNoChange: {
    type: "text",
    description:
      "The text inside the alert badge in the property health card when there is no change",
    default: "No change",
  },
  propertyHealthCardBodyNoChange: {
    type: "markdown",
    description:
      "The main content below the alert badge in the property health card when there is no change",
    default: "Everything looks good! Nothing to review today.",
  },
  propertyHealthCardCtaNoChange: {
    type: "text",
    description:
      "The button label text in the property health card when there is no change",
    default: "View",
  },
  propertyHealthCardAlertSnooze: {
    type: "text",
    description:
      "The text inside the alert badge in the property health card when the property is snoozed",
    default: "In progress",
  },
  propertyHealthCardBodySnooze: {
    type: "markdown",
    description:
      "The main content below the alert badge in the property health card when the property is snoozed",
    default:
      "We're working on mortgages associated with this property following your recent call.",
  },
  propertyHealthCardAlertUnableToCheck: {
    type: "text",
    description:
      "The text inside the alert badge in the property health card when property checking is unavailable",
    default: "Property checking unavailable",
  },
  propertyHealthCardBodyUnableToCheck: {
    type: "markdown",
    description:
      "The main content below the alert badge in the property health card when property checking is unavailable",
    default: "Please contact your broker for updates to this property.",
  },
} as const
