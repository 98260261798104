import { LabelPaths } from "../../blocks/util.types"

export const infoBoxesBlocks = {
  switch: {
    accountNumber: {
      infoBoxTitle: {
        type: "text",
        description: "Title element for Account number info box",
        default: "Account number",
      },
      infoBoxBody: {
        type: "text",
        description: "Body element for Account number info box",
        default:
          "You'll find this on your mortgage maturity letter or your most recent mortgage statement",
      },
    },
  },
} as const

export type InfoBoxesLabelPaths = LabelPaths<typeof infoBoxesBlocks>
