import { Lists, ListsStore, listTypes } from "../../../types/retain/List.types"

const approvalCheckList = [listTypes.APPROVAL, listTypes.APPROVAL_INTERNAL]
const alertsChecklist = [
  listTypes.ALL_LEADS,
  listTypes.CALL_LIST,
  listTypes.MY_LEADS,
  listTypes.MEETINGS_BOOKED,
  listTypes.ORPHANS,
  listTypes.URGENT,
]

function nullifyZeros(lists: Lists) {
  return lists.map((list) => ({
    ...list,
    alert: list.alert === 0 ? null : list.alert,
  }))
}

function filterAndSum(filterList: string[], lists: Lists) {
  return lists
    .filter((list) => filterList.includes(list.name))
    .reduce((acc, curr) => (curr.alert ? acc + curr.alert : acc), 0)
}

function transformListsResponse(lists: Lists): ListsStore {
  const total = filterAndSum(alertsChecklist, lists)
  const totalApproval = filterAndSum(approvalCheckList, lists)
  return {
    detail: nullifyZeros(lists),
    totalAlerts: total === 0 ? null : total,
    totalApproval: totalApproval === 0 ? null : totalApproval,
  }
}

export default transformListsResponse
