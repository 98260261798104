import { ReactNode } from "react"
import { useHistory } from "react-router"

import Button from "../../../components/common/Button"
import { ButtonTrackingId } from "../../../constants/buttonTrackingIds"

type LinkProps = {
  path: string
  trackingId?: ButtonTrackingId
  children?: ReactNode
}

export default function LinkButton({ path, trackingId, children }: LinkProps) {
  const history = useHistory()
  return (
    <Button
      onClick={() => {
        history.push(path)
      }}
      trackingId={trackingId}
    >
      {children}
    </Button>
  )
}
