import { createApi } from "@reduxjs/toolkit/dist/query/react"

import { AuthErrorReason } from "../../../constants/authErrorReasons"
import axiosBaseQuery from "../axiosBaseQuery"

const { EXPIRED_TOKEN } = AuthErrorReason

export const notInterestedApi = createApi({
  reducerPath: "notInterestedApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),

  endpoints: (builder) => ({
    getNumOfMortgages: builder.query<
      { numMortgages: number },
      { userId?: string; token?: string }
    >({
      query: ({ userId, token }) => ({
        url: `/mortgage/num-mortgages/`,
        params: {
          user_id: userId,
          token,
        },
        reportContext: {
          ignoreStatuses: [{ code: 401, reasons: [EXPIRED_TOKEN] }],
        },
      }),
    }),
    postIdentNotInterested: builder.mutation({
      query: ({ userId, token, mortgageId, category }) => ({
        url: `/not-interested/`,
        method: "POST",
        requestData: {
          user_id: userId,
          token,
          mortgageId,
          category,
        },
      }),
    }),
  }),
})

export const { useGetNumOfMortgagesQuery, usePostIdentNotInterestedMutation } =
  notInterestedApi
