import { isObject, isBoolean, isNumber } from "lodash-es"

/**
 * Creates a tags object to be passed to Sentry.captureException CaptureContext tags param
 * We don't want to include PII or tokens etc so all values are stripped down to booleans
 * and prefixed with "had" if they weren't boolean to start with
 * @param values Primitve values will be used, Objects will be ignored and stripped
 * @returns tags object with type Record<string, boolean> or undefined if no tags were present
 */
export default function createTagsFromPrimitives(values: Record<string, unknown>) {
  const booleanTags = Object.entries(values).reduce<Record<string, boolean>>(
    (tags, [key, value]) => {
      if (!isObject(value)) {
        if (isBoolean(value)) {
          return { ...tags, [key]: value }
        }
        const titleCasedKey = key[0].toUpperCase() + key.substring(1)
        const isNumberValue = isNumber(value) && !Number.isNaN(value)
        return { ...tags, [`had${titleCasedKey}`]: isNumberValue || Boolean(value) }
      }
      return tags
    },
    {}
  )
  if (Object.entries(booleanTags).length) {
    return booleanTags
  }
  return undefined
}
