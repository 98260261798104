export const DATE_FORMAT = "DD/MM/YYYY"
export const TIME_FORMAT = "HH:mm"
export const EMAIL_POP_IN = "email"
export const TABLET_BREAKPOINT = 768
export const PHONE_BREAKPOINT = 480
export const DEFAULT_LIST_SIZE = 25
export const SUPPORT_EMAIL = "support@eligible.ai"
export const DEFAULT_DAYS_PRODUCT_IS_AVAILABLE = 200 // Approx 7 months

export const errorMessages = {
  DELETE: "Sorry, this file could not be deleted right now.",
  DOWNLOAD: "Sorry, this file could not be downloaded right now.",
  EMAIL: "Please enter a valid email address",
  EMPTY_GRAPH: "Your reports are still being generated",
  EXPIRED_LINK:
    "Sorry, it looks like that link has expired. You'll be redirected shortly. You could also try a link in a recent email.",
  EXPIRED_TOKEN:
    "Sorry, it looks like you're using an expired or invalid token. Please visit from a recent email from us.",
  PASSWORD:
    "Please make sure your new password at least 10 characters and contains at least one number and one letter",
  PHONE: "Please enter a valid phone number",
  REQUEST: "Sorry, there has been an error, please try again later.",
  UNKNOWN: `Something went wrong, please try again later. If the issue persists get in touch with our support team at ${SUPPORT_EMAIL}`,
  URL: "Please enter a valid URL",
}
