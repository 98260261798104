import cx from "classnames"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import buttonTrackingIds from "../../../constants/buttonTrackingIds"
import routes from "../../../constants/routes"
import useTrackClick from "../../../hooks/trackClick"
import NextArrow from "../../../images/NextArrow.svg?react"
import { fakeMortgage } from "../../../lib/stubData"
import { useViewWhyHappenedEventMutation } from "../../../redux/services/lender"
import { currentSituationBeenExpanded } from "../../../redux/slices/persistantSession"
import { useAppSelector } from "../../../redux/store"
import { Mortgage } from "../../../types/retain/Mortgages.types"
import { IfComponentsProvider } from "../../consumer/ProductSelection/ProductSelectionListPage/MortgagePanel/IfComponentsProvider"
import ActionBox from "../ActionBox/ActionBox"
import ButtonLink from "../ButtonLink"
import CurrentSituationPreamble from "../CurrentSituationPreamble"
import { Button } from "../index"
import PopIn from "../PopIn"
import skeleton from "../Skeleton"
import CalculatePayments from "./CalculatePayments"
import FollowOnPayments from "./FollowOnPayments"
import InitialPayments from "./InitialPayments"
import s from "./MortgageOverview.module.scss"
import useMortgageOverviewContent from "./useMortgageOverviewContent"

type MortgageOverviewProps = {
  mortgage: Mortgage | null
  isPortal?: boolean
  owned?: boolean
  loading?: boolean
  disabled?: boolean
  showPreamble?: boolean
}

function MortgageOverview({
  loading,
  mortgage,
  owned = false,
  isPortal = false,
  disabled,
  showPreamble = true,
}: MortgageOverviewProps) {
  const [isUnmounted, setIsUnmounted] = useState(false)
  const [showMortgageInfo, setShowMortgageInfo] = useState(false)
  const businessSide = useAppSelector((state) => state.auth.businessSide)
  const previouslyExpanded = useAppSelector(
    (state) => !!state.persistant.longterm?.currentSituationBeenExpanded
  )
  const [currentSituationExpanded, setCurrentSituationExpanded] = useState(
    isPortal || businessSide || previouslyExpanded
  )
  const [fakeLoading, setFakeLoading] = useState(false)
  const { trackClick } = useTrackClick()
  const dispatch = useDispatch()

  const [viewWhyHappening] = useViewWhyHappenedEventMutation()

  useEffect(() => {
    return () => {
      setIsUnmounted(true)
    }
  }, [])

  const {
    whyPaymentsChangeString,
    whyPaymentsChangeRef,
    whyHappeningMdxContent,
    whyHappeningImage,
    whyHappeningImageRef,
    popInTitle,
    popInTitleRef,
  } = useMortgageOverviewContent(mortgage)

  if (mortgage === null) return null

  // to retain some of the logic around the skeleton, we had to add this to catch mortgages now that they are a truly required field

  function fakeTimeout() {
    trackClick(buttonTrackingIds.CALCULATE_CURRENT_SITUATION)
    setFakeLoading(true)
    setTimeout(
      () => {
        if (isUnmounted) {
          return
        }
        setCurrentSituationExpanded(true)
        dispatch(currentSituationBeenExpanded())
        return setFakeLoading(false)
      },
      (Math.random() + 1) * 500
    )
  }

  const pronoun = owned ? "Your" : "Their"

  return (
    <div
      className={loading ? s.loading : undefined}
      aria-label={loading ? "Loading your current situation" : undefined}
    >
      {showPreamble && (
        <div className={s.mortgageInfo}>
          {loading ? (
            <>
              <span className={s.loadingTextFull} />
              <span className={s.loadingTextFull} />
              <span className={s.loadingTextNotFull} />
            </>
          ) : (
            <CurrentSituationPreamble mortgage={mortgage} owned={owned} />
          )}
        </div>
      )}

      {!loading && owned && (
        <ButtonLink
          ref={whyPaymentsChangeRef}
          onClick={() => {
            setShowMortgageInfo(true)
            viewWhyHappening({ mortgageId: mortgage.id })
          }}
          className={s.mortgageInfoLink}
          trackingId={buttonTrackingIds.WHY_ARE_MY_PAYMENTS_CHANGING}
        >
          {whyPaymentsChangeString} ➝
        </ButtonLink>
      )}
      <div className={cx(s.presentSituation, { [s.inverted]: mortgage.expired })}>
        {mortgage.expired ? (
          <FollowOnPayments
            loading={loading}
            mortgage={mortgage}
            owned={owned}
            pronoun={pronoun}
          />
        ) : (
          <InitialPayments
            mortgage={mortgage}
            owned={owned}
            pronoun={pronoun}
            isPortal={isPortal}
            openMortgageInfo={() => setShowMortgageInfo(true)}
          />
        )}
      </div>
      <div
        className={cx(s.transformArrow, {
          [s.pointUp]: mortgage.expired,
        })}
      >
        <NextArrow />
      </div>
      <div
        className={cx(
          s.notPresentSituation,
          { [s.inverted]: !mortgage.expired && currentSituationExpanded },
          { [s.calculating]: !mortgage.expired && !currentSituationExpanded }
        )}
      >
        {mortgage.expired ? (
          <InitialPayments
            mortgage={mortgage}
            owned={owned}
            pronoun={pronoun}
            isPortal={isPortal}
            openMortgageInfo={() => setShowMortgageInfo(true)}
          />
        ) : (
          <>
            {currentSituationExpanded ? (
              <FollowOnPayments
                loading={loading}
                mortgage={mortgage}
                owned={owned}
                pronoun={pronoun}
              />
            ) : (
              <CalculatePayments
                fakeTimeout={fakeTimeout}
                pronoun={pronoun}
                loading={fakeLoading}
                disabled={disabled}
              />
            )}
          </>
        )}
      </div>
      {owned && (
        <PopIn shown={showMortgageInfo} hide={() => setShowMortgageInfo(false)}>
          {isPortal ? (
            <ActionBox>
              {whyHappeningImage && (
                <img
                  src={whyHappeningImage}
                  ref={whyHappeningImageRef}
                  alt=""
                  className={s.popInImage}
                />
              )}
              <h2 ref={popInTitleRef}>{popInTitle}</h2>
              <div className={s.mdxContent}>
                <IfComponentsProvider hasExpired={mortgage.expired} hasMultiple={false}>
                  {whyHappeningMdxContent}
                </IfComponentsProvider>
              </div>
            </ActionBox>
          ) : (
            <ActionBox>
              <h2>Why is this changing?</h2>
              <p className={s.popInCopy}>
                Mortgage products usually start on a lower rate. After the initial term
                is up, your mortgage moves to a standard variable rate set by your
                Lender.
              </p>
              <p className={s.popInCopy}>
                This rate is often the highest that a lender charges. It also varies
                from lender to lender.
              </p>
              <p className={s.popInCopy}>
                So, you may see a considerable hike in your payments after the first few
                years of your mortgage.
              </p>
              <Link to={routes.CONSUMER.BLOG_POST({ slug: "what-is-standard-anyway" })}>
                <Button className={s.learnMoreButton} fullWidth>
                  Learn More
                </Button>
              </Link>
            </ActionBox>
          )}
        </PopIn>
      )}
    </div>
  )
}

export default skeleton<MortgageOverviewProps>({
  predicate: ({ mortgage }) => mortgage === null,
  dummyProps: {
    loading: true,
    mortgage: {
      ...fakeMortgage,
      startDate: new Date(Date.now() - 730 * 24 * 60 * 60 * 1000).toISOString(),
      expiryDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
      expired: false,
      debtAfterPreferentialRate: 100000,
      monthlyPreferentialPayment: 500,
      preferentialRate: 1.5,
      propertyValue: 150000,
      monthlyFollowOnPayment: 1000,
      followOnRate: 5,
      isSharedOwnership: false,
      loanPurpose: "purchase",
      lender: {
        id: "lender-id-001",
        name: "Good Mortgages Ltd",
      },
    },
  },
})(MortgageOverview)
