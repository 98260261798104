import { LabelPaths } from "../../../hooks/blocks/util.types"

const helpCardBodyMarkdown = `
### We’re here to help...

If you are in financial difficulty or have recently had a change to circumstances impacting your ability to pay, call us to get the right support.
`

export const needSupportBlocks = {
  lender: {
    helpCard: {
      body: {
        type: "markdown",
        description: "Text in the financial help module",
        default: helpCardBodyMarkdown,
      },
      button: {
        type: "text",
        description: "The button text in the financial help module",
        default: "Get help",
      },
    },
    helpPage: {
      hero: {
        type: "image",
        description: "Header image for top of new Get help page",
        default: null, // Icon of person
      },
      header: {
        type: "text",
        description: "Title for new Get help page",
        default: "Get help",
      },
      intro: {
        type: "markdown",
        description: "Content for Get help page",
        default: null,
      },
    },
  },
}

export type NeedSupportPaths = LabelPaths<typeof needSupportBlocks>
