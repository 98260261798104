import { LabelPaths } from "../../../hooks/blocks/util.types"

export const notInterestedPageBlocks = {
  updateNotifications: {
    type: "text",
    description: "The heading above the main content of the Not Interested page",
    default: "Manage alerts",
  },
  updateNotificationsMortgageSelector: {
    type: "markdown",
    description:
      "The paragraph below the heading that describes the alerts you can opt out of on the Not Interested page",
    default:
      "These alerts are related to factual information and notifications about products and services that we provide to you.",
  },
  updateNotificationsPremable: {
    type: "text",
    description:
      "The paragraph below the heading inside the modal that pops up when you toggle notifications off for a mortgage",
    default:
      "If you have a moment, please let us know what you did with your mortgage:",
  },
  updateNotificationsSubmit: {
    type: "text",
    description:
      "The label text inside in the Save button at the bottom of the modal that pops up when you toggle notifications off for a mortgage",
    default: "Save",
  },
  updateNotificationsSubmitLoading: {
    type: "text",
    description:
      "The label text inside in the disabled Save button while a request is in progress, at the bottom of the modal that pops up when you toggle notifications off for a mortgage",
    default: "Saving",
  },
  notInterestedModal: {
    type: "text",
    description:
      "The heading at the top of the modal that pops up when you toggle notifications off for a mortgage",
    default: "Already dealt with this mortgage?",
  },
} as const

export type NotInterestedPagePaths = LabelPaths<typeof notInterestedPageBlocks>
