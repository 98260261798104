import { ActivitySummary } from "../../../components/business/UserActivities/userActivityTypes"
import { PaginatedResponse } from "../../../types/retain"
import { retainEventTypes } from "../../../types/retain/Events.types"

const typesToAddPositionTo = [
  retainEventTypes.STATE_CHANGE_CONSUMER,
  retainEventTypes.STATE_CHANGE_MORTGAGE,
  retainEventTypes.CALL_REQUESTED,
  retainEventTypes.LIFECYCLE_CALL_REQUESTED,
  retainEventTypes.SNOOZE_REQUESTED,
  retainEventTypes.NO_ANSWER,
  retainEventTypes.VOICEMAIL_LEFT,
]

const typesToAddCountTo = [retainEventTypes.EMAIL_OPEN, retainEventTypes.EMAIL_CLICKED]

function groupActivitiesByType(activities: ActivitySummary[]) {
  // Since the API returns the most recent first, unshift to the start to maintain creation order
  return activities.reduce<Partial<Record<retainEventTypes, string[]>>>(
    (acc, activity) => {
      if (acc[activity.type]) {
        acc[activity.type]?.unshift(activity.id)
      } else {
        acc[activity.type] = [activity.id]
      }
      return acc
    },
    {}
  )
}

function getPositionWithinGroup(
  activity: ActivitySummary,
  group: Partial<Record<retainEventTypes, string[]>>
) {
  const index = group[activity.type]?.findIndex((id) => activity.id === id) ?? -1
  return index + 1 >= 1 ? index + 1 : undefined
}

function getGroupCount(
  activity: ActivitySummary,
  group: Partial<Record<retainEventTypes, string[]>>
) {
  return group[activity.type]?.length
}

function transformActivitiesResponse(
  response: PaginatedResponse<ActivitySummary> | undefined | null
) {
  if (!response) {
    return response
  }
  const groupedActivities = groupActivitiesByType(response.results)
  const results = response.results
    .map((activity) => {
      let count
      let position
      let previous
      if (typesToAddPositionTo.includes(activity.type)) {
        position = getPositionWithinGroup(activity, groupedActivities)
      }
      if (typesToAddCountTo.includes(activity.type)) {
        count = getGroupCount(activity, groupedActivities)
        const isLastSeenOfType =
          getPositionWithinGroup(activity, groupedActivities) === count
        const allPrevious = groupedActivities[activity.type]
        if (isLastSeenOfType && allPrevious) {
          previous = [...allPrevious]
        }
      }
      return {
        ...activity,
        count,
        position,
        previous,
      }
    })
    .filter(
      (activity) => !typesToAddCountTo.includes(activity.type) || activity.previous
    )
  return {
    results,
    count: results.length,
  }
}

export default transformActivitiesResponse
