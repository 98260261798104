import { LabelPaths } from "../../../../hooks/blocks/util.types"

export const portalArticleBlocks = {
  lenderLearn: {
    heroImage: {
      type: "image",
      description: "Hero image for the /portal/learn/:propertyId page",
      default: null,
    },
  },
  learn: {
    title: {
      type: "text",
      description: "Title for the /portal/learn/:propertyId page",
      default: "Why do mortgage payments change?",
    },
    content: {
      type: "markdown",
      description: "Copy displayed on the /portal/learn/:propertyId page",
      default: `
Switching to a new mortgage product with us, or remortgaging with a new lender, is a big financial decision. Like your initial mortgage, you need to select the right product for you today.

It is common to move to a new mortgage product before the end of the initial term to avoid a higher payment. Moving to a new mortgage product may also allow additional borrowing or debt consolidation.

Understand in depth why mortgage payments change, the process for moving to a new mortgage product and how to make the right decision for you.
`,
    },
  },
} as const

export type PortalArticleLabelPaths = LabelPaths<typeof portalArticleBlocks>
