import { LabelPaths } from "../../../hooks/blocks/util.types"

export const zeroMortgagesBlock = {
  zeroMortgages: {
    image: {
      type: "image",
      description: "Hero image for the zero mortgages page",
      default: null,
    },
    title: {
      type: "text",
      description: "Title for the zero mortgages page",
      default: "You have no mortgages with us that need to be actioned right now",
    },
    content: {
      type: "markdown",
      description: "Body text for the zero mortgages page",
      default:
        "Get in touch with your advisor if you'd like to enquire about a new mortgage.",
    },
  },
} as const

export type ZeroMortgagesPaths = LabelPaths<typeof zeroMortgagesBlock>
