export enum listTypes {
  ALL_LEADS = "all_leads",
  CALL_LIST = "call_list",
  LIFECYCLE = "lifecycle",
  MY_LEADS = "my_leads",
  MEETINGS_BOOKED = "meetings_booked",
  ORPHANS = "orphans",
  URGENT = "urgent",
  APPROVAL = "approval",
  APPROVAL_INTERNAL = "approval_internal",
  PRODUCT_SELECTION = "product_selection",
}

export type ListType = listTypes[]
export type List =
  | "all_leads"
  | "call_list"
  | "lifecycle"
  | "my_leads"
  | "meetings_booked"
  | "orphans"
  | "urgent"
  | "product_selection"
export type LoadApprovalList = "approval" | "approval_internal"
export type Lists = { name: List | LoadApprovalList; alert: number | null }[]

export interface ListsStore {
  detail: Lists
  totalAlerts: number | null
  totalApproval: number | null
}

export type ListColumn<T> = {
  heading: string
  headerClassName?: string
  cellClassName: string
  renderCellContent: T
}
