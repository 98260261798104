export const expiryDatePageBlocks = {
  expiryDateModuleTitle: {
    type: "text",
    description:
      "Label that displays inside the Expiry Date Info Card on the Lifecycle Home page, and the top of modal that it opens",
    default: "Expiry Date",
  },
  expiryDateModuleAlertCard: {
    type: "text",
    description:
      "The label inside the clickable Expiry Date alerts cards at /property/:propertyId",
    default: "Expiry date",
  },
  expiryDateModalContentPage1: {
    title: {
      DEPRECATED_id: "expiryDateModalContentPage1",

      type: "text",
      description: "The title of the first page of the Expiry Date info modal",
      default: "Expiry date explained",
    },
    content: {
      DEPRECATED_id: "expiryDateModalContentPage1",

      type: "markdown",
      description: "The content of the first page of the Expiry Date info modal",
      default:
        "The expiry date is when your product's initial term comes to an end. Your rate is likely increase when this happens. That's why it’s common to move to a new product before the end of your initial term. You can then avoid higher payment rates.",
    },
  },
  expiryDateModalContentPage2: {
    title: {
      DEPRECATED_id: "expiryDateModalContentPage2",

      type: "text",
      description: "The title of the second page of the Expiry Date info modal",
      default: "How does this impact my mortgage?",
    },
    content: {
      DEPRECATED_id: "expiryDateModalContentPage2",

      type: "markdown",
      description: "The content of the second page of the Expiry Date info modal",
      default:
        "After the initial term expires, mortgage rates usually move to the lender's Standard Variable Rate (SVR). This is often the highest rate offered. That's why it's recommended to start thinking about moving products 4–6 months before expiry.",
    },
  },
  expiryDateModalContentPage3: {
    title: {
      DEPRECATED_id: "expiryDateModalContentPage3",

      type: "text",
      description: "The title of the third page of the Expiry Date info modal",
      default: "Why do we track it?",
    },
    content: {
      DEPRECATED_id: "expiryDateModalContentPage3",

      type: "markdown",
      description: "The content of the third page of the Expiry Date info modal",
      default:
        "Taking action ahead of mortgage expiry is essential. Keep an eye on your lender's current SVR, and the potential impact on your future payments.",
    },
  },
} as const
