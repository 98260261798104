import { useEffect, useRef } from "react"

import checkIsDevLikeEnvironment from "../../../../lib/checkIsDevLikeEnvironment"
import {
  toggleDevToolContentIsHighlighted,
  toggleDevToolShowContentHighlightButton,
} from "../../../../redux/slices/ui"
import { useAppDispatch, useAppSelector } from "../../../../redux/store"
import { Button } from "../../index"
import { toastNotification } from "../../ToastNotification"
import s from "./ContentHighlightingButton.module.scss"

function ContentHighlightingButton() {
  const hasBeenExecutedRef = useRef(false)
  const dispatch = useAppDispatch()
  const showContentHighlightButton = useAppSelector(
    (state) => state.ui.DEV_TOOL_showContentHighlightButton
  )
  const contentIsHighlighted = useAppSelector(
    (state) => state.ui.DEV_TOOL_contentIsHighlighted
  )

  useEffect(() => {
    const isDevLikeEnvironment = checkIsDevLikeEnvironment()
    if (isDevLikeEnvironment && !hasBeenExecutedRef.current) {
      document.addEventListener("keydown", (e) => {
        if (e.ctrlKey && e.altKey && (e.key === "∫" || e.key === "b")) {
          dispatch(toggleDevToolShowContentHighlightButton())
        }
      })
    }
    hasBeenExecutedRef.current = true
  }, [dispatch])

  if (!showContentHighlightButton) {
    return null
  }

  return (
    <Button
      className={s.toggleButton}
      onClick={() => {
        const allDataAttributeContentBlocks =
          document.querySelectorAll("[data-contentid]")
        allDataAttributeContentBlocks.forEach((el) => {
          el.classList.toggle(s.highlighted)
          const contentId = el.getAttribute("data-contentid")
          if (!contentId) {
            return
          }
          const copyToClipboard = buildCopyToClipboardFunctionForContentId(contentId)
          const tooltipId = `tooltip-for-${contentId}`
          const tooltip = document.getElementById(tooltipId)
          if (tooltip) {
            tooltip?.parentNode?.removeChild(tooltip)
          } else {
            const container = document.createElement("div")
            container.className += s.contentIdDisplay
            container.innerText = contentId || ""
            container.id = tooltipId
            el.addEventListener("click", copyToClipboard)
            el.appendChild(container)
          }
        })
        dispatch(toggleDevToolContentIsHighlighted())
      }}
    >
      {contentIsHighlighted ? "Hide" : "Show"} content highlighting
    </Button>
  )
}

function buildCopyToClipboardFunctionForContentId(contentId: string) {
  return async function () {
    if (!contentId) {
      toastNotification(
        "Unable to copy to clipboard, there was a problem getting the contentId to the clipboard",
        "error"
      )
      return
    }
    try {
      await navigator.clipboard.writeText(contentId)
      toastNotification(`Copied "${contentId}" to clipboard`, "info")
    } catch {
      toastNotification(
        "Unable to copy to clipboard, if the App is running locally then it's likely due to being on HTTP instead of HTTPS",
        "error"
      )
    }
  }
}

export default ContentHighlightingButton
