import { forwardRef, KeyboardEvent, LegacyRef, MouseEvent, ReactNode } from "react"

import { handleEnterPressed } from "../../../lib/keyboardEvents"

interface Props {
  className?: string
  label?: string
  onClick: (e?: MouseEvent | KeyboardEvent) => void
  onEnter?: () => void
  onKeyPress?: (e?: KeyboardEvent) => void
  children?: ReactNode
}

const ClickableDiv = forwardRef(
  (props: Props, ref: LegacyRef<HTMLDivElement> | undefined) => {
    const { className, label, onClick, onEnter, children, onKeyPress, ...moreProps } =
      props
    return (
      <div
        ref={ref}
        className={className}
        role="button"
        aria-label={label}
        tabIndex={0}
        onClick={(e) => onClick(e)}
        onKeyDown={handleEnterPressed(onEnter || onClick)}
        onKeyPress={onKeyPress}
        {...moreProps}
      >
        {children}
      </div>
    )
  }
)

ClickableDiv.displayName = "ClickableDiv"

export default ClickableDiv
