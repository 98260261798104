import { cssTransition } from "react-toastify"

import s from "./configureToastTransition.module.scss"

/* If the user has prefers-reduced-motion active, we make the toast fade in and out.
 * If not, this will return undefined meaning it will have its default animation where it flies in and out of the screen */
function configureToastTransition() {
  if (window.matchMedia(`(prefers-reduced-motion: reduce)`).matches) {
    return cssTransition({
      enter: s.fadeIn,
      exit: s.fadeOut,
      collapse: false,
    })
  }
  return undefined
}

export default configureToastTransition
