import { createApi } from "@reduxjs/toolkit/query/react"

import { PaginatedResponse } from "../../../types/retain"
import { LifecycleProspect } from "../../../types/retain/Lifecycle.types"
import { Property } from "../../../types/retain/Mortgages.types"
import axiosBaseQuery from "../axiosBaseQuery"

export const lifecycleProspectsApi = createApi({
  reducerPath: "lifecycleProspectsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  endpoints: (builder) => ({
    getProspects: builder.query<
      PaginatedResponse<LifecycleProspect> | null | undefined,
      { "advisor[]": (string | null)[]; offset?: number; limit?: number }
    >({
      query: (params) => ({
        url: "/lifecycle/",
        params,
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
    }),
    dismissProspect: builder.mutation<unknown, string>({
      query: (id) => ({
        url: `/lifecycle/${id}/dismiss/`,
        method: "POST",
      }),
    }),
    switchProspect: builder.mutation<
      unknown,
      { id: string; properties?: Property["id"][] }
    >({
      query: ({ id, properties }) => ({
        url: `/lifecycle/${id}/switch/`,
        method: "POST",
        requestData: {
          properties,
        },
      }),
    }),
  }),
})

export const {
  useGetProspectsQuery,
  useDismissProspectMutation,
  useSwitchProspectMutation,
} = lifecycleProspectsApi
