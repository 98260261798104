import { useRouteMatch } from "react-router-dom"

import buttonTrackingIds from "../../../constants/buttonTrackingIds"
import routes from "../../../constants/routes"
import useTrackClick from "../../../hooks/trackClick"

type TrackExpansionToggleArgs = {
  mortgageId: string
  isPreviouslyExpanded: boolean
}

function useTrackBasedOnExpansionToggle() {
  const { trackClick } = useTrackClick()

  const isExpiryDateRoute = !!useRouteMatch([
    routes.CONSUMER.EXPIRY_DATE,
    routes.CONSUMER.EXPIRY_DATE_PROPERTY_INDEX,
  ])

  const expandedTrackingId = isExpiryDateRoute
    ? buttonTrackingIds.EXPIRY_DATE_BASED_ON_EXPANDED
    : buttonTrackingIds.CURRENT_SITUATION_BASED_ON_EXPANDED

  const collapsedTrackingId = isExpiryDateRoute
    ? buttonTrackingIds.EXPIRY_DATE_BASED_ON_COLLAPSED
    : buttonTrackingIds.CURRENT_SITUATION_BASED_ON_COLLAPSED

  return ({ mortgageId, isPreviouslyExpanded }: TrackExpansionToggleArgs) => {
    const trackingId = isPreviouslyExpanded ? collapsedTrackingId : expandedTrackingId
    trackClick(trackingId, `Mortgage id: ${mortgageId}`)
  }
}

export default useTrackBasedOnExpansionToggle
