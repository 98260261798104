import { LabelPaths } from "../../../hooks/blocks/util.types"

export const externalAdvicePageBlocks = {
  lender: {
    externalAdvice: {
      headerTitle: {
        type: "text",
        description: "Heading element for the /external-advice/ page",
        default: null,
      },
      heroImage: {
        type: "image",
        description:
          "Hero image for the /external-advice/ page. Fallback icon is a tick within a circle.",
        default: null,
      },
      introContent: {
        type: "markdown",
        description:
          "Copy displayed on the /external-advice/ page as the intro content.",
        default: null,
      },
      urlButton: {
        labelContent: {
          type: "text",
          description: "URL button label for  /external-advice/ page ",
          default: "Book appointment",
        },
      },
      codeHeaderTitle: {
        type: "text",
        description: "Code Heading element for the /external-advice/ page",
        default: null,
      },
      codeHeaderMdxContent: {
        type: "mdx",
        description: "Code Heading MDX element for the /external-advice/ page",
        default: null,
      },
    },
  },
} as const

export type ExternalAdviceLabelPaths = LabelPaths<typeof externalAdvicePageBlocks>
