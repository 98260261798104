import { useEffect } from "react"

type DismissExpiryKeypressListenerProps = {
  dismiss: () => void
}

/* We only want this useEffect to trigger once the AuthExpiry.tsx modal is shown.
 * This is why this component exists and only renders when `shown` is true in AuthExpiry.
 * The previous approach was to put this useEffect in AuthExpiry.tsx but without bigger
 * workaround that would result in space-bar presses triggering an API to the `/me` endpoint
 * regardless of whether the AuthExpiry modal is showing or not. */
function DismissExpiryKeypressListener({
  dismiss,
}: DismissExpiryKeypressListenerProps) {
  useEffect(() => {
    const dismissUponSpacePress = (e: KeyboardEvent) => {
      if (e.key === " ") {
        dismiss()
      }
    }
    document.body.addEventListener("keypress", dismissUponSpacePress)
    return () => {
      document.body.removeEventListener("keypress", dismissUponSpacePress)
    }
  }, [dismiss])

  return null
}

export default DismissExpiryKeypressListener
