import { LabelPaths } from "../../../../hooks/blocks/util.types"

export const thanksPageBlocks = {
  lender: {
    selection: {
      thanks: {
        pageTitle: {
          type: "text",
          description: "Heading element for the /product-selection/thanks page",
          default: "Thanks, we're preparing your personalised illustrations.",
        },
        headerImage: {
          type: "image",
          description:
            "Header image for the /production-selection/thanks page. Fallback icon is a tick within a circle.",
          default: null,
        },
        postContent: {
          type: "text",
          description:
            "Copy displayed on the /production-selection thanks page if the consumer has post as their preferred contact method.",
          default:
            "A member of our team is processing your application.\n\nKeep an eye out for your product switch offer.",
        },
        emailContent: {
          type: "text",
          description:
            "Copy displayed on the /production-selection thanks page if the consumer has email as their preferred contact method.",
          default:
            "A member of our team is processing your application.\n\nKeep an eye out for your product switch offer.",
        },
      },
    },
  },
} as const

export type ThanksPageLabelPaths = LabelPaths<typeof thanksPageBlocks>
