import { LabelPaths } from "../../../hooks/blocks/util.types"

export const portalButtonBlocks = {
  stopAlertsButton: {
    label: {
      type: "text",
      description: "Label for the already actioned button",
      default: "Stop alerts",
    },
  },
  continueReviewButton: {
    labelContent: {
      type: "text",
      description: "Label for the continue review button",
      default: "Continue your review",
    },
  },
  exploreProcessButton: {
    labelContent: {
      type: "text",
      description: "Label for the explore process button",
      default: "Explore Process",
    },
  },
  externalAdviceButton: {
    label: {
      type: "text",
      description: "Label for the external advice button",
      default: "Find advisor",
    },
    iconImage: {
      type: "text",
      description:
        "Key for the icon on the external advice button. Options are: unbiased",
      default: null,
    },
  },
  getHelpButton: {
    label: {
      type: "text",
      description: "Label for the get help button",
      default: "Get help",
    },
  },
  internalAdviceButton: {
    label: {
      type: "text",
      description: "Label for the internal advice button",
      default: "Get help",
    },
  },
  journeyViewMortgage: {
    type: "text",

    description: "Label for the view mortgage button",
    default: "View mortgage",
  },
  moreOptionsButton: {
    label: {
      type: "text",
      description: "Label for the more options button",
      default: "More options",
    },
  },
  productSelectionButton: {
    labelContent: {
      type: "text",
      description: "Label for the product selection start button",
      default: "Start your review",
    },
  },
  seeRatesButton: {
    label: {
      type: "text",
      description: "Label for the see rates button",
      default: "See rates",
    },
  },
  setReminderCta: {
    type: "text",
    description: "Label for the set reminder button",
    default: "Set reminder",
  },
  withSwitchSupportButton: {
    label: {
      type: "text",
      description: "Label for the with switch support button",
      default: "Contact support",
    },
  },
  keyInformationProduct: {
    type: "text",
    description: "Label for the portal article start reading button",
    default: "Key information to move product",
  },
  visitProductPortal: {
    type: "text",
    description: "Label for the visit product portal button",
    default: "Visit product portal",
  },
} as const

export type PortalButtonPaths = LabelPaths<typeof portalButtonBlocks>
