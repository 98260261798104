import { createSlice } from "@reduxjs/toolkit"

export type UpdateStore = {
  updated: boolean
}

const initialState: UpdateStore = { updated: false }

const consumerUpdateSlice = createSlice({
  name: "consumerUpdate",
  initialState,
  reducers: {
    markAsUpdated(state) {
      state.updated = true
    },
    markAsOriginal(state) {
      state.updated = false
    },
  },
})

export const { markAsUpdated, markAsOriginal } = consumerUpdateSlice.actions
export default consumerUpdateSlice.reducer
