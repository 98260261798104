import { HoveredItem } from "../../components/common/Chart"
import ActionType from "../../constants/actionTypes"
import { ChartStore } from "../../types/retain"

export const initialState = {}

type HoveredItemAction = {
  type: ActionType.CHARTS_SET_HOVERED_ITEM
  chartId: string
  hoveredItem: HoveredItem
}

type SliderIdxAction = {
  type: ActionType.CHARTS_SET_SLIDER_IDX
  chartId: string
  idx: number
}

type Action = HoveredItemAction | SliderIdxAction

export default function (state: ChartStore = initialState, action: Action): ChartStore {
  const { chartId } = action

  switch (action.type) {
    case ActionType.CHARTS_SET_HOVERED_ITEM:
      return {
        ...state,
        [chartId]: {
          ...state[chartId],
          hoveredItem: {
            seriesIndex: action.hoveredItem?.seriesIndex,
            dataPointIndex: action.hoveredItem?.dataPointIndex,
          },
        },
      }

    case ActionType.CHARTS_SET_SLIDER_IDX:
      return {
        ...state,
        [chartId]: {
          ...state[chartId],
          sliderIdx: action.idx,
        },
      }

    default:
      return state
  }
}
