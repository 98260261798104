import { ReactNode } from "react"

import ButtonTrackingIds from "../../../constants/buttonTrackingIds"
import useTrackClick from "../../trackClick"

type ExternalLinkProps = {
  url: string
  children: ReactNode
}

export default function ExternalLink({ url, children }: ExternalLinkProps) {
  const { trackClick } = useTrackClick()

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        const label = typeof children === "string" ? `${children} - ` : ""
        trackClick(ButtonTrackingIds.EXTERNAL_LINK, `${label}${url}`)
      }}
    >
      {children}
    </a>
  )
}
