import { SerializedError } from "@reduxjs/toolkit"
import axios from "axios"

import { ReportContext } from "./reportAxiosError"

/**
 * Util function for building SerializedError from AxiosError. Extracted from axiosBaseQuery
 * so that it can be used easily within queryFns that are overring query configs.
 *
 * @param {unknown} err Expected AxiosError or Error like object
 * @param {ReportContext} reportContext Currently only using `errorReasonMessage` to determine
 * message source from the AxiosError.message or the response.data errorReason field
 * @returns {SerializedError}
 */
function buildSerializedError(
  err: unknown,
  reportContext?: ReportContext
): SerializedError {
  if (axios.isAxiosError(err)) {
    return {
      name: err.name,
      code: err.response?.status?.toString(),
      stack: err.stack,
      message: reportContext?.errorReasonMessage
        ? err.response?.data?.errorReason
        : err.message,
    }
  }
  return { message: String(err) }
}

export { buildSerializedError }
