/* eslint react/jsx-props-no-spreading: "off" */
import cx from "classnames"
import { ReactNode } from "react"

import NextArrow from "../../../images/NextArrow.svg?react"
import s from "./ContentBox.module.scss"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  noFullWidth?: boolean
  topMargin?: boolean
  bottomMargin?: boolean
  topBorder?: boolean
  removePadding?: boolean
  removeMargin?: boolean
  icon?: ReactNode
  iconClassname?: string
  withArrow?: boolean
  className?: string
  topBorderError?: boolean
  fadeIn?: boolean
}

const ContentBox = ({
  children,
  noFullWidth,
  topMargin = true,
  bottomMargin = true,
  topBorder,
  removePadding,
  removeMargin,
  icon,
  iconClassname,
  withArrow = false,
  className = "",
  topBorderError = false,
  fadeIn,
  ...rest
}: Props) => (
  <div
    className={cx(s.box, className, "logged-in", {
      [s.noFullWidth]: noFullWidth,
      [s.topMargin]: topMargin,
      [s.bottomMargin]: bottomMargin,
      [s.topBorder]: topBorder,
      [s.removePadding]: removePadding,
      [s.removeMargin]: removeMargin,
      [s.hasIcon]: icon,
      [s.topBorderError]: topBorderError,
      [s.fadeIn]: fadeIn,
    })}
    {...rest}
  >
    {icon && <div className={iconClassname ?? s.icon}>{icon}</div>}
    {children}
    {withArrow && (
      <div className={s.arrowIcon}>
        <NextArrow />
      </div>
    )}
  </div>
)

export default ContentBox
