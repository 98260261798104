import { FunctionComponent, SVGProps } from "react"
import { toast, ToastOptions } from "react-toastify"

import { SUPPORT_EMAIL } from "../../../constants/retain"
import CloseButton from "../../../images/Cross.svg?react"
import logSentryBreadcrumb from "../../../lib/logSentryBreadcrumb"
import { Bell, Tick } from "./Icons"

import "./StandardToastStyling.css"

export type CallToActionButton = {
  buttonText: string
  onButtonClick: () => void
}

type ToastNotificationLevel = "info" | "warning" | "error"

interface EligibleToastOptions extends ToastOptions {
  image?: FunctionComponent<React.PropsWithChildren<SVGProps<SVGSVGElement>>>
  largeButton?: CallToActionButton
  smallButton?: CallToActionButton
}

export function toastNotification(
  text: string,
  type: ToastNotificationLevel,
  options: EligibleToastOptions = {}
) {
  let toastId: string | number = ""
  let Icon = Bell
  let className = "eligible-toast"
  switch (type) {
    case "info": {
      Icon = Tick
      className = "eligible-toast"
      break
    }
    case "warning": {
      className = "eligible-toast-warning"
      break
    }
    case "error": {
      className = "eligible-toast-error"
      break
    }
    default: {
      break
    }
  }
  logSentryBreadcrumb({
    message: text,
    level: type,
    category: "ui.toast",
  })
  toastId = toast(
    <div className="eligible-toast-text-content">
      {text}
      {options.largeButton && (
        <LargeCallToActionButton toastId={toastId} largeButton={options.largeButton} />
      )}
      {options.smallButton && (
        <SmallCallToActionButton toastId={toastId} smallButton={options.smallButton} />
      )}
    </div>,
    {
      ...options,
      className,
      bodyClassName: "eligible-toast-content",
      closeButton: () => (
        <div className="eligible-toast-close-button">
          <CloseButton onClick={(): void => toast.dismiss(toastId)} />
        </div>
      ),
      icon: (
        <div className="eligible-toast-icon-area">
          {options.image ? <options.image /> : <Icon />}
        </div>
      ),
    }
  )
}

export function toastMessageUndoable(text: string, undoFunction: () => void) {
  toastNotification(text, "info", {
    smallButton: { buttonText: "Undo", onButtonClick: undoFunction },
    autoClose: 12000,
  })
}

export function toastMessageErrorTryAgain(
  text: string,
  nextFunction: () => Promise<void>
) {
  const recursiveWrapper = () => {
    toastNotification(text, "error", {
      smallButton: {
        buttonText: "Report issue",
        onButtonClick: () => {
          if (window.Beacon("info")) {
            window.Beacon("open")
          } else {
            window.location.href = `mailto:${SUPPORT_EMAIL}`
          }
        },
      },
      largeButton: {
        buttonText: "Try again",
        onButtonClick: () => {
          nextFunction()
            .then(() => {
              toastNotification("Success", "info")
            })
            .catch(() => {
              recursiveWrapper()
            })
        },
      },
    })
  }
  recursiveWrapper()
}

function SmallCallToActionButton(props: {
  toastId: string | number
  smallButton: CallToActionButton
}): JSX.Element {
  const { toastId, smallButton } = props
  return (
    <div className="eligible-toast-call-to-actions">
      <button
        type="button"
        className="eligible-toast-link"
        onClick={() => {
          toast.dismiss(toastId)
          smallButton.onButtonClick()
        }}
      >
        {smallButton.buttonText}
      </button>
    </div>
  )
}

function LargeCallToActionButton(props: {
  toastId: string | number
  largeButton: CallToActionButton
}): JSX.Element {
  const { toastId, largeButton } = props
  return (
    <div className="eligible-toast-call-to-actions">
      <button
        type="button"
        className="button small outline eligible-toast-button"
        onClick={() => {
          toast.dismiss(toastId)
          largeButton.onButtonClick()
        }}
      >
        {largeButton.buttonText}
      </button>
    </div>
  )
}
