import { useContent_Unstable } from "../blocks/useContent_Unstable"
import { DynamicComponents } from "./dynamics/DynamicComponent.types"
import { useMdx } from "./useMdx"

function useMdxContent<Paths extends string = string>(
  path: Paths,
  passedProps: Record<string, string | boolean | number | null> = {},
  options?: { fallback?: string; dynamicComponents?: DynamicComponents[] }
) {
  const { b } = useContent_Unstable<Paths>({ type: "mdx" })
  const source = b(path, { fallback: options?.fallback })

  return useMdx(source, options?.dynamicComponents, passedProps)
}

export default useMdxContent
