import { Event as SentryEvent } from "@sentry/react"

interface EventWithSourceFile extends SentryEvent {
  extra?: Record<string, unknown> & { body: { sourceFile: string } }
}

/**
 * Checks that a Sentry Event has a sourceFile property in its extra.body data
 */
const isEventWithSourceFile = (event: SentryEvent): event is EventWithSourceFile => {
  if (
    event.extra?.body &&
    Object.prototype.hasOwnProperty.call(event.extra?.body, "sourceFile")
  ) {
    return true
  }
  return false
}

export default isEventWithSourceFile
